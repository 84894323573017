import { Routes, Route, Navigate, useNavigate } from "react-router-dom";
import "./App.css";
import Home from "./pages/Home";
import Commissions from "./pages/Commissions";
// import ConsumptionReport from "./pages/ConsumptionReport";
import CRPDFGEN from "./pages/CR_PDF_GEN";
import SOPDFGEN from "./pages/SO_PDF_GEN";
// import ConsumptionReportGen from "./pages/ConsumptionReportGen";
import { useIsAuthenticated } from "@azure/msal-react";
import PlatinumSupportCalculator from "./pages/PlatinumSupportCalculator";
import LeftSidebar from "./components/sidebar/LeftSidebar";
import MainHeader from "./components/mainHeader/MainHeader";
import { useDarkMode } from "./DarkModeContext";
import logoDark from "./assets/ttt-logo.png";
import logoLight from "./assets/ttt-logo_light.png";
// import SalesOrder from "./pages/SalesOrder";
import { useAuth } from "./components/AuthProvider";
import { CustomNavigationClient } from "./NavigationClient";
import Analytics from "./pages/Analytics";

function App({ pca }) {
	const { darkMode } = useDarkMode();
	const isAuthenticated = useIsAuthenticated();
	const { user, handleLogin } = useAuth();

	const navigate = useNavigate();
	const navigationClient = new CustomNavigationClient(navigate);
	pca.setNavigationClient(navigationClient);

	return (
		<>
			<div className="min-h-screen text-white bg-gray-100 dark:bg-slate-800">
				{isAuthenticated ? (
					<main className="flex bg-gray-300 dark:bg-slate-900">
						{user?.entraGroup === "default" ? (
							<div>
								Unrecognized user. Please contact Operations
								team to ask for access
							</div>
						) : (
							<div className="flex w-full h-screen bg-custom-green-gradient">
								<div className="flex-shrink-0">
									<LeftSidebar />
								</div>
								<div className="flex flex-col flex-grow my-3 mr-3 overflow-hidden bg-gray-100 rounded-lg shadow-lg dark:bg-gray-800">
									<MainHeader userName={user?.userName} />
									<div className="flex-grow h-0 p-6 pt-0 overflow-x-hidden overflow-y-auto scrollbar-hide">
										<Pages />
									</div>
								</div>
							</div>
						)}
					</main>
				) : (
					<div className="flex w-screen min-h-screen">
						<div className="w-full flex flex-col items-center justify-center gap-5 shadow-lg bg-slate-200 dark:bg-slate-800 bg-custom-green-gradient">
							<div className="max-h-[22rem] rounded-2xl pb-2 flex flex-col items-center gap-12">
								<div className="flex flex-col items-center gap-5 pb-0">
									<img
										className="w-[5rem]"
										src={darkMode ? logoDark : logoLight}
										alt="TTT Logo"
									/>
									<h1 className="text-6xl font-bold text-zinc-800 dark:text-slate-300">
										Sales Hub
									</h1>
									<p className="text-xl font-bold rounded-md bg-gray-300 px-2 py-1 text-white bg-gradient-to-r from-blue-400 via-green-400 to-blue-400 bg-[length:200%_200%] animate-moveGradient">
										Beta
									</p>
								</div>
								<button
									onClick={handleLogin}
									className="btn-primary w-max"
								>
									Sign in
								</button>
							</div>
						</div>
					</div>
				)}
			</div>
		</>
	);
}

function Pages() {
	const { darkMode } = useDarkMode();
	return (
		<Routes>
			<Route path="/">
				<Route index element={<Navigate to="/home" />} />
				<Route path="home" element={<Home />} />
				<Route path="commissions" element={<Commissions />} />
				<Route path="consumption-reports" element={<CRPDFGEN />} />
				<Route path="sales-orders" element={<SOPDFGEN />} />
				<Route
					path="platinum-calculator"
					element={<PlatinumSupportCalculator darkMode={darkMode} />}
				/>
				<Route path="analytics" element={<Analytics />} />
			</Route>
		</Routes>
	);
}

export default App;
