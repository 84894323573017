import logoDark from "../../assets/ttt-logo.png";
import logoLight from "../../assets/ttt-logo_light.png";
import HomeIcon from "@mui/icons-material/Home";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import AssessmentIcon from "@mui/icons-material/Assessment";
import CalculateIcon from "@mui/icons-material/Calculate";
import ArticleIcon from "@mui/icons-material/Article";
import AnalyticsIcon from "@mui/icons-material/Analytics";
import { PiSidebar } from "react-icons/pi";
import { useDarkMode } from "../../DarkModeContext";
import { useState } from "react";
import { ArrowBack } from "@mui/icons-material";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import FeedbackButton from "../common/FeedbackButton";
import Loading from "../common/Loading";
import { environment } from "../../authConfig";
import { NavLink } from "react-router-dom";
import { useAuth, permissionMatrix } from "../AuthProvider";

function LeftSidebar() {
	const { darkMode } = useDarkMode();
	const { user } = useAuth();
	const [sidebarExpanded, setSidebarExpanded] = useState(true);

	const iconMap = {
		"Home": <HomeIcon />,
		"Commissions": <RequestQuoteIcon />,
		"Consumption Report": <AssessmentIcon />,
		"Sales Order": <ArticleIcon />,
		"Platinum Calculator": <CalculateIcon />,
		"Analytics": <AnalyticsIcon />,
	};

	const toggleSidebarView = () => {
		setSidebarExpanded((prevState) => !prevState);
	};

	const version = import.meta.env.VITE_VERSION;

	const routeMap = {
		"Home": "home",
		"Commissions": "commissions",
		"Consumption Report": "consumption-reports",
		"Sales Order": "sales-orders",
		"Platinum Calculator": "platinum-calculator",
		"Analytics": "analytics",
	};

	return (
		<div
			className={`z-40 flex-col justify-between h-full overflow-y-auto border-gray-200 scrollbar-hide border-r-1 text-slate-900 md:flex dark:border-gray-900 dark:text-white ${sidebarExpanded ? "xl:min-w-[300px] w-64" : "xl:min-w-[70px]"}`}
		>
			<div className="flex flex-col">
				<div
					className={`max-h-[22rem] rounded-2xl pb-2 ${sidebarExpanded ? "flex" : "flex flex-col items-center gap-5"} p-7 justify-between`}
				>
					<div className="flex items-start gap-2">
						<img
							className="w-[28px]"
							src={darkMode ? logoDark : logoLight}
							alt="TTT Logo"
						/>
						{sidebarExpanded && (
							<div className="flex flex-col mt-1">
								<h1 className="flex items-center font-bold text-md text-zinc-800 dark:text-slate-300">
									Sales Hub
									<span className="ms-2 text-xs font-bold rounded-md bg-gray-300 px-2 py-1 text-zinc-100 bg-gradient-to-r from-blue-400 via-green-400 to-blue-400 bg-[length:200%_200%] animate-moveGradient">
										Beta
									</span>
								</h1>
								<span className="text-sm text-zinc-800 dark:text-slate-300">
									v{version}
								</span>
							</div>
						)}
					</div>
				</div>
				<div className="xl:min-w[300px] hidden max-h-[22rem] rounded-2xl pb-2 md:inline">
					<div className="flex flex-col items-start p-4 space-y-3 text-sm">
						{user?.entraGroup ? (
							permissionMatrix[user?.entraGroup].map(
								(pageName, index) => {
									return (
										<NavLink
											key={`${index}-${pageName}`}
											className={`flex w-full items-center ${sidebarExpanded ? "" : "justify-center"} gap-2 rounded-lg p-3 text-start`}
											to={`/${routeMap[pageName]}`}
										>
											{iconMap[pageName]}
											{sidebarExpanded && pageName}
										</NavLink>
									);
								}
							)
						) : (
							<Loading />
						)}
					</div>
				</div>
			</div>
			<div className="flex flex-col items-start p-4 space-y-3 text-sm">
				{/* <button
					className={`flex w-full items-center ${sidebarExpanded ? "" : "justify-center"} gap-2 rounded-lg p-3 text-start text-neutral-800 hover:bg-gray-500/10 dark:text-slate-400 dark:hover:text-slate-300`}
					onClick={() => setActivePage("Settings")}
				>
					{sidebarExpanded ? (
						<>
							<Settings />
							Settings
						</>
					) : (
						<Settings />
					)}
				</button> */}
				{environment === "production" && (
					<FeedbackButton sidebarExpanded={sidebarExpanded} />
				)}
				<button
					className={`flex w-full items-center ${sidebarExpanded ? "" : "justify-center"} gap-2 rounded-lg p-3 text-start text-neutral-800 hover:text-gray-500/10 dark:text-slate-400 dark:hover:text-slate-300`}
					onClick={toggleSidebarView}
				>
					{sidebarExpanded ? (
						<>
							<ArrowBack />
							Collapse Menu
						</>
					) : (
						<Tippy
							content="Expand sidebar"
							placement="right"
							animation="fade"
							theme={darkMode ? "dark" : "light"}
							delay={500}
						>
							<div>
								<PiSidebar />
							</div>
						</Tippy>
					)}
				</button>
			</div>
			{/* {sidebarExpanded && (
                <div className="p-5 m-3 space-y-3 text-black rounded-lg shadow-lg bg-white/50 dark:bg-blue-400/20 dark:text-white">
                    <h2 className="font-semibold text-md">Spot an Issue?</h2>
                    <p className="text-xs">Help us improve by letting us know what's not working. Your feedback is valuable!</p>
                    <button className="btn-primary">Submit Feedback</button>
                </div>
            )} */}
		</div>
	);
}

export default LeftSidebar;
