/* eslint-disable no-mixed-spaces-and-tabs */
import { useState } from "react";
import axios from "axios";
import FilterNav from "../components/commissions/FilterNav";
import useDebounce from "../hooks/useDebounce";
import LogoLoading from "../components/common/LogoLoading";
import CommissionsList from "../components/commissions/CommissionsList";
import CommissionDetails from "../components/commissions/CommissionDetails";
import { useQuery } from "react-query";
import { useAuth } from "../components/AuthProvider";
import { serverAddress } from "../authConfig";
import { formatMoney } from "../utils";

function Commissions() {
	const { user, accessToken } = useAuth();

	const [selectedSalesperson, setSelectedSalesperson] = useState(
		user?.userName
	);
	const [selectedMonth, setSelectedMonth] = useState("All Time");
	const [searchInput, setSearchInput] = useState("");
	const [selectedStatus, setSelectedStatus] = useState("All Statuses");
	const [activeSalesOrderId, setActiveSalesOrderId] = useState("");
	const debouncedSearchInput = useDebounce(searchInput, 500);

	const fetchSalespersons = async () => {
		// AMs should only be able to see their own sales orders
		if (!user?.isElevatedPermissions)
			return [{ "full_name": user?.userName }];

		try {
			const response = await axios.get(
				`${serverAddress}/account-managers`,
				{
					headers: {
						"Content-Type": "application/json",
						"Authorization": `Bearer ${accessToken}`,
						"X-User-Name": `${user?.userName}`,
					},
				}
			);
			if (response.status === 200) {
				return response.data.salespersons;
			}
		} catch (error) {
			console.error(error);
		}
	};

	const {
		data: salespersons,
		error: salespersonsError,
		isLoading: salespersonsIsLoading,
	} = useQuery({
		queryKey: ["salespersons", user?.userName],
		queryFn: () => fetchSalespersons(),
		staleTime: 30 * 60 * 1000, // Cache data for 30 mins. Number of AMs doesn't change often
	});

	const fetchUsersSalesOrders = async (salesperson) => {
		if (salesperson === undefined) return;

		setSelectedSalesperson(salesperson);
		try {
			const response = await axios.get(
				`${serverAddress}/sales-orders/users/${salesperson}`,
				{
					headers: {
						"Content-Type": "application/json",
						"Authorization": `Bearer ${accessToken}`,
						"X-User-Name": `${salesperson}`,
					},
				}
			);
			if (response.status === 200) {
				return response.data;
			}
		} catch (error) {
			console.error(error);
		}
	};

	const {
		data: salesOrders,
		error: salesOrdersError,
		isLoading: salesOrdersAreLoading,
	} = useQuery({
		queryKey: ["listSalespersonsSalesOrders", { selectedSalesperson }],
		queryFn: () => fetchUsersSalesOrders(selectedSalesperson),
		staleTime: 2 * 60 * 1000, // Cache data for 2 mins. User can clear cache by refreshing
		enabled: !!salespersons,
	});

	const prevMonthEpoch = (() => {
		const currentDate = new Date();
		const prevMonthDate = new Date(
			currentDate.getFullYear(),
			currentDate.getMonth() - 1,
			1
		);
		return Math.floor(prevMonthDate.getTime() / 1000);
	})();

	const currentMonthEpoch = (() => {
		const currentDate = new Date();
		const currentMonthDate = new Date(
			currentDate.getFullYear(),
			currentDate.getMonth(),
			1
		);
		return Math.floor(currentMonthDate.getTime() / 1000);
	})();

	const prevMonthTotalContractsValue = salesOrders?.reduce(
		(partialSum, a) => {
			if (
				a._ts >= prevMonthEpoch &&
				a._ts <= currentMonthEpoch &&
				a.status === "Signed"
			) {
				return partialSum + a.totalContractValue;
			}
			return partialSum;
		},
		0
	);

	const prevMonthCommission = salesOrders?.reduce((partialSum, a) => {
		if (
			a._ts >= prevMonthEpoch &&
			a._ts <= currentMonthEpoch &&
			a.status === "Signed"
		) {
			return partialSum + a.commission;
		}
		return partialSum;
	}, 0);

	const currentMonthTotalContractsValue = salesOrders?.reduce(
		(partialSum, a) => {
			if (a._ts >= currentMonthEpoch && a.status === "Signed") {
				return partialSum + a.totalContractValue;
			}
			return partialSum;
		},
		0
	);

	const currentMonthCommission = salesOrders?.reduce((partialSum, a) => {
		if (a._ts >= currentMonthEpoch && a.status === "Signed") {
			return partialSum + a.commission;
		}
		return partialSum;
	}, 0);

	return (
		<div>
			<div className="flex gap-3">
				<>
					<div className="flex w-full flex-col gap-3">
						<div className="animate-fade-in rounded-lg bg-white p-4 shadow-md transition-opacity duration-200 dark:bg-slate-700">
							<div className="flex w-full gap-4 justify-between">
								<div className="flex flex-col w-full">
									<p className="text-lg font-bold text-black dark:text-white">
										Filters
									</p>
									<FilterNav
										isElevatedPermissions={
											user?.isElevatedPermissions
										}
										salespersons={salespersons}
										selectedSalesperson={
											selectedSalesperson
										}
										selectedMonth={selectedMonth}
										searchInput={searchInput}
										selectedStatus={selectedStatus}
										handleSelectedSalesperson={(
											salesperson
										) => {
											setSelectedSalesperson(salesperson);
										}}
										handleSelectedMonth={(month) => {
											setSelectedMonth(month);
										}}
										handleSearchInput={(input) => {
											setSearchInput(input);
										}}
										handleSelectedStatus={(status) => {
											setSelectedStatus(status);
										}}
									/>
								</div>
								<div className="flex gap-4 items-center">
									<div className="flex flex-col">
										<h4>Last Month</h4>
										<div className="flex gap-1">
											<div className="bg-slate-800 rounded-md p-2">
												<p>Revenue:</p>
												<p>
													$
													{formatMoney(
														prevMonthTotalContractsValue
													)}
												</p>
											</div>
											<div className="bg-slate-800 rounded-md p-2">
												<p>Commission:</p>
												<p>
													$
													{formatMoney(
														prevMonthCommission
													)}
												</p>
											</div>
										</div>
									</div>
									<div className="flex flex-col">
										<h4>This Month</h4>
										<div className="flex gap-1">
											<div className="bg-slate-800 rounded-md p-2">
												<p>Revenue:</p>
												<p>
													$
													{formatMoney(
														currentMonthTotalContractsValue
													)}
												</p>
											</div>
											<div className="bg-slate-800 rounded-md p-2">
												<p>Commission:</p>
												<p>
													$
													{formatMoney(
														currentMonthCommission
													)}
												</p>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className="flex">
							<CommissionsList
								activeSalesOrderId={activeSalesOrderId}
								salesOrders={salesOrders}
								debouncedSearchInput={debouncedSearchInput}
								selectedMonth={selectedMonth}
								selectedStatus={selectedStatus}
								selectedSalesperson={selectedSalesperson}
								handleActiveSalesOrderChange={(
									salesOrderId
								) => {
									setActiveSalesOrderId(salesOrderId);
								}}
							/>
							<CommissionDetails
								activeSalesOrderId={activeSalesOrderId}
								serverAddress={serverAddress}
								accessToken={accessToken}
								userName={user?.userName}
								selectedSalesperson={selectedSalesperson}
							/>
						</div>
					</div>
				</>
			</div>
		</div>
	);
}

export default Commissions;
