import {
	environment,
	graphConfig,
	loginRequest,
	serverAddress,
} from "../authConfig";
import { useMsal } from "@azure/msal-react";
import axios from "axios";
import React, { createContext, useContext, useEffect, useState } from "react";
import * as Sentry from "@sentry/react";

export const permissionMatrix = {
	"d247f529-6b4f-4c44-8373-b37d13d78a0e": [
		"Home",
		"Commissions",
		"Consumption Report",
		"Sales Order",
		"Platinum Calculator",
		"Analytics",
	], // Operations Dynamic
	"ea5fa75d-c057-4767-89c8-0796c1ff3a5c": [
		"Home",
		"Commissions",
		"Consumption Report",
		"Sales Order",
		"Platinum Calculator",
	], // Account Managers Managers
	"99f4de29-54f6-45eb-9a37-f6db6c81df25": [
		"Home",
		"Commissions",
		"Consumption Report",
		"Sales Order",
		"Platinum Calculator",
	], // Account Managers - Dynamic
	"b7b07872-5583-4b2a-8df7-f118b02da7d8": [
		"Home",
		"Commissions",
		"Consumption Report",
		"Sales Order",
		"Platinum Calculator",
	], // Account Managers Dynamic (UK)
	"cb434c27-bce0-444e-bf24-1fe89b4f4a83": [
		"Consumption Report",
		"Platinum Calculator",
	], // AE Dynamic (US)
	"e95785c2-3b8b-443b-860b-70d8871bb7a1": [
		"Consumption Report",
		"Platinum Calculator",
	], // AE Dynamic (UK)
	"ebe29cd4-2d6b-47af-a340-0297da0701a8": [
		"Consumption Report",
		"Platinum Calculator",
	], // AE Dynamic (ALL)
	"83ab0d75-473f-4ae0-9a6b-747351b201ff": ["Platinum Calculator"], // Platinum Support
};

async function callMsGraph(accessToken) {
	console.info("Fetching user data from Entra");
	const headers = new Headers();
	const bearer = `Bearer ${accessToken}`;
	headers.append("Authorization", bearer);

	const response = await fetch(graphConfig.graphGroupsEndpoint, {
		method: "GET",
		headers: headers,
	});
	const data = await response.json();
	return data.value;
}

// type User = {
//   userName?: string | null;
//   entraGroup?: string | null;
//   isElevatedPermissions?: boolean | null;
// };

// type AuthContext = {
//   accessToken?: string | null;
//   user?: User | null;
//   getAccessToken: (idToken: string, entraGroup: string) => Promise<void>;
//   handleLogin: () => Promise<void>;
// };

const AuthContext = createContext(undefined);

// type AuthProviderProps = PropsWithChildren;

export default function AuthProvider({ children }) {
	const [loginResponse, setLoginResponse] = useState();
	const [accessToken, setAccessToken] = useState();
	const [user, setUser] = useState();
	const { instance, accounts } = useMsal();

	useEffect(() => {
		const initializeAuth = async () => {
			if (instance && accounts.length > 0) {
				try {
					await instance.initialize();
					console.info("INSTANCE IS INITIALIZED!");

					const [idToken, entraGroup] = await getUserData();
					if (!idToken || !entraGroup) {
						return;
					}
					await getAccessToken(idToken, entraGroup);
				} catch (error) {
					console.error("Error initializing MSAL instance:", error);
				}
			} else {
				console.info("INSTANCE IS NOT INITIALIZED");
			}
		};

		initializeAuth();
	}, [instance, accounts]);

	async function getUserData() {
		console.info("Getting user data");
		const silentTokenResponse = await instance.acquireTokenSilent({
			...loginRequest,
			account: accounts[0],
		});
		const graphResponse = await callMsGraph(
			silentTokenResponse.accessToken
		);

		let entraGroup = "default";
		for (const group of graphResponse) {
			if (group === "ea5fa75d-c057-4767-89c8-0796c1ff3a5c") {
				entraGroup = "ea5fa75d-c057-4767-89c8-0796c1ff3a5c";
				break;
			} else if (group.id in permissionMatrix) {
				// BUG: No longer terminating on first match, but this will require
				// that the user is only in a single group in the permission matrix
				// if they aren't an Account Manager Manager.
				// e.g: they can't be Account Manager and Account Executive, or we
				// could have overlapping / masked permissions
				entraGroup = group.id;
			}
		}

		let isElevatedPermissions = false;
		if (
			entraGroup === "d247f529-6b4f-4c44-8373-b37d13d78a0e" ||
			entraGroup === "ea5fa75d-c057-4767-89c8-0796c1ff3a5c"
		) {
			isElevatedPermissions = true;
		}

		console.debug("Entra Group is: ", entraGroup);

		// TODO: Caching of user data to enable silent SSO. Need to implement pull from cache if exists
		const cachedData = JSON.stringify({
			entraGroup: entraGroup,
			userName: accounts[0].name ? accounts[0].name : "",
			userEmail: accounts[0].username ? accounts[0].username : "",
		});
		localStorage.setItem("groupMembership", cachedData);

		const idToken = silentTokenResponse.idToken;

		setUser({
			userName: accounts[0].name ? accounts[0].name : undefined,
			entraGroup: entraGroup,
			isElevatedPermissions: isElevatedPermissions,
		});

		Sentry.setUser({
			fullName: accounts[0].name,
			email: accounts[0].username,
		});

		return [idToken, entraGroup];
	}

	async function handleLogin() {
		const loginResult = await instance.loginPopup(loginRequest);
		if (environment !== "production") {
			console.info("Login response:", loginResult);
		}
		setLoginResponse(loginResult);
	}

	async function handleLogout() {
		Object.keys(localStorage).forEach((key) => {
			if (key !== "dark-mode") {
				localStorage.removeItem(key);
			}
		});

		const logoutRequest = {
			account: instance.getActiveAccount(),
			mainWindowRedirectUri: "/",
			postLogoutRedirectUri: "/",
		};

		const logoutResult = await instance.logout(logoutRequest);
		if (environment !== "production") {
			console.info("Logout response:", logoutResult);
		}
	}

	async function getAccessToken(idToken, entraGroup) {
		const access_token = localStorage.getItem("saleshubAccessToken");
		if (access_token) {
			console.info("Retrieved access token from local storage");
			setAccessToken(access_token);
		} else {
			const response = await axios.get(`${serverAddress}/token`, {
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${idToken}`,
					"X-Entra-Group": `${entraGroup}`,
					"X-User-Name": `${accounts[0].name}`,
				},
			});
			console.info("Fetched access token");
			localStorage.setItem(
				"saleshubAccessToken",
				response.data.access_token
			);
			setAccessToken(response.data.access_token);
		}
	}

	return (
		<AuthContext.Provider
			value={{
				accessToken,
				user,
				getAccessToken,
				handleLogin,
				handleLogout,
			}}
		>
			{children}
		</AuthContext.Provider>
	);
}

export function useAuth() {
	const context = useContext(AuthContext);

	if (context === undefined) {
		throw new Error("useAuth must be used inside of an AuthProvider");
	}

	return context;
}
