/* eslint-disable no-unused-vars */
import React from 'react';
import SOrder from '../components/SO_PDF';

function SoPdfGen({ serverAddress, accessToken, userName }) {
    return (
        <div>
            <SOrder serverAddress={serverAddress} accessToken={accessToken} userName={userName} />
        </div>
    );
}

export default SoPdfGen;