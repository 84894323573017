import ReactDOM from "react-dom/client";
import "./index.css";
import { environment } from "./authConfig";
import AppWrapper from "./AppWrapper";
import * as Sentry from "@sentry/react";

if (environment === "production") {
	Sentry.init({
		dsn: "https://11404d17af6b74a81fabc6fc2b895847@o4507685550161920.ingest.us.sentry.io/4507686178521088",
		integrations: [
			Sentry.browserTracingIntegration(),
			Sentry.replayIntegration(),
			Sentry.feedbackIntegration({
				nameLabel: "Full Name",
				namePlaceholder: "Full Name",
				isNameRequired: true,
				emailPlaceholder: "your.email@trustedtechteam.com",
				isEmailRequired: true,
				colorScheme: "dark",
				showBranding: false,
				autoInject: false,
				useSentryUser: {
					name: "fullName",
					email: "email",
				},
			}),
		],
		// Performance Monitoring
		tracesSampleRate: 1.0, //  Capture 100% of the transactions
		// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
		tracePropagationTargets: [
			/^https:\/\/sh\.trustedtechteam\.com/,
			/^https:\/\/sh\.trustedtechteam\.dev/,
		],
		// Session Replay
		replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
		replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
	});
}

ReactDOM.createRoot(document.getElementById("root")).render(<AppWrapper />);
