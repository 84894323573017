/* eslint-disable no-unused-vars */
import { useEffect } from "react";
import CReport from "../components/CR_PDF";

function CrPdfGen({ serverAddress, accessToken, userName }) {
	useEffect(() => {
		const elements = document.querySelectorAll(".animate-fade-in");
		elements.forEach((el) => {
			el.classList.add("opacity-0");
			setTimeout(() => {
				el.classList.remove("opacity-0");
				el.classList.add("opacity-100");
			}, 20);
		});
	}, []);

	return (
		<div className="animate-fade-in transition-opacity duration-200">
			<CReport
				serverAddress={serverAddress}
				accessToken={accessToken}
				userName={userName}
			/>
		</div>
	);
}

export default CrPdfGen;
