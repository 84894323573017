import axios from "axios";
import { formatMoney } from "../../utils";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { NavLink } from "react-router-dom";

function CommissionDetails({
	activeSalesOrderId,
	serverAddress,
	accessToken,
	userName,
	selectedSalesperson,
}) {
	const queryClient = useQueryClient();

	const fetchSalesOrderDetails = async (activeSalesOrderId) => {
		if (!activeSalesOrderId) return;
		const response = await axios.get(
			`${serverAddress}/sales-orders/${activeSalesOrderId}`,
			{
				headers: {
					"Content-Type": "application/json",
					"Authorization": `Bearer ${accessToken}`,
					"X-User-Name": `${userName}`,
				},
			}
		);
		if (response.status === 200) {
			return response.data;
		}
		throw new Error("Failed to fetch sales order details");
	};

	const {
		data: salesOrderData,
		error: salesOrderError,
		isLoading: salesOrderIsLoading,
	} = useQuery({
		queryKey: ["salesOrderDetails", activeSalesOrderId],
		queryFn: () => fetchSalesOrderDetails(activeSalesOrderId),
		staleTime: 2 * 60 * 1000, // Cache data for 2 mins. User can clear cache by refreshing
	});

	const changeSalesOrderStatus = async (mutation) => {
		try {
			const response = await axios.patch(
				`${serverAddress}/sales-orders/status`,
				{
					id: mutation.id,
					status: mutation.status,
				},
				{
					headers: {
						"Content-Type": "application/json",
						"Authorization": `Bearer ${accessToken}`,
						"X-User-Name": `${userName}`,
					},
				}
			);
			console.info(response);
		} catch (error) {
			console.error(error);
		}
	};

	const mutation = useMutation({
		mutationFn: changeSalesOrderStatus,
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: ["salesOrderDetails", activeSalesOrderId],
			});
			queryClient.invalidateQueries({
				queryKey: [
					"listSalespersonsSalesOrders",
					{ selectedSalesperson },
				],
			});
		},
	});

	const pstDate = (epoch) => {
		const date = new Date(epoch * 1000); // Convert seconds to milliseconds
		const options = {
			timeZone: "America/Los_Angeles",
			year: "numeric",
			month: "2-digit",
			day: "2-digit",
			hour: "2-digit",
			minute: "2-digit",
		};

		return date.toLocaleString("en-US", options);
	};

	const isCoreSuite = (productName) => {
		return (
			productName === "Microsoft 365 E3" ||
			productName === "Microsoft 365 E5" ||
			productName === "Microsoft 365 Business Premium" ||
			productName === "Office 365 E3" ||
			productName === "Office 365 E5"
		);
	};

	const profitClassName = (profit) => {
		return profit < 0 ? "text-red-500" : "";
	};

	const totalProfit = (pricingSummary) => {
		let totalProfit = 0;
		pricingSummary.forEach((lineItem) => {
			console.debug(lineItem);
			const diff = lineItem.unitPrice - lineItem.matchedUnitPrice;
			const profit = diff * lineItem.quantity;
			totalProfit += profit;
		});
		return formatMoney(totalProfit);
	};

	const totalCommission = (pricingSummary) => {
		let totalCommission = 0;
		pricingSummary.forEach((lineItem) => {
			console.debug(lineItem);

			let numMonths = 12;

			const profit = lineItem.unitPrice - lineItem.matchedUnitPrice;
			const annualProfit = profit * numMonths;
			let commission = annualProfit * lineItem.quantity * 0.04;
			if (lineItem.productName.includes("Platinum")) {
				commission = 0;
			}

			if (salesOrderData.currency !== "USD") {
				const conversionRate =
					lineItem.matchedUnitPriceInUsd / lineItem.matchedUnitPrice;

				// BUG: We rely on there being a unit price match in USD
				// for the conversion rate currently
				// If the product (e.g: legacy SKU) has no match price in USD
				// the commission for it will show as 0
				if (isNaN(conversionRate)) {
					commission = 0;
				} else {
					commission = commission * conversionRate;
				}
			}

			if (commission < 0 && isCoreSuite(lineItem.matchedSkuTitle)) {
				commission = 0;
			}

			totalCommission += commission;
		});
		return formatMoney(totalCommission);
	};

	const currencySymbol = (currency) => {
		let symbol = "$";
		if (currency === "USD") {
			symbol = "$";
		} else if (currency === "CAD") {
			symbol = "C$";
		} else if (currency === "EUR") {
			symbol = "€";
		} else if (currency === "GBP") {
			symbol = "£";
		} else if (currency === "CHF") {
			symbol = "CHF";
		}
		return symbol;
	};

	const symbol = currencySymbol(salesOrderData?.currency);

	const statusOptions = [
		{ value: "Draft", label: "Draft" },
		{ value: "Pending", label: "Pending" },
		{ value: "Signed", label: "Signed" },
		{ value: "Rejected", label: "Rejected" },
	];

	return (
		<div className="ml-2 w-3/4 dark:border-gray-600 dark:bg-slate-700 dark:text-gray-300 border-neutral-300 border shadow-md text-black rounded-lg p-4">
			{salesOrderIsLoading ? <div>Loading...</div> : <></>}
			{salesOrderError ? (
				<div>Something terrible happened! {salesOrderError}</div>
			) : (
				<></>
			)}
			{salesOrderData ? (
				<div className="flex h-full flex-col justify-between items-baseline">
					<div>
						<div className="flex justify-end">
							<NavLink
								className="btn-edit bg-blue-500 text-white px-4 py-2 rounded"
								to="/sales-orders"
								state={{ activeSalesOrderId }}
							>
								Edit Sales Order
							</NavLink>
						</div>

						<h2 className="font-bold text-lg">
							Company:{" "}
							{salesOrderData?.companyName === ""
								? "Unknown Company"
								: salesOrderData?.companyName}
						</h2>
						<h2 className="font-bold text-lg">
							Salesperson:{" "}
							{salesOrderData?.salesPersonName === ""
								? "Unknown Salesperson"
								: salesOrderData?.salesPersonName}
						</h2>
						<h2 className="font-bold text-lg">
							Date:{" "}
							{pstDate(salesOrderData?._ts) ?? "Unknown Date"}
						</h2>
						<h2 className="font-bold text-lg">
							Status:{" "}
							<select
								className="w-28 rounded-xl border border-neutral-300 bg-white p-1 text-gray-700 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-blue-500 dark:border-gray-600 dark:bg-slate-700 dark:text-gray-300"
								onChange={(e) => {
									mutation.mutate({
										status: e.target.value,
										id: salesOrderData.id,
									});
								}}
								value={salesOrderData.status ?? ""}
							>
								{statusOptions.map((status, index) => (
									<option
										className="dark:bg-neutral-800"
										key={index}
										value={status.value}
									>
										{status.value}
									</option>
								))}
							</select>
						</h2>

						<h3 className="mt-4 flex justify-between items-center text-center">
							<p className="font-bold">SALES ORDER VALUE:</p>
							<p className="font-bold">
								{symbol} {salesOrderData?.totalAmount}
							</p>
						</h3>
						<h3 className="mt-4 flex justify-between items-center text-center">
							<p className="font-bold">Platinum Support:</p>
							<p className="font-bold">
								{salesOrderData?.platinumSupport}
							</p>
						</h3>
						<h3 className="mt-4 font-bold">Commission</h3>
						<table>
							<thead>
								<tr className="text-left border-b-2 border-neutral-200">
									<th className="px-1">Product</th>
									<th className="px-1">Sold</th>
									<th className="px-1">Cost</th>
									<th className="px-1">Quantity</th>
									<th className="px-1">Profit</th>
									<th className="px-1">Commission</th>
									<th className="px-1">Total</th>
								</tr>
							</thead>
							<tbody>
								{salesOrderData?.pricingSummary.map(
									(lineItem, index) => {
										let numMonths = 12;

										const profit =
											lineItem.unitPrice -
											lineItem.matchedUnitPrice;
										const annualProfit = profit * numMonths;
										let commission =
											annualProfit *
											lineItem.quantity *
											0.04;
										if (
											lineItem.productName.includes(
												"Platinum"
											)
										) {
											commission = 0;
										}

										if (
											commission < 0 &&
											isCoreSuite(
												lineItem.matchedSkuTitle
											)
										) {
											commission = 0;
										}

										if (
											lineItem.productName.includes(
												"Platinum"
											)
										) {
											commission = 0;
										}

										if (salesOrderData.currency !== "USD") {
											const conversionRate =
												lineItem.matchedUnitPriceInUsd /
												lineItem.matchedUnitPrice;

											// BUG: We rely on there being a unit price match in USD
											// for the conversion rate currently
											// If the product (e.g: legacy SKU) has no match price in USD
											// the commission for it will show as 0
											if (isNaN(conversionRate)) {
												commission = 0;
											} else {
												commission =
													commission * conversionRate;
											}
										}

										const totalOfCommissions =
											totalCommission(
												salesOrderData?.pricingSummary
											);

										commission = formatMoney(commission);

										return (
											<tr
												key={index}
												className={`${
													profit < 0
														? "bg-red-500/25 border-y border-neutral-200"
														: "bg-transparent border-y border-neutral-200"
												}`}
											>
												<td className="px-1">
													{lineItem.productName}
												</td>
												<td className="px-1">
													{symbol}
													{lineItem.unitPrice}
												</td>
												<td className="px-1">
													{symbol}
													{lineItem.matchedUnitPrice}
												</td>
												<td className="px-1">
													{lineItem.quantity}
												</td>
												<td
													className={profitClassName(
														profit
													)}
												>
													{symbol}
													{formatMoney(profit)}
												</td>
												<td
													className={
														commission.includes("-")
															? "px-1 text-red-500"
															: "px-1"
													}
												>
													{`$${commission}`}
												</td>
												<td
													className={
														totalOfCommissions.includes(
															"-"
														) // If total is negative
															? "px-1 text-red-500"
															: "px-1"
													}
												>
													{index === 0
														? `$${totalOfCommissions}`
														: ""}
												</td>
											</tr>
										);
									}
								)}
							</tbody>
						</table>
					</div>
					<div className="mt-4">
						<p>
							ChargeBee ID:{" "}
							{salesOrderData?.chargebeeSubscriptionId}
						</p>
						<p>Sales Order ID: {salesOrderData?.salesOrderId}</p>
						<p>
							SF Account Id:{" "}
							{salesOrderData?.salesForceAccountId ?? "N/A"}
						</p>
						<p>
							SF Opportunity Id:{" "}
							{salesOrderData?.salesForceOpportunityId ?? "N/A"}
						</p>
					</div>
				</div>
			) : (
				<div className="flex h-full justify-center items-center">
					<p className="text-lg font-bold text-black dark:text-white">
						Select a Sales Order to view its details
					</p>
				</div>
			)}
		</div>
	);
}

export default CommissionDetails;
