import { Search } from "@mui/icons-material";
// import { useMemo } from "react";

function DocumentsFilterNav({
	salespersons,
	selectedSalesperson,
	selectedMonth,
	searchInput,
	selectedStatus,
	handleSelectedSalesperson,
	handleSelectedMonth,
	handleSearchInput,
	handleSelectedStatus,
}) {
	const monthOptions = [
		{ "Jan": "01" },
		{ "Feb": "02" },
		{ "Mar": "03" },
		{ "Apr": "04" },
		{ "May": "05" },
		{ "Jun": "06" },
		{ "Jul": "07" },
		{ "Aug": "08" },
		{ "Sep": "09" },
		{ "Oct": "10" },
		{ "Nov": "11" },
		{ "Dec": "12" },
	];

	const salesOrderStatusOptions = ["Draft", "Pending", "Signed", "Rejected"];

	return (
		<div className="flex gap-4 w-full">
			<div className="flex flex-col">
				<div className="flex gap-4">
					<select
						className="block w-full rounded-xl border border-neutral-300 bg-white p-2.5 text-gray-700 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-blue-500 dark:border-gray-600 dark:bg-slate-700 dark:text-gray-300"
						onChange={(e) =>
							handleSelectedSalesperson(e.target.value)
						}
						value={selectedSalesperson}
					>
						{salespersons?.map((salesperson, index) => (
							<option
								className="dark:bg-neutral-800"
								key={index}
								value={salesperson.full_name}
							>
								{salesperson.full_name}
							</option>
						))}
					</select>
					<select
						className="block w-full rounded-xl border border-neutral-300 bg-white p-2.5 text-gray-700 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-blue-500 dark:border-gray-600 dark:bg-slate-700 dark:text-gray-300"
						onChange={(e) => handleSelectedMonth(e.target.value)}
						value={selectedMonth}
					>
						<option
							className="dark:bg-neutral-800"
							value="All Time"
						>
							All Time
						</option>
						{monthOptions.map((monthObj, index) => {
							const [monthName, monthValue] =
								Object.entries(monthObj)[0];
							return (
								<option
									className="dark:bg-neutral-800"
									key={index}
									value={monthValue}
								>
									{monthName}
								</option>
							);
						})}
					</select>
					<select
						className="block w-full rounded-xl border border-neutral-300 bg-white p-2.5 text-gray-700 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-blue-500 dark:border-gray-600 dark:bg-slate-700 dark:text-gray-300"
						onChange={(e) => handleSelectedStatus(e.target.value)}
						value={selectedStatus}
					>
						<option
							className="dark:bg-neutral-800"
							value="All Statuses"
						>
							All Statuses
						</option>
						{salesOrderStatusOptions.map((status, index) => (
							<option
								className="dark:bg-neutral-800"
								key={index}
								value={status}
							>
								{status}
							</option>
						))}
					</select>
				</div>
				<div className="hidden mt-4 w-full items-center rounded-full bg-gray-300 p-2 sm:flex dark:bg-neutral-800">
					<Search />
					<input
						className="ml-2 hidden h-full w-full flex-shrink bg-transparent text-black placeholder-gray-500 dark:text-white outline-none md:inline-flex"
						type="text"
						placeholder="Search for a sales order"
						value={searchInput}
						onChange={(e) => handleSearchInput(e.target.value)}
						maxLength={50}
					/>
				</div>
			</div>
		</div>
	);
}

export default DocumentsFilterNav;
