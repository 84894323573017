function Loading() {
    return (
        <div className="mt-5 flex">
            <div className="ml-2 h-8 w-8 rounded-full border-0 border-blue-300">
                <svg className="h-10 w-10 animate-spin rounded-full border-4 border-b-blue-500 border-l-transparent border-r-transparent border-t-transparent"></svg>
            </div>
        </div>
    );
}

export default Loading;
