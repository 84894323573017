import { useEffect, useState, useRef } from 'react';
import * as Sentry from '@sentry/react';
import { BugReport } from '@mui/icons-material';

function FeedbackButton({sidebarExpanded}) {
  const [feedback, setFeedback] = useState(null);

  useEffect(() => {
    const feedbackInstance = Sentry.getFeedback();
    setFeedback(feedbackInstance);
  }, []);

  const buttonRef = useRef();

  useEffect(() => {
    if (feedback && buttonRef.current) {
      const unsubscribe = feedback.attachTo(buttonRef.current);
      return () => {
        unsubscribe();
      };
    }
  }, [feedback]);

  return (
    <button 
        type="button"
        className={`flex w-full items-center ${sidebarExpanded ? "" : "justify-center"} gap-2 rounded-lg p-3 text-start text-neutral-800 hover:text-gray-500/10 dark:text-slate-400 dark:hover:text-slate-300`}
        ref={buttonRef}
    >
        <BugReport />
        {sidebarExpanded && "Report an Issue"}
    </button>
  );
}

export default FeedbackButton;
