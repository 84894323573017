function Home() {
	return (
		<div className="flex flex-col gap-3">
			<div className="p-6 space-y-8 text-gray-900 bg-white rounded-lg shadow-md dark:bg-gray-700 dark:text-gray-100">
				<h2 className="mb-5 text-2xl font-bold">📣 Announcements</h2>
				<p className="flex items-center">
					Welcome to the TTT Sales Hub (Beta)!
				</p>
			</div>
		</div>
	);
}

export default Home;
