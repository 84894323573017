function CommissionsList({
	activeSalesOrderId,
	salesOrders,
	debouncedSearchInput,
	selectedMonth,
	selectedStatus,
	selectedSalesperson,
	handleActiveSalesOrderChange,
}) {
	// TODO: Make this a generic component so it can be reused by the consumption report
	// commission tracking

	const pstDate = (epoch) => {
		const date = new Date(epoch * 1000); // Convert seconds to milliseconds

		// Convert to PST
		const options = {
			timeZone: "America/Los_Angeles",
			year: "numeric",
			month: "2-digit",
			day: "2-digit",
			hour: "2-digit",
			minute: "2-digit",
		};

		return date.toLocaleString("en-US", options);
	};

	const searchFilter = (salesOrder) => {
		if (
			debouncedSearchInput.trim().length === 17 ||
			debouncedSearchInput.trim().length === 16
		) {
			return salesOrder.chargebeeSubscriptionId
				.toLowerCase()
				.includes(debouncedSearchInput.trim().toLowerCase());
		} else if (debouncedSearchInput.trim().length === 36) {
			return salesOrder.salesOrderId
				.toLowerCase()
				.includes(debouncedSearchInput.trim().toLowerCase());
		} else if (debouncedSearchInput.trim().length > 2) {
			return salesOrder.companyName
				.toLowerCase()
				.includes(debouncedSearchInput.trim().toLowerCase());
		} else {
			return true;
		}
	};

	const dateFilter = (salesOrder) => {
		const date = pstDate(salesOrder?._ts);
		const month = date.split("/")[0];
		if (month === selectedMonth) {
			return salesOrder;
		} else if (selectedMonth === "All Time" || selectedMonth === "") {
			return salesOrder;
		}
	};

	const statusFilter = (salesOrder) => {
		if (selectedStatus === "All Statuses") {
			return salesOrder;
		} else if (salesOrder?.status) {
			return salesOrder.status === selectedStatus;
		}
	};

	const salespersonFilter = (salesOrder) => {
		if (selectedSalesperson === "All Salespersons") {
			return salesOrder;
		} else if (salesOrder?.salesPersonName) {
			return salesOrder.salesPersonName === selectedSalesperson;
		}
	};

	return (
		<div className="w-1/4 h-[66vh] overflow-y-auto p-4 dark:border-gray-600 dark:bg-slate-700 border-gray-300 border shadow-md rounded-lg">
			<ul>
				{salesOrders && salesOrders.length > 0 ? (
					salesOrders
						.filter(searchFilter)
						.filter(dateFilter)
						.filter(statusFilter)
						.filter(salespersonFilter)
						.sort((a, b) => b._ts - a._ts)
						.map((salesOrder, index) => {
							return (
								<li
									key={index}
									className={`${activeSalesOrderId === salesOrder.id ? "dark:bg-blue-800 dark:hover:bg-blue-700 bg-blue-400 hover:bg-blue-500" : "dark:bg-gray-600 dark:hover:bg-gray-500 bg-gray-300 hover:bg-gray-400"} transition-all duration-150 shadow-md mb-2 flex flex-col items-start min-h-16 overflow-x-hidden rounded-lg p-2 text-black hover:cursor-pointer dark:text-white`}
									onClick={() => {
										handleActiveSalesOrderChange(
											salesOrder.id
										);
									}}
								>
									<p className="font-bold">
										{salesOrder?.companyName === ""
											? "Unknown Company"
											: salesOrder?.companyName}
									</p>
									<p className="text-sm">
										{pstDate(salesOrder?._ts)}
									</p>
									<p className="text-sm">
										{salesOrder?.chargebeeSubscriptionId}
									</p>
								</li>
							);
						})
				) : (
					<div className="flex justify-center items-center">
						No sales orders found for {selectedSalesperson}!
					</div>
				)}
			</ul>
		</div>
	);
}

export default CommissionsList;
