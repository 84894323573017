import logoPath from "../../assets/ttt-horizontal-lockup_primary.png";
import "react-datepicker/dist/react-datepicker.css";
import {
	Page,
	Text,
	View,
	Document,
	StyleSheet,
	Image,
} from "@react-pdf/renderer";
// import { getPlatinumSupportDetails } from "../../utils";

const styles = StyleSheet.create({
	page: {
		padding: 30,
		fontSize: 12,
		position: "relative",
		paddingBottom: 100,
	},
	heading: {
		fontSize: 12,
		marginBottom: 10,
	},
	watermarkOverlayContainer: {
		position: "absolute",
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		justifyContent: "center",
		alignItems: "center",
	},
	watermarkOverlay: {
		transform: "rotate(45deg)",
		fontSize: 120,
		fontWeight: "black",
		color: "#25252530",
	},
	boldText: {
		fontWeight: "bold",
	},
	section: {
		marginBottom: 20,
	},
	logoSection: {
		marginBottom: 20,
	},
	fromToSection: {
		flexDirection: "row",
		justifyContent: "center",
		textAlign: "center",
		marginBottom: 5,
	},
	horizontalLine: {
		borderBottomColor: "#e6e6e6",
		borderBottomWidth: 2,
		marginVertical: 10,
	},
	servicesSection: {
		marginTop: 5,
		marginBottom: 0,
	},
	pricingSummarySection: {
		marginTop: 0,
		marginBottom: 0,
	},
	platinumSupportSection: {
		marginTop: 0,
		marginBottom: 0,
	},
	table: {
		display: "table",
		width: "auto",
		marginBottom: 5,
		borderStyle: "solid",
		borderWidth: 1,
		borderRightWidth: 0,
		borderBottomWidth: 0,
		borderColor: "#252525",
	},
	tableRow: {
		flexDirection: "row",
	},
	tableCellHeader: {
		margin: 5,
		fontWeight: "bold",
	},
	tableCell: {
		margin: 5,
		textAlign: "center",
	},
	tableCellProductName: {
		margin: 5,
		textAlign: "left",
	},
	descriptionText: {
		fontSize: 10,
		color: "gray",
		marginTop: 2,
		marginLeft: 5,
		textAlign: "left",
		marginBottom: 5,
	},
	tableTotalCell: {
		padding: 5,
		width: "100%",
		fontWeight: "bold",
		fontFamily: "Calibri",
		fontSize: 14,
		textAlign: "right",
	},

	billingTable: {
		display: "table",
		width: "auto",
		marginBottom: 45,
		borderStyle: "solid",
		borderWidth: 1,
		borderRightWidth: 0,
		borderBottomWidth: 0,
		borderColor: "#252525",
	},
	totalAmount: {
		textAlign: "right",
		marginRight: 30,
		marginTop: 5,
	},
	totalText: {
		fontSize: 14,
	},
	termsAndSignatureBlock: {
		// padding: 10,
		border: "1px solid #252525",
		marginBottom: "auto",
	},
	terms: {
		padding: 5,
		fontSize: 10,
	},
	signatureBlockRow: {
		flexDirection: "row",
	},
	signatureBlockCol: {
		width: "50%",
		padding: 5,
		// border: "1px solid #252525",
		fontSize: 10,
	},
	signatureBlockHeader: {
		backgroundColor: "#e0f7e0",
		textAlign: "start",
		fontWeight: "bold",
	},
	footer: {
		position: "absolute",
		bottom: 30,
		left: 30,
		right: 30,
		fontSize: 8,
		textAlign: "center",
	},
	salesOrderTitle: {
		fontFamily: "Mollen",
		fontSize: 12,
		fontWeight: "bold",
		textAlign: "center",
	},
	sectionTitle: {
		fontFamily: "Calibri",
		fontSize: 12,
		fontWeight: "bold",
		marginBottom: 10,
		marginTop: 10,
	},
	platinumSupportIcon: {
		width: 20,
		height: 20,
		marginRight: 5,
	},
	platinumSupportTable: {
		display: "table",
		width: "100%",
		marginBottom: 45,
		borderStyle: "solid",
		borderWidth: 1,
		borderRightWidth: 0,
		borderBottomWidth: 0,
		borderColor: "#252525",
	},
	platinumSupportCell: {
		margin: 5,
		fontSize: 10,
		color: "gray",
	},
	billingTableColHeader: {
		width: "20%",
		borderStyle: "solid",
		borderWidth: 1,
		borderLeftWidth: 0,
		borderTopWidth: 0,
		backgroundColor: "#e0f7e0",
		borderColor: "#252525",
		textAlign: "center",
		fontFamily: "Calibri",
		fontWeight: "bold",
	},
	billingTableCol: {
		width: "20%",
		borderStyle: "solid",
		borderWidth: 1,
		borderLeftWidth: 0,
		borderTopWidth: 0,
		borderColor: "#252525",
	},
	pricingSummaryTableColHeaderProduct: {
		width: "40%",
		borderStyle: "solid",
		borderWidth: 1,
		borderLeftWidth: 0,
		borderTopWidth: 0,
		backgroundColor: "lightgray",
		borderColor: "#252525",
		textAlign: "center",
		fontFamily: "Calibri",
		fontWeight: "bold",
	},
	pricingSummaryTableColHeader: {
		width: "20%",
		borderStyle: "solid",
		borderWidth: 1,
		borderLeftWidth: 0,
		borderTopWidth: 0,
		backgroundColor: "lightgray",
		borderColor: "#252525",
		textAlign: "center",
		fontFamily: "Calibri",
		fontWeight: "bold",
	},
	pricingSummaryTableColProduct: {
		width: "40%",
		borderStyle: "solid",
		borderWidth: 1,
		borderLeftWidth: 0,
		borderTopWidth: 0,
		borderColor: "#252525",
	},
	pricingSummaryTableCol: {
		width: "20%",
		borderStyle: "solid",
		borderWidth: 1,
		borderLeftWidth: 0,
		borderTopWidth: 0,
		borderColor: "#252525",
	},

	platinumSupportTableColHeader: {
		width: "50%",
		borderStyle: "solid",
		borderWidth: 1,
		borderLeftWidth: 0,
		borderTopWidth: 0,
		backgroundColor: "#e0f7e0",
		borderColor: "#252525",
		textAlign: "center",
		fontFamily: "Calibri",
		fontWeight: "bold",
	},
	platinumSupportTableCol: {
		width: "50%",
		borderStyle: "solid",
		borderWidth: 1,
		borderLeftWidth: 0,
		borderTopWidth: 0,
		borderColor: "#252525",
	},
	platinumSupportTierCell: {
		width: "20%",
		borderStyle: "solid",
		borderWidth: 1,
		borderLeftWidth: 0,
		borderTopWidth: 0,
		borderColor: "#252525",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		flexDirection: "column",
	},
	platinumSupportBenefitsCell: {
		width: "80%",
		borderStyle: "solid",
		borderWidth: 1,
		borderLeftWidth: 0,
		borderTopWidth: 0,
		borderColor: "#252525",
		padding: 5,
	},
	platinumSupportTierHeader: {
		width: "20%",
		borderStyle: "solid",
		borderWidth: 1,
		borderLeftWidth: 0,
		borderTopWidth: 0,
		backgroundColor: "#e0f7e0",
		borderColor: "#252525",
		textAlign: "center",
		fontFamily: "Calibri",
		fontWeight: "bold",
	},
	platinumSupportBenefitsHeader: {
		width: "80%",
		borderStyle: "solid",
		borderWidth: 1,
		borderLeftWidth: 0,
		borderTopWidth: 0,
		backgroundColor: "#e0f7e0",
		borderColor: "#252525",
		textAlign: "center",
		fontFamily: "Calibri",
		fontWeight: "bold",
	},
	tableColEmpty: {
		width: "50%",
		borderStyle: "none",
		borderWidth: 1,
		borderColor: "transparent",
	},
	tableColTotal: {
		width: "25%",
		borderStyle: "solid",
		borderWidth: 1,
		borderLeftWidth: 1,
		borderTopWidth: 0,
		borderColor: "transparent",
		textAlign: "right",
		fontFamily: "Calibri",
		fontWeight: "bold",
	},
	tableCellTotal: {
		width: "25%",
		margin: 5,
		fontWeight: "bold",
		borderColor: "transparent",
	},
	tableColHeader: {
		width: "25%",
		borderStyle: "solid",
		borderWidth: 1,
		borderLeftWidth: 0,
		borderTopWidth: 0,
		backgroundColor: "#e0f7e0",
		borderColor: "#252525",
		textAlign: "center",
		fontFamily: "Calibri",
		fontWeight: "bold",
	},
	tableCol: {
		width: "25%",
		borderStyle: "solid",
		borderWidth: 1,
		borderLeftWidth: 0,
		borderTopWidth: 0,
		borderColor: "#252525",
	},
	totalRowCol: {
		width: "80%",
		borderStyle: "solid",
		borderWidth: 1,
		borderLeftWidth: 0,
		borderTopWidth: 0,
		borderColor: "#252525",
		backgroundColor: "#ffffff",
		textAlign: "center",
		fontWeight: "bold",
		fontSize: 14,
		justifyContent: "center",
		alignItems: "center",
		display: "flex",
	},
	totalRowValueCol: {
		width: "100%",
		borderStyle: "solid",
		// borderWidth: 1,
		// borderLeftWidth: 0,
		// borderTopWidth: 0,
		// borderColor: "#252525",
		// backgroundColor: "#bebebe",
		// textAlign: "center",
		justifyContent: "end",
		alignItems: "center",
		fontWeight: "bold",
		fontSize: 13,
		paddingRight: 10,
		display: "flex-end",
		marginBottom: 15,
	},

	currencyNote: {
		fontSize: 10,
		fontStyle: "italic",
		marginTop: -10,
		textAlign: "right",
		position: "relative",
		top: -20,
	},
	infoSection: {
		fontSize: 12,
		fontWeight: "normal",
	},
});

const currencyOptions = [
	{ value: "USD", label: "USD", symbol: "$", flag: "🇺🇸" },
	{ value: "CAD", label: "CAD", symbol: "$", flag: "🇨🇦" },
	{ value: "GBP", label: "GBP", symbol: "£", flag: "🇬🇧" },
	{ value: "EUR", label: "EUR", symbol: "€", flag: "🇪🇺" },
	{ value: "CHF", label: "CHF", symbol: "Chf.", flag: "🇨🇭" },
	{ value: "DKK", label: "DKK", symbol: "kr", flag: "🇩🇰" },
	{ value: "NOK", label: "NOK", symbol: "kr", flag: "🇳🇴" },
	{ value: "SEK", label: "SEK", symbol: "kr", flag: "🇸🇪" },
];

function SalesOrderPDFBasic({
	formData,
	addons,
	// platinumSupport
}) {
	const totalAmount = addons.reduce((total, addon) => total + addon.amount, 0);
	const totalContractValue = totalAmount * (formData.termMonths || 1);
	// const platinumSupportDetails = getPlatinumSupportDetails(platinumSupport);

	const getCurrencyOption = (currencyCode) => {
		return currencyOptions.find((option) => option.value === currencyCode);
	};

	const currencyOption =
		getCurrencyOption(addons.length > 0 ? addons[0].currency : "USD") || {};

	const currencySymbol = currencyOption.symbol || "$";
	// const currencyLabel = currencyOption.label || "USD";

	return (
		<Document>
			<Page style={styles.page} size="A4" orientation="portrait">
				<View
					style={{
						flexDirection: "row",
						justifyContent: "space-between",
						alignItems: "top",
					}}
				>
					<Image src={logoPath} style={{ width: "150", height: "25" }} />
					<View>
						{currencyOption.label !== "USD" &&
							currencyOption.label !== "CAD" ? (
							<>
								<Text style={styles.infoSection}>Trusted Tech Team Ltd</Text>
								<Text style={styles.infoSection}>322 High Holborn</Text>
								<Text style={styles.infoSection}>London</Text>
								<Text style={styles.infoSection}>WC1V 7PB</Text>
							</>
						) : (
							<>
								<Text style={styles.infoSection}>Trusted Tech Team, LLC</Text>
								<Text style={styles.infoSection}>5171 California Ave</Text>
								<Text style={styles.infoSection}>Suite 250</Text>
								<Text style={styles.infoSection}>Irvine, CA 92617</Text>
								<Text style={styles.infoSection}>United States</Text>
							</>
						)}
					</View>
					<View>
						<Text style={styles.infoSection}>
							Sales Order for: {formData.companyName}
						</Text>
						<Text style={styles.infoSection}>
							Offer Valid Through:{" "}
							{formData.validThrough.toLocaleDateString("en-US")}
						</Text>
						<Text style={styles.infoSection}>
							Prepared by: {formData.salesPersonName}
						</Text>
					</View>
				</View>
				<View style={styles.horizontalLine} />
				<View style={styles.fromToSection}>
					<Text style={styles.salesOrderTitle}>Sales Order</Text>
				</View>
				<View
					style={{
						flexDirection: "row",
						justifyContent: "space-between",
						alignItems: "top",
					}}
				>
					<View style={{ textAlign: "left", width: "50%" }}>
						<Text style={[styles.infoSection, { fontWeight: "bold" }]}>
							Customer Information:
						</Text>
						<Text style={styles.infoSection}>
							{formData.companyName}
							{' ("You")'}
						</Text>
					</View>
					<View style={{ textAlign: "left", width: "50%" }}>
						{/* <Text style={[styles.infoSection, { fontWeight: "bold" }]}>
							Address:
						</Text>
						<Text style={styles.infoSection}>{formData.companyAddress}</Text>
						<Text style={styles.infoSection}>
							{formData.companyCity}, {formData.companyState},{" "}
							{formData.companyZIP}
						</Text> */}
					</View>
				</View>
				<Text style={styles.sectionTitle}>Services</Text>
				<View style={styles.billingTable}>
					<View style={styles.tableRow}>
						<View style={styles.billingTableColHeader}>
							<Text style={styles.tableCellHeader}>Start Date</Text>
						</View>
						<View style={styles.billingTableColHeader}>
							<Text style={styles.tableCellHeader}>Net Terms</Text>
						</View>
						<View style={styles.billingTableColHeader}>
							<Text style={styles.tableCellHeader}>Billing Frequency</Text>
						</View>
						<View style={styles.billingTableColHeader}>
							<Text style={styles.tableCellHeader}>Commitment Term</Text>
						</View>
						<View style={styles.billingTableColHeader}>
							<Text style={styles.tableCellHeader}>Total Contract Value</Text>
						</View>
					</View>
					<View style={styles.tableRow}>
						<View style={styles.billingTableCol}>
							<Text style={styles.tableCell}>
								{formData.renewalDate.toLocaleDateString("en-US")}
							</Text>
						</View>
						<View style={styles.billingTableCol}>
							<Text style={styles.tableCell}>{formData.netTerms}</Text>
						</View>
						<View style={styles.billingTableCol}>
							<Text style={styles.tableCell}>{formData.billingFrequency}</Text>
						</View>
						<View style={styles.billingTableCol}>
							<Text style={styles.tableCell}>
								{formData.termMonths} Month(s)
							</Text>
						</View>
						<View style={styles.billingTableCol}>
							<Text style={styles.tableCell}>
								{currencySymbol}
								{(totalContractValue / 100).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}
							</Text>
						</View>
					</View>
				</View>
				<Text style={styles.sectionTitle}>Pricing Summary</Text>
				<View style={styles.table} >
					<View style={styles.tableRow}>
						<View style={styles.pricingSummaryTableColHeaderProduct}>
							<Text style={styles.tableCellHeader}>Name</Text>
						</View>
						<View style={styles.pricingSummaryTableColHeader}>
							<Text style={styles.tableCellHeader}>Quantity</Text>
						</View>
						<View style={styles.pricingSummaryTableColHeader}>
							<Text style={styles.tableCellHeader}>Unit Price</Text>
						</View>
						<View style={styles.pricingSummaryTableColHeader}>
							<Text style={styles.tableCellHeader}>Amount</Text>
						</View>
					</View>
					{addons.map((addon, index) => (
						<View style={styles.tableRow} key={index} wrap={false}>
							<View style={styles.pricingSummaryTableColProduct}>
								<Text style={styles.tableCellProductName}>{addon.name}</Text>
								{addon.description && (
									<Text style={styles.descriptionText}>
										{addon.description}
									</Text>
								)}
							</View>
							<View style={styles.pricingSummaryTableCol}>
								<Text style={styles.tableCell}>{addon.quantity}</Text>
							</View>
							<View style={styles.pricingSummaryTableCol}>
								<Text style={styles.tableCell}>
									{currencyOption.symbol}
									{(addon.unit_price / 100).toFixed(2)}
								</Text>
							</View>
							<View style={styles.pricingSummaryTableCol}>
								<Text style={styles.tableCell}>
									{currencyOption.symbol}
									{(addon.amount / 100).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}
								</Text>
							</View>
						</View>
					))}
				</View>
				<View style={styles.totalRowValueCol}>
					<Text style={styles.tableTotalCell}>
						Total {currencyOption.symbol}
						{(totalAmount / 100).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}
					</Text>
				</View>
				{/* <Text style={styles.currencyNote}>
                    *Prices shown in {currencyLabel}
                </Text> */}
				{/* {platinumSupportDetails && (
                    <>
                        <Text style={styles.sectionTitle}>PLATINUM SUPPORT (ADD-ONS)</Text>
                        <View style={styles.platinumSupportTable} wrap={false}>
                            <View style={styles.tableRow}>
                                <View style={styles.platinumSupportTierHeader}>
                                    <Text style={styles.tableCellHeader}>Tier</Text>
                                </View>
                                <View style={styles.platinumSupportBenefitsHeader}>
                                    <Text style={styles.tableCellHeader}>Benefits</Text>
                                </View>
                            </View>
                            <View style={styles.tableRow}>
                                <View style={styles.platinumSupportTierCell}>
                                    <View
                                        style={{
                                            flexDirection: "row",
                                            alignItems: "center",
                                            justifyContent: "center",
                                        }}
                                    >
                                        <Image
                                            src={platinumSupportDetails.icon}
                                            style={styles.platinumSupportIcon}
                                        />
                                        <Text>{platinumSupport}</Text>
                                    </View>
                                </View>
                                <View style={styles.platinumSupportBenefitsCell}>
                                    {platinumSupportDetails.benefits.map((benefit, index) => (
                                        <Text key={index} style={styles.platinumSupportCell}>
                                            • {benefit}
                                        </Text>
                                    ))}
                                </View>
                            </View>
                        </View>
                    </>
                )} */}
				<View style={{ marginBottom: 20 }} />
				<View style={styles.termsAndSignatureBlock} wrap={false} >
					<Text
						style={[
							styles.boldText,
							{
								backgroundColor: "#e0f7e0",
								padding: 5,
								borderBottomWidth: 1,
							},
						]}
					>
						Terms of Service
					</Text>
					<Text style={styles.terms}>
						The Terms of Service apply to this Sales Order, license increases,
						and any renewals. You are subject to Microsoft&apos;s terms and
						conditions. Notwithstanding, you are responsible for all payments
						due for the entire duration of the Commitment Term as specified in
						this Sales Order. For billing purposes, Monthly Net Terms is defined
						as four consecutive weeks. Failure to make full, timely payments or
						violating the terms of this Sales Order can result in service
						disruption, loss of savings and/or Net Terms. TTT may modify or
						terminate this Sales Order as a result of changes imposed by
						third-party licensors or vendors, such as Microsoft. You have the
						flexibility to downgrade or reduce licenses up to seven calendar
						days from the Services Start Date. Licenses can be added at any time
						and will be co-termed. For renewals, please send any licensing
						change requests in writing to csp@trustedtechteam.com at least 30
						days prior to your next Commitment Term.
					</Text>
					<Text style={[styles.terms, { borderTopWidth: 1 }]}>
						By signing below, you accept the contents of this Sales Order.
					</Text>
					<View
						style={[
							styles.signatureBlockRow,
							{ borderTopWidth: 1, borderBottomWidth: 1 },
						]}
					>
						<Text
							style={[
								styles.signatureBlockCol,
								styles.signatureBlockHeader,
								{ borderRightWidth: 1 },
							]}
						>
							Trusted Tech Team, LLC (Authorized Signatory Only)
						</Text>
						<Text
							style={[styles.signatureBlockCol, styles.signatureBlockHeader]}
						>
							Client (Authorized Signatory Only)
						</Text>
					</View>
					<View style={[styles.signatureBlockRow, { borderBottomWidth: 1 }]}>
						<Text style={[styles.signatureBlockCol, { borderRightWidth: 1 }]}>
							Printed Name:
						</Text>
						<Text style={styles.signatureBlockCol}>Printed Name:</Text>
					</View>
					<View style={[styles.signatureBlockRow, { borderBottomWidth: 1 }]}>
						<Text style={[styles.signatureBlockCol, { borderRightWidth: 1 }]}>
							Signature:
						</Text>
						<Text style={styles.signatureBlockCol}>Signature:</Text>
					</View>
					<View style={styles.signatureBlockRow}>
						<Text style={[styles.signatureBlockCol, { borderRightWidth: 1 }]}>
							Date:
						</Text>
						<Text style={styles.signatureBlockCol}>Date:</Text>
					</View>
				</View>
				{/* <View style={{ marginTop: 20 }} /> */}
				<View style={styles.footer} wrap={false} fixed>
				<View style={styles.horizontalLine} />
					<Text>CONFIDENTIAL & PROPRIETARY</Text>
					<Text>
						This document is exclusively for commercial use between Trusted Tech
						Team, and Customer, and is considered Confidential Information.
						according to the Terms, not to be disclosed to third-parties unless
						such disclosure is explicitly permitted under this Agreement.
					</Text>
				</View>
				<View style={styles.watermarkOverlayContainer} wrap={false} fixed>
					<Text style={styles.watermarkOverlay}>DRAFT</Text>
				</View>
			</Page>
		</Document>
	);
}

export default SalesOrderPDFBasic;
