/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import Select from "react-select";
import {
	Page,
	Text,
	View,
	Document,
	StyleSheet,
	Image,
	pdf,
	Font,
	Link,
} from "@react-pdf/renderer";
import logoPath from "../assets/ttt-horizontal-lockup_primary.png";
import logoPath2 from "../assets/microsoft-solutions-partner_no-background.jpg";
import calibri from "../assets/calibri.ttf";
import calibrib from "../assets/calibrib.ttf";
import calibrii from "../assets/calibrii.ttf";
import calibriz from "../assets/calibriz.ttf";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FaUser, FaTable, FaFileInvoice } from "react-icons/fa";
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";
import axios from "axios";
import { SalesOrderPDFBasic } from "./SO_NCE_template_basic";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import { ImDownload } from "react-icons/im";
import { ImSearch } from "react-icons/im";
import { ImPlus } from "react-icons/im";
import { ImFileText } from "react-icons/im";
import { ImBin } from "react-icons/im";
import { BlobServiceClient } from "@azure/storage-blob";
import { Buffer } from "buffer"; //need this for blob upload
import { generateSHA256, formatMoney } from "../utils";
import {
	uploadConsumptionReportToCosmos,
	uploadSalesOrderToCosmos,
} from "../utils";
import { serverAddress } from "../authConfig";
import { useAuth } from "./AuthProvider";
window.Buffer = Buffer;

Font.register({
	family: "Calibri",
	fonts: [
		{ src: calibri },
		{ src: calibrib, fontWeight: "bold" },
		{ src: calibrii, fontStyle: "italic" },
		{ src: calibriz, fontWeight: "bold", fontStyle: "italic" },
	],
});

const senderOptions = [
	{
		value: "NONE",
		label: "NONE",
		email: "N/A",
		phone: "N/A",
	},
	{
		value: "Justin Sharrocks",
		label: "Justin Sharrocks",
		email: "justin.sharrocks@trustedtechteam.com",
		phone: "(44) 754 309 7701",
	},
	{
		value: "Lindsey Lowell",
		label: "Lindsey Lowell",
		email: "lindsey.lowell@trustedtechteam.com",
		phone: "(44) 754 309 7701",
	},
	{
		value: "Pippa Sophie Jones ",
		label: "Pippa Sophie Jones ",
		email: "pippa.jones@trustedtechteam.com",
		phone: "(44) 7879 384 718",
	},
	{
		value: "Mehrzad Mohammadi",
		label: "Mehrzad Mohammadi",
		email: "mehrzad.mohammadi@trustedtechteam.com",
		phone: "(745) 528-6457",
	},
	{
		value: "Bilal Aziz",
		label: "Bilal Aziz",
		email: "Bill.Aziz@trustedtechteam.com",
		phone: "(949) 617-2845",
	},
	{
		value: "Fabian Svensson",
		label: "Fabian Svensson",
		email: "fabian.svensson@trustedtechteam.com",
		phone: "(739) 277-3952",
	},
];

const platSupportOptions = [
	{ value: "No", label: "No" },
	{ value: "Yes", label: "Yes" },
];

const currencyOptions = [
	{ value: "USD", label: "🇺🇸" },
	{ value: "CAD", label: "🇨🇦" },
	{ value: "GBP", label: "🇬🇧" },
	{ value: "EUR", label: "🇪🇺" },
	{ value: "CHF", label: "🇨🇭" },
	{ value: "DKK", label: "🇩🇰" },
	{ value: "NOK", label: "🇳🇴" },
	{ value: "SEK", label: "🇸🇪" },
];

const currencySymbols = {
	USD: "$",
	CAD: "$",
	GBP: "£",
	EUR: "€",
	CHF: "Chf.",
	DKK: "kr",
	NOK: "kr",
	SEK: "kr",
};

const currentVendorOptions = [
	{ value: "Current Vendor", label: "Current Vendor" },
	{ value: "Microsoft", label: "Microsoft" },
	{ value: "CDW", label: "CDW" },
	{ value: "Rackspace", label: "Rackspace" },
	{ value: "Appriver", label: "Appriver" },
	{ value: "Pax8", label: "Pax8" },
	{ value: "PC Connection", label: "PC Connection" },
	{ value: "Dell", label: "Dell" },
	{ value: "Insight Global", label: "Insight Global" },
	{ value: "Ingram Micro", label: "Ingram Micro" },
	{ value: "Synnex", label: "Synnex" },
	{ value: "TechData", label: "TechData" },
	{ value: "MessageOpps", label: "MessageOpps" },
];

const netTermsOptions = [
	{ value: "NONE", label: "NONE" },
	{ value: "Net 7", label: "Net 7" },
	{ value: "Net 15", label: "Net 15" },
	{ value: "Net 30", label: "Net 30" },
	{ value: "Net 45", label: "Net 45" },
	{ value: "Net 60", label: "Net 60" },
	{ value: "Net 90", label: "Net 90" },
];

const billingFrequencyOptions = [
	{ value: "NONE", label: "NONE" },
	{ value: "Monthly", label: "Monthly" },
	{ value: "Annually", label: "Annually" },
];

const styles = StyleSheet.create({
	page: {
		flexDirection: "column",
		padding: 30,
		fontFamily: "Calibri",
	},
	section: {
		marginBottom: 10,
	},
	heading: {
		fontSize: 12,
		marginBottom: 10,
		fontWeight: "bold",
	},
	boldText: {
		fontWeight: "bold",
	},
	table: {
		display: "table",
		width: "auto",
		marginTop: 20,
	},
	tableRow: {
		flexDirection: "row",
	},
	tableRowHeader: {
		flexDirection: "row",
		backgroundColor: "#f0f0f0",
	},
	tableRowEven: {
		flexDirection: "row",
		backgroundColor: "#f9f9f9",
	},
	tableRowOdd: {
		flexDirection: "row",
		backgroundColor: "#ffffff",
	},
	tableCol: {
		padding: 8,
	},
	tableCell: {
		fontSize: 10,
		textAlign: "center",
	},
	tableCellProduct: {
		margin: "auto",
		marginTop: 5,
		fontSize: 10,
	},
	tableHeaderRed: {
		backgroundColor: "#dc143c",
		color: "black",
		fontWeight: "bold",
	},
	tableHeaderGreen: {
		backgroundColor: "#60C659",
		color: "black",
		fontWeight: "bold",
	},
	tableCellRed: {
		backgroundColor: "#FFCCCC",
		padding: 8,
	},
	tableCellGreen: {
		backgroundColor: "#E0FFEB",
		padding: 8,
	},
	clientSenderInfo: {
		flexDirection: "row",
		justifyContent: "space-between",
		marginTop: 20,
	},
	clientInfo: {
		width: "50%",
		fontSize: 10,
	},
	senderInfo: {
		width: "50%",
		fontSize: 10,
		textAlign: "right",
	},
	totalSection: {
		marginTop: 20,
		fontSize: 13,
		fontStyle: "italic",
		textAlign: "right",
		fontWeight: "bold",
	},
	productCol: {
		width: "33%",
	},
	smallCol: {
		width: "15%",
	},
	summaryImage: {
		width: 170,
		height: "auto",
		marginTop: 20,
	},
	footer: {
		position: "absolute",
		bottom: 30,
		left: 30,
		right: 30,
		textAlign: "center",
		fontSize: 9,
		color: "gray",
	},
	link: {
		color: "blue",
		textDecoration: "underline",
	},
});

function applyDiscounts(row, tttDiscount, currentVendorDiscount) {
	const tttPrice =
		row.product && row.product.label.startsWith("Platinum Support")
			? row.msrp
			: row.msrp * (1 - tttDiscount / 100);

	const currentVendorPrice = row.msrp * (1 - currentVendorDiscount / 100);
	let tttTotal = row.assignedSeats * tttPrice;
	let currentVendorTotal = row.assignedSeats * currentVendorPrice;
	let lineItemSavings = currentVendorTotal - tttTotal;

	if (row.assignedSeats === 0) {
		tttTotal = 0;
	}

	if (row.totalSeats === 0) {
		currentVendorTotal = 0;
	}

	const tttPricePerSeat = row.assignedSeats === 0 ? 0 : tttPrice;
	const currentVendorPricePerSeat =
		row.totalSeats === 0 ? 0 : currentVendorPrice;

	return {
		...row,
		currentVendorPrice: currentVendorPricePerSeat,
		tttPrice: tttPricePerSeat,
		tttTotal,
		currentVendorTotal,
		lineItemSavings,
	};
}

const ConsumptionReportPDF = ({
	rows,
	clientName,
	clientCompany,
	clientEmail,
	clientPhone,
	selectedSender,
	senderEmail,
	senderPhone,
	totalCurrentVendor,
	totalTtt,
	totalSavings,
	totalAnnualSavings,
	currency,
	currentDate,
	currentVendor,
	referenceCode,
}) => (
	<Document>
		<Page style={styles.page} size="A4" orientation="landscape">
			<View
				style={{
					flexDirection: "row",
					justifyContent: "space-between",
					alignItems: "center",
				}}
			>
				<Image src={logoPath} style={{ width: 150, height: "auto" }} />
				<View style={{ textAlign: "right" }}>
					<Text style={[styles.heading, styles.boldText]}>
						{" "}
						Date: {currentDate.toLocaleDateString("en-US")}
					</Text>
					<Text style={[styles.heading, styles.boldText]}>
						Reference: {referenceCode}
					</Text>
				</View>
			</View>
			<View style={styles.clientSenderInfo}>
				<View style={styles.clientInfo}>
					<Text style={[styles.heading, styles.boldText]}>Prepared For:</Text>
					<Text>{clientName}</Text>
					<Text>{clientCompany}</Text>
					<Text>{clientEmail}</Text>
					<Text>{clientPhone}</Text>
				</View>
				<View style={styles.senderInfo}>
					<Text style={[styles.heading, styles.boldText]}>Prepared By:</Text>
					<Text>{selectedSender ? selectedSender.label : ""}</Text>
					<Text>{senderEmail}</Text>
					<Text>{senderPhone}</Text>
				</View>
			</View>
			<View style={styles.section}>
				<View style={styles.table}>
					<View style={[styles.tableRow, styles.tableRowHeader]}>
						<View style={[styles.tableCol, styles.productCol]}>
							<Text style={[styles.tableCell, styles.boldText]}>Product</Text>
						</View>
						<View style={[styles.tableCol, styles.smallCol]}>
							<Text style={[styles.tableCell, styles.boldText]}>
								Assigned Seats
							</Text>
						</View>
						<View style={[styles.tableCol, styles.smallCol]}>
							<Text style={[styles.tableCell, styles.boldText]}>
								Total Seats
							</Text>
						</View>
						<View style={[styles.tableCol, styles.smallCol]}>
							<Text style={[styles.tableCell, styles.boldText]}>MSRP</Text>
						</View>
						<View
							style={[styles.tableCol, styles.smallCol, styles.tableHeaderRed]}
						>
							<Text style={[styles.tableCell, styles.boldText]}>
								{currentVendor} {currencySymbols[currency]}/Seat
							</Text>
						</View>
						<View
							style={[styles.tableCol, styles.smallCol, styles.tableHeaderRed]}
						>
							<Text style={[styles.tableCell, styles.boldText]}>
								{currentVendor} {currencySymbols[currency]} (Total)
							</Text>
						</View>
						<View
							style={[
								styles.tableCol,
								styles.smallCol,
								styles.tableHeaderGreen,
							]}
						>
							<Text style={[styles.tableCell, styles.boldText]}>TTT/Seat</Text>
						</View>
						<View
							style={[
								styles.tableCol,
								styles.smallCol,
								styles.tableHeaderGreen,
							]}
						>
							<Text style={[styles.tableCell, styles.boldText]}>
								TTT (Total)
							</Text>
						</View>
						<View style={[styles.tableCol, styles.smallCol]}>
							<Text style={[styles.tableCell, styles.boldText]}>
								Line Item Savings
							</Text>
						</View>
					</View>
					{rows.map((row, index) => (
						<View
							style={[
								styles.tableRow,
								index % 2 === 0 ? styles.tableRowEven : styles.tableRowOdd,
							]}
							key={row.id}
							wrap={false}
						>
							<View style={[styles.tableCol, styles.productCol]}>
								<Text style={styles.tableCell}>
									{row.product ? row.product.label : "Select a product"}
								</Text>
							</View>
							<View style={[styles.tableCol, styles.smallCol]}>
								<Text style={styles.tableCell}>{row.assignedSeats}</Text>
							</View>
							<View style={[styles.tableCol, styles.smallCol]}>
								<Text style={styles.tableCell}>{row.totalSeats}</Text>
							</View>
							<View style={[styles.tableCol, styles.smallCol]}>
								<Text style={styles.tableCell}>
									{currencySymbols[currency]}
									{row.msrp.toFixed(2)}
								</Text>
							</View>
							<View
								style={[styles.tableCol, styles.smallCol, styles.tableCellRed]}
							>
								<Text style={styles.tableCell}>
									{currencySymbols[currency]}
									{row.currentVendorPrice.toFixed(2)}
								</Text>
							</View>
							<View
								style={[styles.tableCol, styles.smallCol, styles.tableCellRed]}
							>
								<Text style={styles.tableCell}>
									{currencySymbols[currency]}
									{(row.currentVendorPrice * row.totalSeats).toFixed(2)}
								</Text>
							</View>
							<View
								style={[
									styles.tableCol,
									styles.smallCol,
									styles.tableCellGreen,
								]}
							>
								<Text style={styles.tableCell}>
									{currencySymbols[currency]}
									{row.tttPrice.toFixed(2)}
								</Text>
							</View>
							<View
								style={[
									styles.tableCol,
									styles.smallCol,
									styles.tableCellGreen,
								]}
							>
								<Text style={styles.tableCell}>
									{currencySymbols[currency]}
									{(row.tttPrice * row.assignedSeats).toFixed(2)}
								</Text>
							</View>
							<View style={[styles.tableCol, styles.smallCol]}>
								<Text style={styles.tableCell}>
									{currencySymbols[currency]}
									{row.lineItemSavings.toFixed(2)}
								</Text>
							</View>
						</View>
					))}
				</View>
			</View>
			<View
				style={{
					flexDirection: "row",
					justifyContent: "space-between",
					alignItems: "center",
				}}
			>
				<Image src={logoPath2} style={styles.summaryImage} />
				<View style={styles.totalSection}>
					<Text>
						{currentVendor} / Month: {currencySymbols[currency]}
						{totalCurrentVendor.toFixed(2)}
					</Text>
					<Text>
						Trusted Tech Team / Month: {currencySymbols[currency]}
						{totalTtt.toFixed(2)}
					</Text>
					<Text>
						Savings / Month: {currencySymbols[currency]}
						{totalSavings.toFixed(2)}
					</Text>
					<Text>
						Savings / Year: {currencySymbols[currency]}
						{totalAnnualSavings.toFixed(2)}
					</Text>
				</View>
			</View>
			<View style={styles.footer} wrap={false}>
				<Text>
					Visit us online:{" "}
					<Link src="https://trustedtechteam.com" style={styles.link}>
						trustedtechteam.com
					</Link>
				</Text>
				<Text>
					Learn more about our Microsoft Solutions Partner designations:{" "}
					<Link src="https://trustedtechteam.com/partner" style={styles.link}>
						trustedtech.team/partner
					</Link>
				</Text>
				<Text>
					Infrastructure • Data & AI • Digital & App Innovation • Business
					Applications • Modern Work • Security
				</Text>
			</View>
		</Page>
	</Document>
);

const CReport = () => {
	const { user, accessToken } = useAuth();
	
	const [rows, setRows] = useState([
		{
			id: uuidv4(),
			product: null,
			assignedSeats: 0,
			totalSeats: 0,
			msrp: 0,
			currentVendorPrice: 0,
			tttPrice: 0,
			lineItemSavings: 0,
			effectiveStartDate: new Date(),
		},
	]);

	const [options, setOptions] = useState([]);
	const [currency, setCurrency] = useState("USD");
	const [currentVendor, setCurrentVendor] = useState("Current Vendor");
	const [tttDiscount, setTttDiscount] = useState(0);
	const [currentVendorDiscount, setCurrentVendorDiscount] = useState(0);

	const [clientName, setClientName] = useState("");
	const [clientCompany, setClientCompany] = useState("");
	const [clientEmail, setClientEmail] = useState("");
	const [clientPhone, setClientPhone] = useState("");

	const [selectedSender, setSelectedSender] = useState(null);
	const [senderEmail, setSenderEmail] = useState("");
	const [senderPhone, setSenderPhone] = useState("");

	const [selectedDate, setSelectedDate] = useState("");
	const [reference, setReference] = useState("");
	const [currentDate, setCurrentDate] = useState(new Date());
	const [referenceCode, setReferenceCode] = useState("");
	const [view, setView] = useState("client");
	const [activeSection, setActiveSection] = useState("info");
	//const [salesforceLeadId, setSalesforceLeadId] = useState("");

	//sales order
	const [salesOrderView, setSalesOrderView] = useState(false);
	const [term, setTerm] = useState("");
	const [offerValidThrough, setOfferValidThrough] = useState("");
	const [defaultServiceStartDate, setDefaultServiceStartDate] = useState(
		new Date()
	);
	const [defaultServiceEndDate, setDefaultServiceEndDate] = useState(
		new Date()
	);
	const [netTerms, setNetTerms] = useState("");
	const [billingFrequency, setBillingFrequency] = useState("");
	const [companyAddress, setCompanyAddress] = useState("");
	const [companyCity, setCompanyCity] = useState("");
	const [companyState, setCompanyState] = useState("");
	const [companyZip, setCompanyZip] = useState("");
	const [companyCountry, setCompanyCountry] = useState("");

	const [platinumSupport, setPlatinumSupport] = useState("NONE");

	//states to fetch JSON CRs
	const [jsonFiles, setJsonFiles] = useState([]);
	const [selectedFile, setSelectedFile] = useState("");

	useEffect(() => {
		const loadJsonFiles = async () => {
			const files = await fetchJsonFiles();
			setJsonFiles(files);
		};

		loadJsonFiles();
	}, []);

	const platinumSupportOptions = [
		{ value: "NONE", label: "NONE" },
		{ value: "Basic", label: "Basic" },
		{ value: "Standard", label: "Standard" },
		{ value: "Premium", label: "Premium" },
		{ value: "Elite", label: "Elite" },
	];

	const handleDownloadSalesOrderPDF = async () => {
		if (!selectedSender) {
			console.error("No sender selected");
			return;
		}
		const uniqueId = uuidv4();
		const date = new Date();
		const dateString = date.toISOString().split("T")[0];
		const formData = {
			termMonths: term,
			validThrough: new Date(offerValidThrough),
			servicesStartDate: new Date(defaultServiceStartDate),
			servicesEndDate: new Date(defaultServiceEndDate),
			netTerms,
			billingFrequency,
			salesPersonName: selectedSender.label,
			companyName: clientCompany,
			companyAddress,
			companyCity,
			companyState,
			companyZIP: companyZip,
			companyCountry,
		};

		const addonsWithPrices = rows.map((row) =>
			applyDiscounts(row, tttDiscount, currentVendorDiscount)
		);

		const addons = addonsWithPrices.map((row) => ({
			name: row.product ? row.product.label : "N/A",
			assignedSeats: row.assignedSeats,
			tttPrice: row.tttPrice, // Trusted Tech Team ($/Seat)
			tttTotal: row.tttTotal, // Trusted Tech Team (Total)
			currency: currency,
			effectiveStartDate:
				row.effectiveStartDate instanceof Date
					? row.effectiveStartDate.toISOString().split("T")[0]
					: "Invalid Date",
			effectiveEndDate:
				row.effectiveEndDate instanceof Date
					? row.effectiveEndDate.toISOString().split("T")[0]
					: "Invalid Date",
		}));

		const totalAddonValue = addons.reduce(
			(total, addon) => total + addon.tttTotal,
			0
		);

		const blob = await pdf(
			<SalesOrderPDFBasic
				formData={formData}
				addons={addons}
				platinumSupport={platinumSupport}
			/>
		).toBlob();

		const fileName = `${clientCompany}_${uniqueId}.pdf`;
		const file = new File([blob], fileName, { type: "application/pdf" });

		const url = URL.createObjectURL(file);
		const a = document.createElement("a");
		a.href = url;
		a.download = fileName;
		document.body.appendChild(a);
		a.click();
		document.body.removeChild(a);

		//upload pdf
		const sasToken =
			"sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupyx&se=3000-07-24T00:42:14Z&st=2024-07-23T16:42:14Z&spr=https,http&sig=ahO%2Bf%2BP7cJGC8xaHdQ7EbfbLBS%2FzjJx9NBMIa3CBibc%3D";
		const account = "dwdeprepaidtttdatalake";
		const containerName = "sh-sales-orders-nce";
		const blobServiceClient = new BlobServiceClient(
			`https://${account}.blob.core.windows.net?${sasToken}`
		);
		const containerClient = blobServiceClient.getContainerClient(containerName);
		await containerClient.createIfNotExists();
		const pdfBlobClient = containerClient.getBlockBlobClient(
			`data/01_raw/${dateString}/${fileName}`
		);

		await pdfBlobClient.uploadData(blob, {
			blobHTTPHeaders: { blobContentType: "application/pdf" },
		});

		console.log(`PDF uploaded to blob: ${fileName}`);

		//json summary
		const pricingSummary = addons.map((addon) => ({
			productName: addon.name,
			assignedSeats: addon.assignedSeats,
			tttPrice: addon.tttPrice,
			tttTotal: addon.tttTotal,
			currency: addon.currency,
			effectiveStartDate: addon.effectiveStartDate,
			// effectiveEndDate: addon.effectiveEndDate,
		}));

		let createdDate = new Date();

		const jsonSummary = {
			salesOrderId: uniqueId,
			formData,
			pricingSummary,
			totalAddonValue,
			createdDate: createdDate.toISOString().split("T")[0],
			createdBy: user?.userName,
			status: "Draft",
		};

		// Ignore salesOrderId when hashing because it's a uuid generated each function call
		const { salesOrderId, ...hashableJsonSummary } = jsonSummary;
		const jsonHashableString = JSON.stringify(hashableJsonSummary);
		const jsonHash = await generateSHA256(jsonHashableString);
		const hashSubstring = jsonHash.substring(0, 8);

		jsonSummary["hash"] = hashSubstring;
		jsonSummary["salesOrderType"] = "NCE";

		//create blob
		const jsonBlob = new Blob([JSON.stringify(jsonSummary)], {
			type: "application/json",
		});

		const jsonFileName = `${clientCompany}_${uniqueId}.json`;
		const jsonBlobClient = containerClient.getBlockBlobClient(
			`data/01_raw/${dateString}/${jsonFileName}`
		);

		await jsonBlobClient.uploadData(jsonBlob, {
			blobHTTPHeaders: { blobContentType: "application/json" },
		});

		console.log(`JSON uploaded to blob: ${jsonFileName}`);

		const salesOrderType = "NCE";
		await uploadSalesOrderToCosmos(
			addons,
			formData,
			user?.userName,
			serverAddress,
			accessToken,
			salesOrderType
		);
	};

	useEffect(() => {
		const generateReferenceCode = () => {
			if (currentDate && selectedSender && selectedSender.label) {
				const datePart = currentDate
					.toLocaleDateString("en-US", {
						month: "2-digit",
						day: "2-digit",
						year: "2-digit",
					})
					.replace(/\//g, "");
				const initials = selectedSender.label
					.split(" ")
					.map((name) => name.charAt(0))
					.join("");
				setReferenceCode(`${datePart}-${initials}`);
			}
		};

		generateReferenceCode();
	}, [currentDate, selectedSender]);

	useEffect(
		() => {
			if (selectedSender) {
				setSenderEmail(selectedSender.email);
				setSenderPhone(selectedSender.phone);
				generateReference(selectedDate, selectedSender);
			} else {
				setSenderEmail("");
				setSenderPhone("");
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[selectedSender]
	);

	const generateReference = (date, sender) => {
		if (!date || !sender) return;

		const [year, month, day] = date.split("-");
		const initials = sender.label
			.split(" ")
			.map((n) => n[0])
			.join("");
		const refCode = `${month}${day}${year.slice(-2)}-${initials}`;
		setReference(refCode);
	};

	useEffect(() => {
		const fetchProducts = async () => {
			try {
				console.log(`Fetching products for currency: ${currency}`);

				const response = await axios.get(
					`${serverAddress}/products/all?pk_filter=${currency}`,
					{
						headers: {
							"Content-Type": "application/json",
							"Authorization": `Bearer ${accessToken}`,
							"X-User-Name": `${user?.userName}`,
						},
					}
				);

				const matchList = response.data;
				
				// TEST COMMENT
				//filter products that contain "P1Y|monthly" in their pk_msrp_variants
				let products = matchList
				//NEW SCHEMA
				.filter((item) =>
					item.pk_msrp_variants?.some((variant) =>
						variant.pk_msrp.includes("P1Y|monthly")
					)
				)
				//NEW SCHEMA
					.map((item) => {
						const variant = item.pk_msrp_variants?.find((variant) =>
							variant.pk_msrp.includes("P1Y|monthly")
						);
						
						const erpPrice = variant?.erp_price || 0;

						return {
							value: item.sku_title,
							label: item.sku_title,
							msrp: erpPrice , 
						};
					});

				//sort products by label (SkuTitle)
				products = products.sort((a, b) => a.label.localeCompare(b.label));

				//update the options and rows state
				setOptions(products);
				setRows(
					rows.map((row) => {
						const msrp = row.product
							? products.find((product) => product.value === row.product.value)
									?.msrp || 0
							: 0;

						const currentVendorPrice =
							row.assignedSeats > 0 && row.totalSeats > 0 ? msrp : 0;
						const tttPrice =
							row.assignedSeats > 0 && row.totalSeats > 0 ? msrp : 0;

						return {
							...row,
							msrp: msrp,
							currentVendorPrice: currentVendorPrice,
							tttPrice: tttPrice,
						};
					})
				);
			} catch (error) {
				console.error("Error fetching products:", error);
			}
		};

		fetchProducts();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currency]);

	useEffect(() => {
		const table = document.getElementById("draggable-table");
		if (!table) {
			return;
		}
		let draggedRow = null;

		const handleDragStart = (e) => {
			if (e.target.closest("tbody tr")) {
				draggedRow = e.target;
				e.dataTransfer.effectAllowed = "move";
			}
		};

		const handleDragOver = (e) => {
			e.preventDefault();
			e.dataTransfer.dropEffect = "move";
			const target = e.target.closest("tbody tr");
			if (target && target !== draggedRow) {
				const rect = target.getBoundingClientRect();
				const next = (e.clientY - rect.top) / (rect.bottom - rect.top) > 0.5;
				target.parentNode.insertBefore(
					draggedRow,
					(next && target.nextSibling) || target
				);
			}
		};

		const handleDrop = (e) => {
			e.preventDefault();
			const updatedRows = Array.from(table.querySelectorAll("tbody tr"))
				.filter((tr) => tr.dataset.id)
				.map((tr) => rows.find((row) => row.id === tr.dataset.id));
			setRows(updatedRows);
		};

		table.addEventListener("dragstart", handleDragStart);
		table.addEventListener("dragover", handleDragOver);
		table.addEventListener("drop", handleDrop);

		return () => {
			table.removeEventListener("dragstart", handleDragStart);
			table.removeEventListener("dragover", handleDragOver);
			table.removeEventListener("drop", handleDrop);
		};
	}, [rows]);

	const onDragEnd = (result) => {
		if (!result.destination) {
			return;
		}

		const updatedRows = Array.from(rows);
		const [removed] = updatedRows.splice(result.source.index, 1);
		updatedRows.splice(result.destination.index, 0, removed);

		setRows(updatedRows);
	};

	const handleAddRow = () => {
		const newRow = {
			id: uuidv4(),
			product: null,
			assignedSeats: 0,
			totalSeats: 0,
			msrp: 0,
			currentVendorPrice: 0,
			tttPrice: 0,
			lineItemSavings: 0,
			effectiveStartDate: defaultServiceStartDate,
		};

		const updatedRow = {
			...newRow,
			tttPrice: newRow.msrp * (1 - tttDiscount / 100),
			currentVendorPrice: newRow.msrp * (1 - currentVendorDiscount / 100),
			lineItemSavings:
				Math.max(newRow.currentVendorPrice - newRow.tttPrice, 0) *
				newRow.totalSeats,
		};

		setRows([...rows, updatedRow]);
	};

	const handleDeleteRow = (id) => {
		setRows(rows.filter((row) => row.id !== id));
	};

	const [tempValues, setTempValues] = useState({});

	useEffect(() => {
		setRows((prevRows) =>
			prevRows.map((row) => ({
				...row,
				effectiveStartDate: new Date(defaultServiceStartDate),
			}))
		);
	}, [defaultServiceStartDate]);

	const handleInputChange = (e, id, field) => {
		const value = e.target.value;
		setTempValues((prevTempValues) => ({
			...prevTempValues,
			[`${id}-${field}`]: value,
		}));
	};
	const handleInputBlur = (id, field) => {
		let value = tempValues[`${id}-${field}`];

		if (field.includes("Date")) {
			value = new Date(value);
			if (isNaN(value.getTime())) {
				value = new Date();
			}
		} else {
			value =
				value !== undefined
					? parseFloat(value)
					: rows.find((row) => row.id === id)[field];
		}

		setRows((prevRows) =>
			prevRows.map((row) => {
				if (row.id === id) {
					const updatedRow = { ...row, [field]: value };

					const recalculatedRow = applyDiscounts(
						updatedRow,
						tttDiscount,
						currentVendorDiscount
					);

					return recalculatedRow;
				}
				return row;
			})
		);

		setTempValues((prevTempValues) => ({
			...prevTempValues,
			[`${id}-${field}`]: undefined,
		}));
	};

	const handlePlatinumSupportChange = (value) => {
		setPlatinumSupport(value);

		if (value === "Yes") {
			const platinumSupportOptions = [
				{
					value: "Platinum Support (Basic)",
					label: "Platinum Support (Basic)",
				},
				{
					value: "Platinum Support (Standard)",
					label: "Platinum Support (Standard)",
				},
				{
					value: "Platinum Support (Premium)",
					label: "Platinum Support (Premium)",
				},
				{
					value: "Platinum Support (Elite)",
					label: "Platinum Support (Elite)",
				},
			];

			const newRow = {
				id: uuidv4(),
				product: platinumSupportOptions[0], //default
				assignedSeats: 0,
				totalSeats: 0,
				msrp: 250,
				currentVendorPrice: 0, //stay 0
				tttPrice: 250,
				lineItemSavings: 0,
				effectiveEndDate: defaultServiceEndDate.toISOString().split("T")[0],
				isEditing: true,
			};

			setRows([
				...rows,
				applyDiscounts(newRow, tttDiscount, currentVendorDiscount),
			]);
		}
	};

	const handleSelectChange = (selectedOption, id) => {
		const platinumSupportOptions = [
			{ value: "Platinum Support (Basic)", label: "Platinum Support (Basic)" },
			{
				value: "Platinum Support (Standard)",
				label: "Platinum Support (Standard)",
			},
			{
				value: "Platinum Support (Premium)",
				label: "Platinum Support (Premium)",
			},
			{ value: "Platinum Support (Elite)", label: "Platinum Support (Elite)" },
		];

		const msrp = selectedOption ? selectedOption.msrp : 0;
		setRows(
			rows.map((row) => {
				if (row.id === id) {
					const isPlatinumSupport = platinumSupportOptions.some(
						(option) => option.value === selectedOption.value
					);
					const updatedRow = applyDiscounts(
						{
							...row,
							product: selectedOption,
							msrp: isPlatinumSupport ? 250 : msrp,
							tttPrice: isPlatinumSupport ? 250 : row.tttPrice,
							currentVendorPrice: isPlatinumSupport
								? 0
								: row.currentVendorPrice,
						},
						tttDiscount,
						currentVendorDiscount
					);
					return updatedRow;
				}
				return row;
			})
		);
	};

	const handleEditClick = (id) => {
		setRows(
			rows.map((row) => (row.id === id ? { ...row, isEditing: true } : row))
		);
	};

	const handleSaveClick = (id) => {
		setRows(
			rows.map((row) =>
				row.id === id
					? {
							...row,
							isEditing: false,
							product: row.product?.value
								? row.product
								: { label: "Select a product", value: "" },
						}
					: row
			)
		);
	};

	const handleTttDiscountChange = (value) => {
		setTttDiscount(value);
		setRows(
			rows.map((row) => {
				return applyDiscounts(row, value, currentVendorDiscount);
			})
		);
	};

	const handleCurrentVendorDiscountChange = (value) => {
		setCurrentVendorDiscount(value);
		setRows(
			rows.map((row) => {
				return applyDiscounts(row, tttDiscount, value);
			})
		);
	};

	const handleDownloadPDF = async () => {
		const updatedRows = rows.map((row) =>
			applyDiscounts(row, tttDiscount, currentVendorDiscount)
		);
		const uniqueId = uuidv4();
		const blob = await pdf(
			<ConsumptionReportPDF
				rows={updatedRows}
				clientName={clientName}
				clientCompany={clientCompany}
				clientEmail={clientEmail}
				clientPhone={clientPhone}
				selectedSender={selectedSender}
				senderEmail={senderEmail}
				senderPhone={senderPhone}
				totalCurrentVendor={totalCurrentVendor}
				totalTtt={totalTtt}
				totalSavings={totalSavings}
				totalAnnualSavings={totalAnnualSavings}
				currency={currency}
				currentDate={currentDate}
				referenceCode={referenceCode}
				currentVendor={currentVendor}
			/>
		).toBlob();

		const fileName = `${clientCompany}_${uniqueId}_creport.pdf`;
		const file = new File([blob], fileName, { type: "application/pdf" });

		const url = URL.createObjectURL(file);
		const a = document.createElement("a");
		a.href = url;
		a.download = fileName;
		document.body.appendChild(a);
		a.click();
		document.body.removeChild(a);

		//blob upload
		const sasToken =
			"sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupyx&se=3000-07-24T00:42:14Z&st=2024-07-23T16:42:14Z&spr=https,http&sig=ahO%2Bf%2BP7cJGC8xaHdQ7EbfbLBS%2FzjJx9NBMIa3CBibc%3D";
		const account = "dwdeprepaidtttdatalake";
		const containerName = "sh-consumption-reports";
		const blobFileName = `${clientCompany}_${uniqueId}_creport.pdf`;

		const blobServiceClient = new BlobServiceClient(
			`https://${account}.blob.core.windows.net?${sasToken}`
		);
		const containerClient = blobServiceClient.getContainerClient(containerName);
		await containerClient.createIfNotExists();
		const pdfBlobClient = containerClient.getBlockBlobClient(
			`data/01_raw/${blobFileName}`
		);

		await pdfBlobClient.uploadData(blob, {
			blobHTTPHeaders: { blobContentType: "application/pdf" },
		});

		console.log(`pdf uploaded to blob: ${fileName}`);

		//json upload
		const jsonSummary = {
			consumptionReportID: uniqueId,
			clientCompany: clientCompany,
			selectedSender: selectedSender,
			referenceCode: referenceCode,
			rows: updatedRows,
			currency: currency,
			createdBy: user?.userName,
			status: "Draft",
		};

		// Ignore consumptionReportID when hashing because it's a uuid generated each function call
		const { consumptionReportID, ...hashableJsonSummary } = jsonSummary;
		const jsonHashableString = JSON.stringify(hashableJsonSummary);
		const jsonHash = await generateSHA256(jsonHashableString);
		const hashSubstring = jsonHash.substring(0, 8);

		jsonSummary["hash"] = hashSubstring;
		jsonSummary["salesOrderType"] = "NCE";

		const jsonBlob = new Blob([JSON.stringify(jsonSummary)], {
			type: "application/json",
		});
		const jsonFileName = `${clientCompany}_${uniqueId}_creport.json`;
		const jsonBlobClient = containerClient.getBlockBlobClient(
			`data/01_raw/${jsonFileName}`
		);

		await jsonBlobClient.uploadData(jsonBlob, {
			blobHTTPHeaders: { blobContentType: "application/json" },
		});

		console.log(`JSON uploaded to blob: ${jsonFileName}`);

		await uploadConsumptionReportToCosmos(
			updatedRows,
			clientCompany,
			selectedSender,
			referenceCode,
			currency,
			user?.userName,
			serverAddress,
			accessToken
		);
	};

	const handlePreviewPDF = async () => {
		const updatedRows = rows.map((row) =>
			applyDiscounts(row, tttDiscount, currentVendorDiscount)
		);

		const blob = await pdf(
			<ConsumptionReportPDF
				rows={updatedRows}
				clientName={clientName}
				clientCompany={clientCompany}
				clientEmail={clientEmail}
				clientPhone={clientPhone}
				selectedSender={selectedSender}
				senderEmail={senderEmail}
				senderPhone={senderPhone}
				totalCurrentVendor={totalCurrentVendor}
				totalTtt={totalTtt}
				totalSavings={totalSavings}
				totalAnnualSavings={totalAnnualSavings}
				currency={currency}
				currentDate={currentDate}
				referenceCode={referenceCode}
				currentVendor={currentVendor}
			/>
		).toBlob();

		const url = URL.createObjectURL(blob);
		const pdfUrlWithParams = `${url}#toolbar=0`;
		window.open(pdfUrlWithParams, "_blank");
		// window.open(url, '_blank');

		await uploadConsumptionReportToCosmos(
			updatedRows,
			clientCompany,
			selectedSender,
			referenceCode,
			currency,
			user?.userName,
			serverAddress,
			accessToken
		);
	};

	const handlePreviewSalesOrder = async () => {
		const formData = {
			termMonths: term,
			validThrough: new Date(offerValidThrough),
			servicesStartDate: new Date(defaultServiceStartDate),
			servicesEndDate: new Date(defaultServiceEndDate),
			netTerms,
			billingFrequency,
			salesPersonName: selectedSender?.label,
			companyName: clientCompany,
			companyAddress,
			companyCity,
			companyState,
			companyZIP: companyZip,
			companyCountry,
		};

		const addonsWithPrices = rows.map((row) =>
			applyDiscounts(row, tttDiscount, currentVendorDiscount)
		);

		const addons = addonsWithPrices.map((row) => ({
			name: row.product ? row.product.label : "N/A",
			assignedSeats: row.assignedSeats,
			tttPrice: row.tttPrice, //Trusted Tech Team ($/Seat)
			tttTotal: row.tttTotal, //Trusted Tech Team (Total)
			currency: currency,
			effectiveStartDate:
				row.effectiveStartDate instanceof Date
					? row.effectiveStartDate.toISOString().split("T")[0]
					: "Invalid Date",
			effectiveEndDate:
				row.effectiveEndDate instanceof Date
					? row.effectiveEndDate.toISOString().split("T")[0]
					: "Invalid Date",
		}));

		const blob = await pdf(
			<SalesOrderPDFBasic
				formData={formData}
				addons={addons}
				platinumSupport={platinumSupport}
			/>
		).toBlob();

		const url = URL.createObjectURL(blob);
		const pdfUrlWithParams = `${url}#toolbar=0`;
		window.open(pdfUrlWithParams, "_blank");

		const salesOrderType = "NCE";
		await uploadSalesOrderToCosmos(
			addons,
			formData,
			user?.userName,
			serverAddress,
			accessToken,
			salesOrderType
		);
	};

	//fetch CR Jsons files
	const fetchJsonFiles = async () => {
		const sasToken =
			"sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupyx&se=3000-07-24T00:42:14Z&st=2024-07-23T16:42:14Z&spr=https,http&sig=ahO%2Bf%2BP7cJGC8xaHdQ7EbfbLBS%2FzjJx9NBMIa3CBibc%3D";
		const account = "dwdeprepaidtttdatalake";
		const containerName = "sh-consumption-reports";

		const blobServiceClient = new BlobServiceClient(
			`https://${account}.blob.core.windows.net?${sasToken}`
		);
		const containerClient = blobServiceClient.getContainerClient(containerName);

		const jsonFiles = [];

		for await (const blob of containerClient.listBlobsFlat()) {
			if (blob.name.endsWith(".json")) {
				jsonFiles.push({
					name: blob.name,
					lastModified: blob.properties.lastModified,
				});
			}
		}
		//sort by newest
		jsonFiles.sort(
			(a, b) => new Date(b.lastModified) - new Date(a.lastModified)
		);

		return jsonFiles;
	};

	//fetch CR Jsons inner contents
	const fetchJsonContent = async (fileName) => {
		const sasToken =
			"sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupyx&se=3000-07-24T00:42:14Z&st=2024-07-23T16:42:14Z&spr=https,http&sig=ahO%2Bf%2BP7cJGC8xaHdQ7EbfbLBS%2FzjJx9NBMIa3CBibc%3D";
		const account = "dwdeprepaidtttdatalake";
		const containerName = "sh-consumption-reports";

		const blobServiceClient = new BlobServiceClient(
			`https://${account}.blob.core.windows.net?${sasToken}`
		);
		const containerClient = blobServiceClient.getContainerClient(containerName);
		const blobClient = containerClient.getBlobClient(fileName);

		try {
			const downloadBlockBlobResponse = await blobClient.download(0);
			const downloaded = await downloadBlockBlobResponse.blobBody;
			if (!downloaded) {
				throw new Error(
					"Failed to retrieve the blob content, blobBody is undefined"
				);
			}

			const textContent = await downloaded.text();
			return JSON.parse(textContent);
		} catch (error) {
			console.error("Error fetching JSON content:", error);
			throw error;
		}
	};

	const handleFileSelect = async (event) => {
		const fileName = event.target.value;

		if (!fileName) return;

		try {
			const jsonContent = await fetchJsonContent(fileName);

			setCurrency(jsonContent.currency);
			setClientCompany(jsonContent.clientCompany);
			setSelectedSender(jsonContent.selectedSender);
			setReferenceCode(jsonContent.referenceCode);

			const mappedRows = jsonContent.rows.map((jsonRow) => ({
				id: jsonRow.id,
				product: jsonRow.product,
				assignedSeats: jsonRow.assignedSeats,
				// totalSeats: jsonRow.totalSeats,
				// msrp: jsonRow.msrp,
				// currentVendorPrice: jsonRow.currentVendorPrice,
				tttPrice: jsonRow.tttPrice,
				// lineItemSavings: jsonRow.lineItemSavings,
				// effectiveStartDate: new Date(jsonRow.effectiveStartDate),
				isEditing: jsonRow.isEditing || false,
				tttTotal: jsonRow.tttTotal,
				// currentVendorTotal: jsonRow.currentVendorTotal,
			}));

			setRows(mappedRows);

			console.log("Selected JSON content:", jsonContent);
		} catch (error) {
			console.error("Error handling file select:", error);
		}
	};

	const totalCurrentVendor = rows.reduce(
		(sum, row) =>
			sum + (isNaN(row.currentVendorTotal) ? 0 : row.currentVendorTotal),
		0
	);
	const totalTtt = rows.reduce(
		(sum, row) => sum + (isNaN(row.tttTotal) ? 0 : row.tttTotal),
		0
	);
	const totalSavings = totalCurrentVendor - totalTtt;
	const totalAnnualSavings = totalSavings * 12;

	return (
		<div className="min-h-screen text-black dark:text-white">
			<div className="mb-6 flex justify-center">
				<h2
					onClick={() => setView("client")}
					className={`cursor-pointer text-lg font-bold ${view === "client" ? "border-b-2 border-blue-500 text-blue-500" : ""} mr-4`}
				>
					<FaUser className="mr-1 inline-block" /> Info Section
				</h2>
				<h2
					onClick={() => setView("product")}
					className={`cursor-pointer text-lg font-bold ${view === "product" ? "border-b-2 border-blue-500 text-blue-500" : ""}`}
				>
					<FaTable className="mr-1 inline-block" /> Product Table
				</h2>
				{salesOrderView && (
					<h2
						onClick={() => setView("salesOrder")}
						className={`cursor-pointer text-lg font-bold ${view === "salesOrder" ? "border-b-2 border-blue-500 text-blue-500" : ""} ml-4`}
					>
						<FaFileInvoice className="mr-1 inline-block" /> Sales Order (NCE)
					</h2>
				)}
			</div>
			<div className="fixed-container">
				{view === "client" && (
					<div className="client-info">
						<div className="mb-6 flex">
							<div className="w-1/2 pr-8">
								<div className="mb-4 flex flex-col">
									<label className="mb-1 text-black dark:text-white">
										Date:
									</label>
									<DatePicker
										selected={currentDate}
										onChange={(date) => setCurrentDate(date)}
										dateFormat="MM/dd/yy"
										className="w-full rounded bg-gray-900 p-2 text-white"
									/>
								</div>
							</div>
							<div className="mx-12 w-px bg-gray-700"></div>
							<div className="w-1/2 pl-8">
								<div className="mb-4 flex flex-col">
									<label className="mb-1 text-black dark:text-white">
										Reference:
									</label>
									<div style={{ color: "#60C659" }}>{referenceCode}</div>
								</div>
							</div>
						</div>
						<div className="mb-6 flex">
							<div className="w-1/2 pr-8">
								<h3 className="mb-4 text-lg font-bold">Client Section</h3>
								<div className="mb-4 flex flex-col">
									<label className="mb-1 text-black dark:text-white">
										Name:
									</label>
									<input
										type="text"
										value={clientName}
										onChange={(e) => setClientName(e.target.value)}
										className="input-underline text-black dark:text-white"
									/>
								</div>
								<div className="mb-4 flex flex-col">
									<label className="mb-1 text-black dark:text-white">
										Company:
									</label>
									<input
										type="text"
										value={clientCompany}
										onChange={(e) => setClientCompany(e.target.value)}
										className="input-underline text-black dark:text-white"
									/>
								</div>
								<div className="mb-4 flex flex-col">
									<label className="mb-1 text-black dark:text-white">
										Email:
									</label>
									<input
										type="email"
										value={clientEmail}
										onChange={(e) => setClientEmail(e.target.value)}
										className="input-underline text-black dark:text-white"
									/>
								</div>
								<div className="mb-4 flex flex-col">
									<label className="mb-1 text-black dark:text-white">
										Phone:
									</label>
									<input
										type="tel"
										value={clientPhone}
										onChange={(e) => setClientPhone(e.target.value)}
										className="input-underline text-black dark:text-white"
									/>
								</div>
							</div>
							<div className="mx-12 w-px bg-gray-700"></div>
							<div className="w-1/2 pl-8">
								<h3 className="mb-4 text-lg font-bold">Sender Section</h3>

								{/* <div className="mb-4 flex flex-col">
									<label className="mb-1 text-black dark:text-white">
										Select Sender:
									</label>
									<Select
										options={senderOptions}
										value={selectedSender}
										onChange={setSelectedSender}
										className="w-full"
									/>
								</div> */}

								<div className="mb-4 flex flex-col">
									<label className="mb-1 text-black dark:text-white">
										Select Sender:
									</label>
									<select
										className="w-full rounded-xl border border-gray-300 bg-white p-2 text-gray-700 dark:border-gray-600 dark:bg-slate-700 dark:text-gray-300"
										value={selectedSender?.value}
										onChange={(e) =>
											setSelectedSender(
												senderOptions.find(
													(option) => option.value === e.target.value
												)
											)
										}
									>
										{senderOptions.map((option, index) => (
											<option key={index} value={option.value}>
												{option.value}
											</option>
										))}
									</select>
								</div>

								<div className="mb-4 flex flex-col">
									<label className="mb-1 text-black dark:text-white">
										Email:
									</label>
									<input
										type="email"
										value={senderEmail}
										readOnly
										className="input-underline text-black dark:text-white"
									/>
								</div>
								<div className="mb-4 flex flex-col">
									<label className="mb-1 text-black dark:text-white">
										Phone:
									</label>
									<input
										type="tel"
										value={senderPhone}
										readOnly
										className="input-underline text-black dark:text-white"
									/>
								</div>
							</div>
						</div>
					</div>
				)}
				{view === "product" && (
					// FIXME: PRODUCT TABLE - componentize
					<div className="product-table text-black dark:text-white">
						<div className="mb-6">
							<h2 className="mb-4 text-xl font-bold">Product Table</h2>
							<div className="mt-4 flex w-full flex-col space-y-4">
								{/* Combined Percentage sliders and Dropdowns container */}
								<div className="border border-white rounded-lg overflow-hidden p-4 bg-gray-200 dark:bg-gray-700 shadow-lg flex items-center justify-between w-full space-x-4 mx-auto">
									<div className="flex flex-col items-center w-1/5">
										<label className="mb-1 text-black dark:text-white">
											TTT Discount:
										</label>
										<Slider
											min={0}
											max={100}
											value={tttDiscount}
											onChange={handleTttDiscountChange}
											className="custom-slider w-3/4"
										/>
										<span className="ml-2 text-black dark:text-white">
											{tttDiscount}%
										</span>
									</div>
									<div className="flex flex-col items-center w-1/5">
										<label className="mb-1 text-black dark:text-white">
											Vendor Discount:
										</label>
										<Slider
											min={0}
											max={100}
											value={currentVendorDiscount}
											onChange={handleCurrentVendorDiscountChange}
											className="custom-slider w-3/4"
										/>
										<span className="ml-2 text-black dark:text-white">
											{currentVendorDiscount}%
										</span>
									</div>
									<div className="flex flex-col items-center w-1/5">
										<label className="mb-1 text-black dark:text-white">
											Select Current Vendor:
										</label>
										<select
											className="w-3/4 rounded-xl border border-gray-300 bg-white p-2 text-gray-700 dark:border-gray-600 dark:bg-slate-700 dark:text-gray-300"
											value={currentVendor}
											onChange={(e) => setCurrentVendor(e.target.value)}
										>
											{currentVendorOptions.map((option, index) => (
												<option key={index} value={option.value}>
													{option.value}
												</option>
											))}
										</select>
									</div>
									<div className="flex flex-col items-center w-1/5">
										<label className="mb-1 text-black dark:text-white">
											Select Currency:
										</label>
										<select
											className="w-3/4 rounded-xl border border-gray-300 bg-white p-2 text-gray-700 dark:border-gray-600 dark:bg-slate-700 dark:text-gray-300"
											value={currency}
											onChange={(e) => setCurrency(e.target.value)}
										>
											{currencyOptions.map((option, index) => (
												<option key={index} value={option.value}>
													{option.label} {option.value}
												</option>
											))}
										</select>
									</div>
									<div className="flex flex-col items-center w-1/5">
										<label className="mb-1 text-black dark:text-white">
											Platinum Support:
										</label>
										<select
											className="w-3/4 rounded-xl border border-gray-300 bg-white p-2 text-gray-700 dark:border-gray-600 dark:bg-slate-700 dark:text-gray-300"
											value={platinumSupport}
											onChange={(e) =>
												handlePlatinumSupportChange(e.target.value)
											}
										>
											{platSupportOptions.map((option, index) => (
												<option key={index} value={option.value}>
													{option.value}
												</option>
											))}
										</select>
									</div>
								</div>

								{/* Table and div container */}
								<div className="flex w-full justify-between space-x-2">
									<div className="flex w-full justify-center">
										<DragDropContext onDragEnd={onDragEnd}>
											<Droppable droppableId="droppable-table">
												{(provided) => (
													<div
														ref={provided.innerRef}
														{...provided.droppableProps}
													>
														<table
															id="draggable-table"
															className="min-w-full table-outline border-collapse text-sm rounded shadow-lg"
														>
															{/* Table Content */}
															<thead className="bg-gray-200 dark:bg-gray-700">
																<tr>
																	<th className="header-product p-2 rounded-tl-lg text-black dark:text-white">
																		Product
																	</th>
																	<th className="header-cell w-24 p-2 text-black dark:text-white">
																		Assigned Seats
																	</th>
																	<th className="header-cell w-24 p-2 text-black dark:text-white">
																		Total Seats
																	</th>
																	<th className="header-cell w-24 p-2 text-black dark:text-white">
																		MSRP ({currencySymbols[currency]}/Seat)
																	</th>
																	<th className="header-cell w-24 p-2 text-black dark:text-white">
																		{currentVendor} ({currencySymbols[currency]}
																		/Seat)
																	</th>
																	<th className="header-cell w-24 p-2 text-black dark:text-white">
																		{currentVendor} (Total)
																	</th>
																	<th className="header-cell w-24 p-2 text-black dark:text-white">
																		Trusted Tech Team (
																		{currencySymbols[currency]}/Seat)
																	</th>
																	<th className="header-cell w-24 p-2 text-black dark:text-white">
																		Trusted Tech Team (Total)
																	</th>
																	<th className="header-cell w-24 p-2 text-black dark:text-white">
																		Line Item Savings
																	</th>
																	<th className="header-cell p-2 rounded-tr-lg text-black dark:text-white"></th>
																</tr>
															</thead>
															<tbody className="bg-white dark:bg-gray-700">
																{rows.map((row, index) => (
																	<Draggable
																		key={row.id}
																		draggableId={row.id}
																		index={index}
																	>
																		{(provided) => (
																			<tr
																				ref={provided.innerRef}
																				{...provided.draggableProps}
																				{...provided.dragHandleProps}
																				className="table-row cursor-move hover:bg-gray-100 dark:hover:bg-gray-800 border-b border-gray-300"
																			>
																				<td
																					className={`whitespace-normal break-words p-2 text-black dark:text-white ${index === rows.length - 1 ? "rounded-bl-lg" : ""}`}
																					style={{ width: "300px" }}
																				>
																					<div className="flex justify-between">
																						{row.isEditing ? (
																							<>
																								<div className="flex items-center w-full">
																									<span className="mr-2">
																										::
																									</span>
																									<Select
																										value={row.product}
																										onChange={(option) =>
																											handleSelectChange(
																												option,
																												row.id
																											)
																										}
																										options={
																											row.product &&
																											row.product.label.startsWith(
																												"Platinum Support"
																											)
																												? [
																														{
																															value:
																																"Platinum Support (Basic)",
																															label:
																																"Platinum Support (Basic)",
																														},
																														{
																															value:
																																"Platinum Support (Standard)",
																															label:
																																"Platinum Support (Standard)",
																														},
																														{
																															value:
																																"Platinum Support (Premium)",
																															label:
																																"Platinum Support (Premium)",
																														},
																														{
																															value:
																																"Platinum Support (Elite)",
																															label:
																																"Platinum Support (Elite)",
																														},
																													]
																												: options
																										}
																										menuPortalTarget={
																											document.body
																										}
																										styles={{
																											menuPortal: (base) => ({
																												...base,
																												zIndex: 9999,
																											}),
																											control: (base) => ({
																												...base,
																												minHeight: "unset",
																											}),
																										}}
																										className="w-full"
																									/>
																								</div>
																								<button
																									onClick={() =>
																										handleSaveClick(row.id)
																									}
																									className="ml-2 text-blue-500"
																								>
																									Save
																								</button>
																							</>
																						) : (
																							<div className="flex w-full items-center justify-between">
																								<span className="w-full">
																									{row.product
																										? row.product.label
																										: "Select a product"}
																								</span>
																								<button
																									onClick={() =>
																										handleEditClick(row.id)
																									}
																									className="ml-2 text-blue-500"
																								>
																									Edit
																								</button>
																							</div>
																						)}
																					</div>
																				</td>
																				<td className="w-24 p-2">
																					<input
																						type="number"
																						value={
																							tempValues[
																								`${row.id}-assignedSeats`
																							] !== undefined
																								? tempValues[
																										`${row.id}-assignedSeats`
																									]
																								: row.assignedSeats
																						}
																						onChange={(e) =>
																							handleInputChange(
																								e,
																								row.id,
																								"assignedSeats"
																							)
																						}
																						onKeyDown={(e) => {
																							if (
																								e.key === "-" ||
																								e.key === "e"
																							) {
																								e.preventDefault();
																							}
																						}}
																						min="0"
																						onBlur={() =>
																							handleInputBlur(
																								row.id,
																								"assignedSeats"
																							)
																						}
																						className="input-underline w-full text-black dark:text-white"
																					/>
																				</td>
																				<td className="w-24 p-2">
																					<input
																						type="number"
																						value={
																							tempValues[
																								`${row.id}-totalSeats`
																							] !== undefined
																								? tempValues[
																										`${row.id}-totalSeats`
																									]
																								: row.totalSeats
																						}
																						onChange={(e) =>
																							handleInputChange(
																								e,
																								row.id,
																								"totalSeats"
																							)
																						}
																						onKeyDown={(e) => {
																							if (
																								e.key === "-" ||
																								e.key === "e"
																							) {
																								e.preventDefault();
																							}
																						}}
																						min="0"
																						onBlur={() =>
																							handleInputBlur(
																								row.id,
																								"totalSeats"
																							)
																						}
																						className="input-underline w-full text-black dark:text-white"
																					/>
																				</td>
																				<td className="w-24 p-2">
																					{/* edit rights to msrp including plat support*/}
																					<input
																						type="number"
																						value={
																							tempValues[`${row.id}-msrp`] !==
																							undefined
																								? tempValues[`${row.id}-msrp`]
																								: row.msrp
																						}
																						onChange={(e) =>
																							handleInputChange(
																								e,
																								row.id,
																								"msrp"
																							)
																						}
																						onKeyDown={(e) => {
																							if (
																								e.key === "-" ||
																								e.key === "e"
																							) {
																								e.preventDefault();
																							}
																						}}
																						min="0"
																						onBlur={() =>
																							handleInputBlur(row.id, "msrp")
																						}
																						className="input-underline w-full text-black dark:text-white"
																					/>
																				</td>
																				<td className="w-24 p-2">
																					{currencySymbols[currency]}
																					{(
																						Math.round(
																							(isNaN(row.currentVendorPrice)
																								? 0
																								: row.currentVendorPrice) * 100
																						) / 100
																					).toFixed(2)}
																				</td>
																				<td className="w-24 p-2">
																					{currencySymbols[currency]}
																					{(
																						Math.round(
																							(isNaN(row.currentVendorTotal)
																								? 0
																								: row.currentVendorTotal) * 100
																						) / 100
																					).toFixed(2)}
																				</td>
																				<td className="w-24 p-2">
																					{currencySymbols[currency]}
																					{(
																						Math.round(
																							(isNaN(row.tttPrice)
																								? 0
																								: row.tttPrice) * 100
																						) / 100
																					).toFixed(2)}
																				</td>
																				<td className="w-24 p-2">
																					{currencySymbols[currency]}
																					{(
																						Math.round(
																							(isNaN(row.tttTotal)
																								? 0
																								: row.tttTotal) * 100
																						) / 100
																					).toFixed(2)}
																				</td>
																				<td className="w-24 p-2">
																					{currencySymbols[currency]}
																					{(
																						Math.round(
																							(isNaN(row.lineItemSavings)
																								? 0
																								: row.lineItemSavings) * 100
																						) / 100
																					).toFixed(2)}
																				</td>
																				<td className="p-2">
																					<button
																						onClick={() =>
																							handleDeleteRow(row.id)
																						}
																						className="text-red-500"
																					>
																						<ImBin className="text-xl" />
																					</button>
																				</td>
																			</tr>
																		)}
																	</Draggable>
																))}
																{provided.placeholder}
															</tbody>
														</table>
													</div>
												)}
											</Droppable>
										</DragDropContext>
									</div>
									{/* Control Center div */}
									<div className="flex flex-col items-center space-y-2">
										<div className="p-4 bg-gray-200 dark:bg-gray-700 rounded-lg shadow-md">
											<div className="flex flex-col space-y-2">
												<div className="relative group flex flex-col items-center">
													<button
														onClick={handleAddRow}
														className="control-button text-white transform transition-transform duration-300 ease-in-out"
													>
														<ImPlus className="text-xl rotate-icon text-black dark:text-white" />
													</button>
													<div className="tooltip">Add Row</div>
												</div>
												<div className="relative group flex flex-col items-center">
													<button
														className="control-button text-white transform transition-transform duration-300 ease-in-out"
														onClick={handlePreviewPDF}
													>
														<ImSearch className="text-xl rotate-icon text-black dark:text-white" />
													</button>
													<div className="tooltip">Preview</div>
												</div>
												<div className="relative group flex flex-col items-center">
													<button
														className="control-button text-white transform transition-transform duration-300 ease-in-out"
														onClick={handleDownloadPDF}
													>
														<ImDownload className="text-xl rotate-icon text-black dark:text-white" />
													</button>
													<div className="tooltip">Download</div>
												</div>
												<div className="relative group flex flex-col items-center">
													<button
														className="control-button text-white transform transition-transform duration-300 ease-in-out"
														onClick={() => setSalesOrderView(true)}
													>
														<ImFileText className="text-xl rotate-icon text-black dark:text-white" />
													</button>
													<div className="tooltip">Sales Order</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								{/* Pricing summary div */}
								<div className="border border-white rounded-lg overflow-hidden  p-4 bg-gray-200 dark:bg-gray-700 shadow-md flex items-center justify-center w-full mt-4">
									<div className="text-sm w-full flex justify-between">
										<div>
											<div className="mb-2 text-lg font-bold">
												<span className="font-semibold">
													{currentVendor} / Month:{" "}
												</span>
												<span>
													{currencySymbols[currency]}
													{Math.round(totalCurrentVendor * 100) / 100}
												</span>
											</div>
											<div className="mb-2 text-lg font-bold">
												<span className="font-semibold">
													Trusted Tech Team / Month:{" "}
												</span>
												<span>
													{currencySymbols[currency]}
													{Math.round(totalTtt * 100) / 100}
												</span>
											</div>
										</div>
										<div>
											<div className="mb-2 text-lg font-bold">
												<span className="font-semibold">Savings / Month: </span>
												<span>
													{currencySymbols[currency]}
													{formatMoney(totalSavings)}
												</span>
											</div>
											<div className="mb-2 text-lg font-bold">
												<span className="font-semibold">Savings / Year: </span>
												<span>
													{currencySymbols[currency]}
													{formatMoney(totalAnnualSavings)}
												</span>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				)}

				{view === "salesOrder" && (
					// FIXME: SALES ORDER - componentize
					<div className="sales-order-nce">
						<div className="mb-6 flex">
							<div className="w-1/2 pr-8">
								<h3 className="mb-4 text-lg font-bold">Sales Order (NCE)</h3>
								<div className="mb-4 flex flex-col">
									<div className="mb-4 flex flex-col">
										<label className="mb-1 text-black dark:text-white">
											Company:
										</label>
										<input
											type="text"
											value={clientCompany}
											readOnly
											className="input-underline text-black dark:text-white"
										/>
									</div>
									<label className="mb-1 text-black dark:text-white">
										Sender Name:
									</label>
									<input
										type="text"
										value={selectedSender?.label || ""}
										readOnly
										className="input-underline text-black dark:text-white"
									/>
								</div>
								<div className="mb-4 flex flex-col">
									<label className="mb-1 text-black dark:text-white">
										Quote Number:
									</label>
									<input
										type="text"
										value={referenceCode}
										readOnly
										className="input-underline text-black dark:text-white"
									/>
								</div>
							</div>
							<div className="w-1/2 pl-8">
								<div className="mb-4 flex flex-col">
									<label className="mb-1 text-black dark:text-white">
										Term (Months):
									</label>
									<input
										type="number"
										value={term}
										onChange={(e) => setTerm(e.target.value)}
										onKeyDown={(e) => {
											if (e.key === "-" || e.key === "e") {
												e.preventDefault();
											}
										}}
										min="0"
										className="input-underline text-black dark:text-white"
									/>
								</div>
								<div className="mb-4 flex flex-col">
									<label className="mb-1 text-black dark:text-white">
										Offer Valid Through:
									</label>
									<DatePicker
										selected={offerValidThrough}
										onChange={(date) => setOfferValidThrough(date)}
										dateFormat="MM/dd/yy"
										className="w-full rounded bg-gray-900 p-2 text-white"
									/>
								</div>
								<div className="mb-4 flex flex-col">
									<label className="mb-1 text-black dark:text-white">
										Default Services Start Date:
									</label>
									<DatePicker
										selected={defaultServiceStartDate}
										onChange={(date) => setDefaultServiceStartDate(date)}
										dateFormat="MM/dd/yy"
										className="w-full rounded bg-gray-900 p-2 text-white"
									/>
								</div>
								<div className="mb-4 flex flex-col">
									<label className="mb-1 text-black dark:text-white">
										Default Services End Date:
									</label>
									<DatePicker
										selected={defaultServiceEndDate}
										onChange={(date) => setDefaultServiceEndDate(date)}
										dateFormat="MM/dd/yy"
										className="w-full rounded bg-gray-900 p-2 text-white"
									/>
								</div>
								<div className="mb-4 flex flex-col">
									<label className="mb-1 text-black dark:text-white">
										Net Terms:
									</label>
									<select
										className="block w-full rounded-xl border border-gray-300 bg-white p-2.5 text-gray-700 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-blue-500 dark:border-gray-600 dark:bg-slate-700 dark:text-gray-300"
										onChange={(e) => setNetTerms(e.target.value)}
										value={netTerms}
									>
										{netTermsOptions?.map((option, index) => (
											<option
												className="dark:bg-neutral-800"
												key={index}
												value={option.value}
											>
												{option.value}
											</option>
										))}
									</select>
								</div>
								<div className="mb-4 flex flex-col">
									<label className="mb-1 text-black dark:text-white">
										Billing Frequency:
									</label>
									<select
										className="block w-full rounded-xl border border-gray-300 bg-white p-2.5 text-gray-700 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-blue-500 dark:border-gray-600 dark:bg-slate-700 dark:text-gray-300"
										onChange={(e) => setBillingFrequency(e.target.value)}
										value={billingFrequency}
									>
										{billingFrequencyOptions?.map((option, index) => (
											<option
												className="dark:bg-neutral-800"
												key={index}
												value={option.value}
											>
												{option.value}
											</option>
										))}
									</select>
								</div>
								<div className="mb-4 flex flex-col">
									<label className="mb-1 text-black dark:text-white">
										Company Address:
									</label>
									<input
										type="text"
										value={companyAddress}
										onChange={(e) => setCompanyAddress(e.target.value)}
										className="input-underline text-black dark:text-white"
									/>
								</div>
								<div className="mb-4 flex flex-col">
									<label className="mb-1 text-black dark:text-white">
										City:
									</label>
									<input
										type="text"
										value={companyCity}
										onChange={(e) => setCompanyCity(e.target.value)}
										className="input-underline text-black dark:text-white"
									/>
								</div>
								<div className="mb-4 flex flex-col">
									<label className="mb-1 text-black dark:text-white">
										State/Province:
									</label>
									<input
										type="text"
										value={companyState}
										onChange={(e) => setCompanyState(e.target.value)}
										className="input-underline text-black dark:text-white"
									/>
								</div>
								<div className="mb-4 flex flex-col">
									<label className="mb-1 text-black dark:text-white">
										ZIP:
									</label>
									<input
										type="text"
										value={companyZip}
										onChange={(e) => setCompanyZip(e.target.value)}
										className="input-underline text-black dark:text-white"
									/>
								</div>
								<div className="mb-4 flex flex-col">
									<label className="mb-1 text-black dark:text-white">
										Country:
									</label>
									<input
										type="text"
										value={companyCountry}
										onChange={(e) => setCompanyCountry(e.target.value)}
										className="input-underline text-black dark:text-white"
									/>
								</div>
								<div className="mb-4 flex flex-col">
									<label className="mb-1 text-black dark:text-white">
										Platinum Support:
									</label>
									<select
										className="block w-full rounded-xl border border-gray-300 bg-white p-2.5 text-gray-700 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-blue-500 dark:border-gray-600 dark:bg-slate-700 dark:text-gray-300"
										onChange={(e) => setPlatinumSupport(e.target.value)}
										value={platinumSupport}
									>
										{platinumSupportOptions?.map((option, index) => (
											<option
												className="dark:bg-neutral-800"
												key={index}
												value={option.value}
											>
												{option.value}
											</option>
										))}
									</select>
								</div>
							</div>
						</div>
						{/* dropdown to fetch existing CR Jsons */}
						<div className="mb-4 flex flex-col">
							<label className="mb-1 text-black dark:text-white">
								Build from existing Reports:
							</label>
							<select
								className="block w-full rounded-xl border border-gray-300 bg-white p-2.5 text-gray-700 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-blue-500 dark:border-gray-600 dark:bg-slate-700 dark:text-gray-300"
								onChange={handleFileSelect}
							>
								<option value="">Select Consumption Report</option>
								{jsonFiles.map((file, index) => {
									const fileName = file.name;
									const startIdx = fileName.indexOf("01_raw/") + 7;
									const endIdx = fileName.indexOf("_", startIdx);
									const companyName = fileName.substring(startIdx, endIdx);
									const displayName = `${companyName}_ConsumptionReport`;

									return (
										<option key={index} value={file.name}>
											{displayName}
										</option>
									);
								})}
							</select>
						</div>
						<div className="mb-6">
							<h2 className="mb-4 text-xl font-bold">Product Table</h2>

							<DragDropContext onDragEnd={onDragEnd}>
								<Droppable droppableId="droppable-table-nce">
									{(provided) => (
										<div
											ref={provided.innerRef}
											{...provided.droppableProps}
											className="max-h-80 overflow-auto"
										>
											<table
												id="draggable-table-nce"
												className="min-w-full table-outline text-sm text-black dark:bg-gray-700 dark:text-white"
											>
												<thead>
													<tr>
														<th className="header-product p-2 text-black dark:bg-gray-700 dark:text-white">
															Product
														</th>
														<th className="header-cell p-2 text-black dark:bg-gray-700 dark:text-white">
															Assigned Seats
														</th>
														<th className="header-cell p-2 text-black dark:bg-gray-700 dark:text-white">
															Trusted Tech Team ({currencySymbols[currency]}
															/Seat)
														</th>
														<th className="header-cell p-2 text-black dark:bg-gray-700 dark:text-white">
															Trusted Tech Team (Total)
														</th>
														<th className="header-cell p-2 text-black dark:bg-gray-700 dark:text-white">
															Effective Start Date
														</th>
														<th className="header-cell p-2 text-black dark:bg-gray-700 dark:text-white"></th>
													</tr>
												</thead>
												<tbody>
													{rows.map((row, index) => (
														<Draggable
															key={row.id}
															draggableId={row.id}
															index={index}
														>
															{(provided) => (
																<tr
																	ref={provided.innerRef}
																	{...provided.draggableProps}
																	{...provided.dragHandleProps}
																	className="table-row cursor-move hover:bg-gray-100 dark:bg-gray-700 dark:hover:bg-gray-800"
																>
																	<td
																		className="whitespace-normal break-words p-2 text-black dark:text-white"
																		style={{ width: "300px" }}
																	>
																		<div className="flex items-center">
																			{row.isEditing ? (
																				<>
																					<>
																						<span className="mr-2">::</span>
																						<Select
																							value={row.product}
																							onChange={(option) =>
																								handleSelectChange(
																									option,
																									row.id
																								)
																							}
																							classNames={{
																								control: () => "w-80",
																							}}
																							options={options}
																							className="w-full"
																							menuPortalTarget={document.body}
																							styles={{
																								menuPortal: (base) => ({
																									...base,
																									zIndex: 9999,
																								}),
																								control: (base) => ({
																									...base,
																									minHeight: "unset",
																								}),
																							}}
																						/>
																					</>
																					<button
																						onClick={() =>
																							handleSaveClick(row.id)
																						}
																						className="ml-2 text-blue-500"
																					>
																						Save
																					</button>
																				</>
																			) : (
																				<div className="flex w-full items-center justify-between">
																					<span className="w-full">
																						{row.product
																							? row.product.label
																							: "Select a product"}
																					</span>
																					<button
																						onClick={() =>
																							handleEditClick(row.id)
																						}
																						className="ml-2 text-blue-500"
																					>
																						Edit
																					</button>
																				</div>
																			)}
																		</div>
																	</td>
																	<td className="w-24 p-2">
																		<input
																			type="number"
																			value={
																				tempValues[
																					`${row.id}-assignedSeats`
																				] !== undefined
																					? tempValues[
																							`${row.id}-assignedSeats`
																						]
																					: row.assignedSeats
																			}
																			onChange={(e) =>
																				handleInputChange(
																					e,
																					row.id,
																					"assignedSeats"
																				)
																			}
																			onKeyDown={(e) => {
																				if (e.key === "-" || e.key === "e") {
																					e.preventDefault();
																				}
																			}}
																			min="0"
																			onBlur={() =>
																				handleInputBlur(row.id, "assignedSeats")
																			}
																			className="input-underline w-full text-black dark:text-white"
																		/>
																	</td>
																	<td className="w-24 p-2">
																		{currencySymbols[currency]}
																		{Math.round(
																			(isNaN(row.tttPrice) ? 0 : row.tttPrice) *
																				100
																		) / 100}
																	</td>
																	<td className="w-24 p-2">
																		{currencySymbols[currency]}
																		{Math.round(
																			(isNaN(row.tttTotal) ? 0 : row.tttTotal) *
																				100
																		) / 100}
																	</td>
																	<td className="w-24 p-2">
																		<input
																			type="date"
																			value={
																				tempValues[
																					`${row.id}-effectiveStartDate`
																				] !== undefined
																					? tempValues[
																							`${row.id}-effectiveStartDate`
																						]
																					: row.effectiveStartDate
																						? row.effectiveStartDate
																								.toISOString()
																								.split("T")[0]
																						: ""
																			}
																			onChange={(e) =>
																				handleInputChange(
																					e,
																					row.id,
																					"effectiveStartDate"
																				)
																			}
																			onBlur={() =>
																				handleInputBlur(
																					row.id,
																					"effectiveStartDate"
																				)
																			}
																			className="input-underline w-full text-black dark:text-white"
																		/>
																	</td>
																	<td
																		className={`p-2 text-black dark:text-white ${index === rows.length - 1 ? "last-row" : ""}`}
																	>
																		<button
																			onClick={() => handleDeleteRow(row.id)}
																			className="text-red-500"
																		>
																			❌
																		</button>
																	</td>
																</tr>
															)}
														</Draggable>
													))}
													{provided.placeholder}
												</tbody>
											</table>
										</div>
									)}
								</Droppable>
							</DragDropContext>
							<div className="mt-4 flex items-center justify-between">
								<button
									onClick={handleAddRow}
									className="rounded bg-blue-500 px-4 py-2 text-white"
								>
									Add Row
								</button>
							</div>
							<div className="mt-4 flex justify-end text-sm">
								<div>
									{/* <div>
										Trusted Tech Team / Month: {currencySymbols[currency]}
										{Math.round(totalTtt * 100) / 100}
									</div> */}
									{/* <div>
										Savings / Month: {currencySymbols[currency]}
										{Math.round(totalSavings * 100) / 100}
									</div>
									<div>
										Savings / Year: {currencySymbols[currency]}
										{Math.round(totalAnnualSavings * 100) / 100}
									</div> */}
								</div>
							</div>
							<div className="mt-4 flex justify-end">
								<button
									onClick={handlePreviewSalesOrder}
									className="btn-secondary py-2"
								>
									Preview Sales Order
								</button>
								<button
									onClick={handleDownloadSalesOrderPDF}
									className="btn-primary py-2"
								>
									Download Sales Order
								</button>
							</div>
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

export default CReport;
