import React, { useState } from "react";
import { useAuth } from "../components/AuthProvider";
import useDebounce from "../hooks/useDebounce";
import { useQuery } from "react-query";
import { formatMoney } from "../utils";
import axios from "axios";
import { serverAddress } from "../authConfig";
import ProfitPieChart, {
	renderActiveShape,
} from "../components/analytics/ProfitPieChart";

export default function Analytics() {
	const { user, accessToken } = useAuth();

	const [selectedSalesperson, setSelectedSalesperson] = useState(
		user?.userName
	);
	const [selectedMonth, setSelectedMonth] = useState("All Time");
	const [searchInput, setSearchInput] = useState("");
	const [selectedStatus, setSelectedStatus] = useState("All Statuses");
	const [activeSalesOrderId, setActiveSalesOrderId] = useState("");
	const debouncedSearchInput = useDebounce(searchInput, 500);

	const fetchSalespersons = async () => {
		// AMs should only be able to see their own sales orders
		if (!user?.isElevatedPermissions)
			return [{ "full_name": user?.userName }];

		try {
			const response = await axios.get(
				`${serverAddress}/account-managers`,
				{
					headers: {
						"Content-Type": "application/json",
						"Authorization": `Bearer ${accessToken}`,
						"X-User-Name": `${user?.userName}`,
					},
				}
			);
			if (response.status === 200) {
				return response.data.salespersons;
			}
		} catch (error) {
			console.error(error);
		}
	};

	const {
		data: salespersons,
		error: salespersonsError,
		isLoading: salespersonsIsLoading,
	} = useQuery({
		queryKey: ["salespersons", user?.userName],
		queryFn: () => fetchSalespersons(),
		staleTime: 30 * 60 * 1000, // Cache data for 30 mins. Number of AMs doesn't change often
	});

	const fetchUsersSalesOrders = async (salesperson) => {
		if (salesperson === undefined) return;

		setSelectedSalesperson(salesperson);
		try {
			const response = await axios.get(
				`${serverAddress}/sales-orders/users/${salesperson}`,
				{
					headers: {
						"Content-Type": "application/json",
						"Authorization": `Bearer ${accessToken}`,
						"X-User-Name": `${salesperson}`,
					},
				}
			);
			if (response.status === 200) {
				return response.data;
			}
		} catch (error) {
			console.error(error);
		}
	};

	const {
		data: salesOrders,
		error: salesOrdersError,
		isLoading: salesOrdersAreLoading,
	} = useQuery({
		queryKey: ["listSalespersonsSalesOrders", { selectedSalesperson }],
		queryFn: () => fetchUsersSalesOrders(selectedSalesperson),
		staleTime: 2 * 60 * 1000, // Cache data for 2 mins. User can clear cache by refreshing
		enabled: !!salespersons,
	});

	const prevMonthEpoch = (() => {
		const currentDate = new Date();
		const prevMonthDate = new Date(
			currentDate.getFullYear(),
			currentDate.getMonth() - 1,
			1
		);
		return Math.floor(prevMonthDate.getTime() / 1000);
	})();

	const currentMonthEpoch = (() => {
		const currentDate = new Date();
		const currentMonthDate = new Date(
			currentDate.getFullYear(),
			currentDate.getMonth(),
			1
		);
		return Math.floor(currentMonthDate.getTime() / 1000);
	})();

	const currentYearEpoch = (() => {
		const currentDate = new Date();
		const currentYearDate = new Date(
			currentDate.getFullYear(),
			0, // January is month 0
			1
		);
		return Math.floor(currentYearDate.getTime() / 1000);
	})();

	const selectedUserCurrentYearTotalContractsValue = salesOrders?.reduce(
		(partialSum, a) => {
			if (a._ts >= currentYearEpoch && a.status === "Signed") {
				return partialSum + a.totalContractValue;
			}
			return partialSum;
		},
		0
	);

	const selectedUserCurrentYearCommission = salesOrders?.reduce(
		(partialSum, a) => {
			if (a._ts >= currentYearEpoch && a.status === "Signed") {
				return partialSum + a.commission;
			}
			return partialSum;
		},
		0
	);

	const selectedUserCurrentYearProfit = salesOrders?.reduce(
		(partialSum, a) => {
			if (a._ts >= currentYearEpoch && a.status === "Signed") {
				return partialSum + a.monthlyProfit * a.termMonths;
			}
			return partialSum;
		},
		0
	);

	const selectedUserPrevMonthTotalContractsValue = salesOrders?.reduce(
		(partialSum, a) => {
			if (
				a._ts >= prevMonthEpoch &&
				a._ts <= currentMonthEpoch &&
				a.status === "Signed"
			) {
				return partialSum + a.totalContractValue;
			}
			return partialSum;
		},
		0
	);

	const selectedUserPrevMonthProfit = salesOrders?.reduce((partialSum, a) => {
		if (
			a._ts >= prevMonthEpoch &&
			a._ts <= currentMonthEpoch &&
			a.status === "Signed"
		) {
			return partialSum + a.profit;
		}
		return partialSum;
	}, 0);

	const selectedUserPrevMonthCommission = salesOrders?.reduce(
		(partialSum, a) => {
			if (
				a._ts >= prevMonthEpoch &&
				a._ts <= currentMonthEpoch &&
				a.status === "Signed"
			) {
				return partialSum + a.commission;
			}
			return partialSum;
		},
		0
	);

	const selectedUserCurrentMonthTotalContractsValue = salesOrders?.reduce(
		(partialSum, a) => {
			if (a._ts >= currentMonthEpoch && a.status === "Signed") {
				return partialSum + a.totalContractValue;
			}
			return partialSum;
		},
		0
	);

	const selectedUserCurrentMonthProfit = salesOrders?.reduce(
		(partialSum, a) => {
			if (a._ts >= currentMonthEpoch && a.status === "Signed") {
				return partialSum + a.monthlyProfit;
			}
			return partialSum;
		},
		0
	);

	const selectedUserCurrentMonthCommission = salesOrders?.reduce(
		(partialSum, a) => {
			if (a._ts >= currentMonthEpoch && a.status === "Signed") {
				return partialSum + a.commission;
			}
			return partialSum;
		},
		0
	);

	const selectedUserCurrentMonthCostOfGoodsSold = salesOrders?.reduce(
		(partialSum, a) => {
			if (a._ts >= currentMonthEpoch && a.status === "Signed") {
				return partialSum + a.monthlyCostOfGoods;
			}
			return partialSum;
		},
		0
	);

	const prevMonthTotalContractsValue = salesOrders?.reduce(
		(partialSum, a) => {
			if (
				a._ts >= prevMonthEpoch &&
				a._ts <= currentMonthEpoch &&
				a.status === "Signed"
			) {
				return partialSum + a.totalContractValue;
			}
			return partialSum;
		},
		0
	);

	const prevMonthCommission = salesOrders?.reduce((partialSum, a) => {
		if (
			a._ts >= prevMonthEpoch &&
			a._ts <= currentMonthEpoch &&
			a.status === "Signed"
		) {
			return partialSum + a.commission;
		}
		return partialSum;
	}, 0);

	const currentMonthTotalContractsValue = salesOrders?.reduce(
		(partialSum, a) => {
			if (a._ts >= currentMonthEpoch && a.status === "Signed") {
				return partialSum + a.totalContractValue;
			}
			return partialSum;
		},
		0
	);

	const currentMonthCommission = salesOrders?.reduce((partialSum, a) => {
		if (a._ts >= currentMonthEpoch && a.status === "Signed") {
			return partialSum + a.commission;
		}
		return partialSum;
	}, 0);

	const currentYearTotalContractsValue = salesOrders?.reduce(
		(partialSum, a) => {
			if (a._ts >= currentYearEpoch && a.status === "Signed") {
				return partialSum + a.totalContractValue;
			}
			return partialSum;
		},
		0
	);

	const currentYearCostOfGoods = salesOrders?.reduce((partialSum, a) => {
		if (a._ts >= currentYearEpoch && a.status === "Signed") {
			return partialSum + a.monthlyCostOfGoods * 12;
		}
		return partialSum;
	}, 0);

	const currentYearCommission = salesOrders?.reduce((partialSum, a) => {
		if (a._ts >= currentYearEpoch && a.status === "Signed") {
			return partialSum + a.commission;
		}
		return partialSum;
	}, 0);

	const currentYearProfit = salesOrders?.reduce((partialSum, a) => {
		if (a._ts >= currentYearEpoch && a.status === "Signed") {
			return partialSum + a.monthlyProfit * a.termMonths;
		}
		return partialSum;
	}, 0);

	const data = [
		{
			name: "Cost of Goods",
			value: currentYearCostOfGoods,
			fill: "#BA274A",
		},
		{
			name: "Profit",
			value: currentYearProfit - currentYearCommission,
			fill: "#82D173",
		},
		{
			name: "Commission",
			value: currentYearCommission,
			fill: "#2191FB",
		},
		// { name: "Group C", value: 300 },
		// { name: "Group D", value: 200 },
	];

	return (
		<div className="animate-fade-in rounded-lg bg-white p-4 shadow-md transition-opacity duration-200 dark:bg-slate-700">
			<div className="flex flex-col">
				<div className="flex flex-col gap-4">
					<h2 className="text-xl font-bold">Salespersons</h2>
					<div className="w-1/4">
						<select
							className="block w-full rounded-xl border border-neutral-300 bg-white p-2.5 text-gray-700 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-blue-500 dark:border-gray-600 dark:bg-slate-700 dark:text-gray-300"
							onChange={(e) =>
								setSelectedSalesperson(e.target.value)
							}
							value={selectedSalesperson}
						>
							{salespersons?.map((salesperson, index) => (
								<option
									className="dark:bg-neutral-800"
									key={index}
									value={salesperson.full_name}
								>
									{salesperson.full_name}
								</option>
							))}
						</select>
					</div>
					<div className="flex gap-4">
						<div className="flex flex-col">
							<h4>Last Month</h4>
							<div className="flex gap-1">
								<div className="flex gap-1">
									<div className="bg-slate-800 rounded-md p-2">
										<p>Revenue:</p>
										<p>
											$
											{formatMoney(
												prevMonthTotalContractsValue
											)}
										</p>
									</div>
									<div className="bg-slate-800 rounded-md p-2">
										<p>Commission:</p>
										<p>
											${formatMoney(prevMonthCommission)}
										</p>
									</div>
								</div>
							</div>
						</div>
						<div className="flex flex-col">
							<h4>This Month</h4>
							<div className="flex gap-1">
								<div className="flex gap-1">
									<div className="bg-slate-800 rounded-md p-2">
										<p>Revenue:</p>
										<p>
											$
											{formatMoney(
												currentMonthTotalContractsValue
											)}
										</p>
									</div>
									<div className="bg-slate-800 rounded-md p-2">
										<p>Commission:</p>
										<p>
											$
											{formatMoney(
												currentMonthCommission
											)}
										</p>
									</div>
								</div>
							</div>
						</div>
						<div className="flex flex-col">
							<h4>This Year</h4>
							<div className="flex gap-1">
								<div className="bg-slate-800 rounded-md p-2">
									<p>TCVs:</p>
									<p>
										$
										{formatMoney(
											currentYearTotalContractsValue
										)}
									</p>
								</div>
								<div className="bg-slate-800 rounded-md p-2">
									<p>Commission:</p>
									<p>${formatMoney(currentYearCommission)}</p>
								</div>
								<div className="bg-slate-800 rounded-md p-2">
									<p>Profit:</p>
									<p>${formatMoney(currentYearProfit)}</p>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="flex justify-between items-center ">
					<div className="flex w-full justify-center items-center">
						<div className="flex flex-col">
							<p>
								Annual Profitability of {selectedSalesperson}
								&apos;s accounts:{" "}
							</p>
						</div>
						<ProfitPieChart
							data={data}
							renderActiveShape={renderActiveShape}
						/>
					</div>
					<span></span>
				</div>
			</div>
		</div>
	);
}

export const DataBubble = ({ title, moneyDataPoint }) => {
	return (
		<div className="bg-slate-800 rounded-md p-2">
			<p>{title}:</p>
			<p>${moneyDataPoint}</p>
		</div>
	);
};
