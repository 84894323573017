import { Add } from "@mui/icons-material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import PercentIcon from "@mui/icons-material/Percent";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import { useState, useEffect } from "react";
import { FaEquals } from "react-icons/fa";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";

// FX Rates Used - 7/26/24
//   USD    1
//   GBP    0.78
//   EUR    0.92

const platinumPricing = {
	USD: {
		cost: {
			sevBcCase: 144,
			sevAHour: 550,
			onPremiseHour: 450,
			professionalServicesHour: 100,
			consultingSessionsHour: 60,
			microsoftAssessment: 400,
			dedicatedCloudEngineerHours: 90,
			azureCase: 750,
			azureConsultingSessionsHour: 100,
		},
		free: {
			packageTotalPrice: 0,
			monthlyPrice: 0,
			packageBenefits: {
				sevBcCase: 0,
				sevAHour: 0,
				onPremiseHour: 0,
				professionalServicesHour: 0,
				consultingSessionsHour: 0,
				microsoftAssessment: 0,
				dedicatedCloudEngineerHours: 0,
				azureCase: 0,
				azureConsultingSessionsHour: 0,
			},
			overageRates: {
				sevBcCase: 250,
				sevAHour: 750,
				onPremiseHour: 650,
				professionalServicesHour: 350,
				consultingSessionsHour: 250,
				microsoftAssessment: 5000,
				dedicatedCloudEngineerHours: 210,
				azureCase: 1050,
				azureConsultingSessionsHour: 270,
			},
		},
		standard: {
			packageTotalPrice: 5400,
			monthlyPrice: 450,
			packageBenefits: {
				sevBcCase: 15,
				sevAHour: 0,
				onPremiseHour: 0,
				professionalServicesHour: 0,
				consultingSessionsHour: 12,
				microsoftAssessment: 0,
				dedicatedCloudEngineerHours: 0,
				azureCase: 0,
				azureConsultingSessionsHour: 0,
			},
			overageRates: {
				sevBcCase: 230,
				sevAHour: 750,
				onPremiseHour: 650,
				professionalServicesHour: 325,
				consultingSessionsHour: 240,
				microsoftAssessment: 5000,
				dedicatedCloudEngineerHours: 203,
				azureCase: 1050,
				azureConsultingSessionsHour: 250,
			},
		},
		premium: {
			packageTotalPrice: 21600,
			monthlyPrice: 1800,
			packageBenefits: {
				sevBcCase: 50,
				sevAHour: 2,
				onPremiseHour: 2,
				professionalServicesHour: 20,
				consultingSessionsHour: 24,
				microsoftAssessment: 1,
				dedicatedCloudEngineerHours: 0,
				azureCase: 0,
				azureConsultingSessionsHour: 0,
			},
			overageRates: {
				sevBcCase: 209,
				sevAHour: 750,
				onPremiseHour: 650,
				professionalServicesHour: 300,
				consultingSessionsHour: 225,
				microsoftAssessment: 5000,
				dedicatedCloudEngineerHours: 194,
				azureCase: 1050,
				azureConsultingSessionsHour: 225,
			},
		},
		enterprise: {
			packageTotalPrice: 54000,
			monthlyPrice: 4500,
			packageBenefits: {
				sevBcCase: 100,
				sevAHour: 5,
				onPremiseHour: 5,
				professionalServicesHour: 100,
				consultingSessionsHour: 48,
				microsoftAssessment: 1,
				dedicatedCloudEngineerHours: 48,
				azureCase: 0,
				azureConsultingSessionsHour: 0,
			},
			overageRates: {
				sevBcCase: 187,
				sevAHour: 750,
				onPremiseHour: 650,
				professionalServicesHour: 275,
				consultingSessionsHour: 210,
				microsoftAssessment: 5000,
				dedicatedCloudEngineerHours: 180,
				azureCase: 1050,
				azureConsultingSessionsHour: 200,
			},
		},
	},
	EUR: {
		cost: {
			sevBcCase: 132.48,
			sevAHour: 506,
			onPremiseHour: 414,
			professionalServicesHour: 92,
			consultingSessionsHour: 55.2,
			microsoftAssessment: 368,
			dedicatedCloudEngineerHours: 82.8,
			azureCase: 690,
			azureConsultingSessionsHour: 92,
		},
		free: {
			packageTotalPrice: 0,
			monthlyPrice: 0,
			packageBenefits: {
				sevBcCase: 0,
				sevAHour: 0,
				onPremiseHour: 0,
				professionalServicesHour: 0,
				consultingSessionsHour: 0,
				microsoftAssessment: 0,
				dedicatedCloudEngineerHours: 0,
				azureCase: 0,
				azureConsultingSessionsHour: 0,
			},
			overageRates: {
				sevBcCase: 230,
				sevAHour: 690,
				onPremiseHour: 598,
				professionalServicesHour: 322,
				consultingSessionsHour: 230,
				microsoftAssessment: 4600,
				dedicatedCloudEngineerHours: 193.2,
				azureCase: 966,
				azureConsultingSessionsHour: 248.4,
			},
		},
		standard: {
			packageTotalPrice: 4968,
			monthlyPrice: 414,
			packageBenefits: {
				sevBcCase: 15,
				sevAHour: 0,
				onPremiseHour: 0,
				professionalServicesHour: 0,
				consultingSessionsHour: 12,
				microsoftAssessment: 0,
				dedicatedCloudEngineerHours: 0,
				azureCase: 0,
				azureConsultingSessionsHour: 0,
			},
			overageRates: {
				sevBcCase: 211.6,
				sevAHour: 690,
				onPremiseHour: 598,
				professionalServicesHour: 299,
				consultingSessionsHour: 220.8,
				microsoftAssessment: 4600,
				dedicatedCloudEngineerHours: 186.76,
				azureCase: 966,
				azureConsultingSessionsHour: 230,
			},
		},
		premium: {
			packageTotalPrice: 19872,
			monthlyPrice: 1656,
			packageBenefits: {
				sevBcCase: 50,
				sevAHour: 2,
				onPremiseHour: 2,
				professionalServicesHour: 20,
				consultingSessionsHour: 24,
				microsoftAssessment: 1,
				dedicatedCloudEngineerHours: 0,
				azureCase: 0,
				azureConsultingSessionsHour: 0,
			},
			overageRates: {
				sevBcCase: 192.28,
				sevAHour: 690,
				onPremiseHour: 598,
				professionalServicesHour: 276,
				consultingSessionsHour: 207,
				microsoftAssessment: 4600,
				dedicatedCloudEngineerHours: 178.48,
				azureCase: 966,
				azureConsultingSessionsHour: 207,
			},
		},
		enterprise: {
			packageTotalPrice: 49680,
			monthlyPrice: 4140,
			packageBenefits: {
				sevBcCase: 100,
				sevAHour: 5,
				onPremiseHour: 5,
				professionalServicesHour: 100,
				consultingSessionsHour: 48,
				microsoftAssessment: 1,
				dedicatedCloudEngineerHours: 48,
				azureCase: 0,
				azureConsultingSessionsHour: 0,
			},
			overageRates: {
				sevBcCase: 172.04,
				sevAHour: 690,
				onPremiseHour: 598,
				professionalServicesHour: 253,
				consultingSessionsHour: 193.2,
				microsoftAssessment: 4600,
				dedicatedCloudEngineerHours: 165.6,
				azureCase: 966,
				azureConsultingSessionsHour: 184,
			},
		},
	},
	GBP: {
		cost: {
			sevBcCase: 112.32,
			sevAHour: 429,
			onPremiseHour: 351,
			professionalServicesHour: 78,
			consultingSessionsHour: 46.8,
			microsoftAssessment: 312,
			dedicatedCloudEngineerHours: 70.2,
			azureCase: 585,
			azureConsultingSessionsHour: 78,
		},
		free: {
			packageTotalPrice: 0,
			monthlyPrice: 0,
			packageBenefits: {
				sevBcCase: 0,
				sevAHour: 0,
				onPremiseHour: 0,
				professionalServicesHour: 0,
				consultingSessionsHour: 0,
				microsoftAssessment: 0,
				dedicatedCloudEngineerHours: 0,
				azureCase: 0,
				azureConsultingSessionsHour: 0,
			},
			overageRates: {
				sevBcCase: 195,
				sevAHour: 585,
				onPremiseHour: 507,
				professionalServicesHour: 273,
				consultingSessionsHour: 195,
				microsoftAssessment: 3900,
				dedicatedCloudEngineerHours: 163.8,
				azureCase: 819,
				azureConsultingSessionsHour: 210.6,
			},
		},
		standard: {
			packageTotalPrice: 4212,
			monthlyPrice: 351,
			packageBenefits: {
				sevBcCase: 15,
				sevAHour: 0,
				onPremiseHour: 0,
				professionalServicesHour: 0,
				consultingSessionsHour: 12,
				microsoftAssessment: 0,
				dedicatedCloudEngineerHours: 0,
				azureCase: 0,
				azureConsultingSessionsHour: 0,
			},
			overageRates: {
				sevBcCase: 179.4,
				sevAHour: 585,
				onPremiseHour: 507,
				professionalServicesHour: 253.5,
				consultingSessionsHour: 187.2,
				microsoftAssessment: 3900,
				dedicatedCloudEngineerHours: 158.34,
				azureCase: 819,
				azureConsultingSessionsHour: 195,
			},
		},
		premium: {
			packageTotalPrice: 16848,
			monthlyPrice: 1302,
			packageBenefits: {
				sevBcCase: 50,
				sevAHour: 2,
				onPremiseHour: 2,
				professionalServicesHour: 20,
				consultingSessionsHour: 24,
				microsoftAssessment: 1,
				dedicatedCloudEngineerHours: 0,
				azureCase: 0,
				azureConsultingSessionsHour: 0,
			},
			overageRates: {
				sevBcCase: 163.02,
				sevAHour: 585,
				onPremiseHour: 507,
				professionalServicesHour: 234,
				consultingSessionsHour: 175.5,
				microsoftAssessment: 3900,
				dedicatedCloudEngineerHours: 151.32,
				azureCase: 819,
				azureConsultingSessionsHour: 175.5,
			},
		},
		enterprise: {
			packageTotalPrice: 42120,
			monthlyPrice: 3510,
			packageBenefits: {
				sevBcCase: 100,
				sevAHour: 5,
				onPremiseHour: 5,
				professionalServicesHour: 100,
				consultingSessionsHour: 48,
				microsoftAssessment: 1,
				dedicatedCloudEngineerHours: 48,
				azureCase: 0,
				azureConsultingSessionsHour: 0,
			},
			overageRates: {
				sevBcCase: 145.86,
				sevAHour: 585,
				onPremiseHour: 507,
				professionalServicesHour: 214.5,
				consultingSessionsHour: 163.8,
				microsoftAssessment: 3900,
				dedicatedCloudEngineerHours: 140.4,
				azureCase: 819,
				azureConsultingSessionsHour: 156,
			},
		},
	},
};

const currencySymbols = {
	USD: "$",
	EUR: "€",
	GBP: "£",
};

const defaultFormData = {
	sevBcCase: "",
	sevAHour: "",
	onPremiseHour: "",
	professionalServicesHour: "",
	consultingSessionsHour: "",
	microsoftAssessment: "",
	dedicatedCloudEngineerHours: "",
	azureCase: "",
	azureConsultingSessionsHour: "",
	additionalMarkup: "",
	discount: "",
};

const PlatinumSupportCalculator = ({ darkMode }) => {
	const [selectedPackage, setSelectedPackage] = useState("standard");
	const [selectedCurrency, setSelectedCurrency] = useState("USD");
	const [formData, setFormData] = useState({
		sevBcCase: "",
		sevAHour: "",
		onPremiseHour: "",
		professionalServicesHour: "",
		consultingSessionsHour: "",
		microsoftAssessment: "",
		dedicatedCloudEngineerHours: "",
		azureCase: "",
		azureConsultingSessionsHour: "",
		additionalMarkup: "",
		discount: "",
	});
	const [errors, setErrors] = useState({
		sevBcCase: false,
		sevAHour: false,
		onPremiseHour: false,
		professionalServicesHour: false,
		consultingSessionsHour: false,
		microsoftAssessment: false,
		dedicatedCloudEngineerHours: false,
		azureCase: false,
		azureConsultingSessionsHour: false,
		additionalMarkup: false,
		discount: false,
	});

	const displayNames = {
		sevBcCase: "Sev B/C (Case)",
		sevAHour: "Sev A (Hour)",
		onPremiseHour: "On-premise/Hybrid (Hour)",
		professionalServicesHour: "Professional Services (Hour)*",
		consultingSessionsHour: "Consulting Sessions (Hour)*",
		microsoftAssessment: "Microsoft Assessment",
		dedicatedCloudEngineerHours: "Dedicated Cloud Engineer (Hours)",
		azureCase: "Azure (Case)",
		azureConsultingSessionsHour: "Azure Consulting Sessions (Hour)*",
		additionalMarkup: "Additional Markup",
		discount: "Discount",
	};

	const [isMarkupPercentage, setIsMarkupPercentage] = useState(false);
	const [isDiscountPercentage, setIsDiscountPercentage] = useState(false);

	const handlePackageChange = (e) => {
		const newPackage = e.target.value;
		setSelectedPackage(newPackage);
	};

	const handleCurrencyChange = (e) => {
		const newCurrency = e.target.value;
		setSelectedCurrency(newCurrency);
	};

	const validateInput = (name, value) => {
		if (name === "additionalMarkup" || name === "discount") {
			return (
				/^\d*\.?\d{0,2}$/.test(value) &&
				(value === "" || parseFloat(value) >= 0)
			);
		} else {
			return /^\d*$/.test(value) && (value === "" || parseInt(value) >= 0);
		}
	};

	const handleChange = (e) => {
		const { name, value } = e.target;

		if (validateInput(name, value)) {
			setFormData({
				...formData,
				[name]: value,
			});
			setErrors({
				...errors,
				[name]: false,
			});
		} else {
			setErrors({
				...errors,
				[name]: true,
			});
		}
	};

	const handleKeyDown = (e) => {
		if (e.key === "-" || e.key === "e" || e.key === "E") {
			e.preventDefault();
		}
	};

	const resetForm = () => {
		setFormData(defaultFormData);
		setErrors({
			sevBcCase: false,
			sevAHour: false,
			onPremiseHour: false,
			professionalServicesHour: false,
			consultingSessionsHour: false,
			microsoftAssessment: false,
			dedicatedCloudEngineerHours: false,
			azureCase: false,
			azureConsultingSessionsHour: false,
			additionalMarkup: false,
			discount: false,
		});
	};

	const calculateTotal = () => {
		const rates =
			platinumPricing[selectedCurrency][selectedPackage].overageRates;

		const addonTotal = Object.keys(rates).reduce((acc, key) => {
			const formValue = parseFloat(formData[key]) || 0;
			const rate = rates[key];

			return acc + formValue * rate;
		}, 0);

		const baseAmount =
			platinumPricing[selectedCurrency][selectedPackage].packageTotalPrice +
			addonTotal;

		const additionalMarkup = isMarkupPercentage
			? (baseAmount * (parseFloat(formData.additionalMarkup) || 0)) / 100
			: parseFloat(formData.additionalMarkup) || 0;

		const discount = isDiscountPercentage
			? (baseAmount * (parseFloat(formData.discount) || 0)) / 100
			: parseFloat(formData.discount) || 0;

		const total =
			platinumPricing[selectedCurrency][selectedPackage].packageTotalPrice +
			addonTotal +
			additionalMarkup;

		console.log("Total:", total);
		console.log("Discount:", discount);
		console.log(
			"Total after discount applied:",
			total > 0 ? total - discount : 0
		);

		return {
			addonTotal: addonTotal,
			markupPrice: additionalMarkup,
			discountedPrice: total > 0 ? total - discount : 0,
			discountPrice: discount,
			total: total,
		};
	};

	const calculateAddonTotal = () => {
		const cost = platinumPricing[selectedCurrency]["cost"];
		const benefits =
			platinumPricing[selectedCurrency][selectedPackage].packageBenefits;

		const sevBcCaseTotal =
			((formData["sevBcCase"] ? parseInt(formData["sevBcCase"]) : 0) +
				benefits["sevBcCase"]) *
			cost["sevBcCase"];
		const sevAHourTotal =
			((formData["sevAHour"] ? parseInt(formData["sevAHour"]) : 0) +
				benefits["sevAHour"]) *
			cost["sevAHour"];
		const onPremiseHourTotal =
			((formData["onPremiseHour"] ? parseInt(formData["onPremiseHour"]) : 0) +
				benefits["onPremiseHour"]) *
			cost["onPremiseHour"];
		const professionalServicesHourTotal =
			((formData["professionalServicesHour"]
				? parseInt(formData["professionalServicesHour"])
				: 0) +
				benefits["professionalServicesHour"]) *
			cost["professionalServicesHour"];
		const consultingSessionsHourTotal =
			((formData["consultingSessionsHour"]
				? parseInt(formData["consultingSessionsHour"])
				: 0) +
				benefits["consultingSessionsHour"]) *
			cost["consultingSessionsHour"];
		const microsoftAssessmentTotal =
			((formData["microsoftAssessment"]
				? parseInt(formData["microsoftAssessment"])
				: 0) +
				benefits["microsoftAssessment"]) *
			cost["microsoftAssessment"];
		const dedicatedCloudEngineerHoursTotal =
			((formData["dedicatedCloudEngineerHours"]
				? parseInt(formData["dedicatedCloudEngineerHours"])
				: 0) +
				benefits["dedicatedCloudEngineerHours"]) *
			cost["dedicatedCloudEngineerHours"];
		const azureCaseTotal =
			((formData["azureCase"] ? parseInt(formData["azureCase"]) : 0) +
				benefits["azureCase"]) *
			cost["azureCase"];
		const azureConsultingSessionsHourTotal =
			((formData["azureConsultingSessionsHour"]
				? parseInt(formData["azureConsultingSessionsHour"])
				: 0) +
				benefits["azureConsultingSessionsHour"]) *
			cost["azureConsultingSessionsHour"];

		const addonTotalCost =
			sevBcCaseTotal +
			sevAHourTotal +
			onPremiseHourTotal +
			professionalServicesHourTotal +
			consultingSessionsHourTotal +
			microsoftAssessmentTotal +
			dedicatedCloudEngineerHoursTotal +
			azureCaseTotal +
			azureConsultingSessionsHourTotal;

		console.log("Addon Total Cost: ", addonTotalCost);

		return {
			sevBcCaseTotal: sevBcCaseTotal,
			sevAHourTotal: sevAHourTotal,
			onPremiseHourTotal: onPremiseHourTotal,
			professionalServicesHourTotal: professionalServicesHourTotal,
			consultingSessionsHourTotal: consultingSessionsHourTotal,
			microsoftAssessmentTotal: microsoftAssessmentTotal,
			dedicatedCloudEngineerHoursTotal: dedicatedCloudEngineerHoursTotal,
			azureCaseTotal: azureCaseTotal,
			azureConsultingSessionsHourTotal: azureConsultingSessionsHourTotal,
			addonTotalCost: addonTotalCost,
		};
	};

	const getColorForPercentage = (percent) => {
		percent = Math.min(Math.max(percent, -100), 100);

		if (percent < 10) {
			return "text-red-500 dark:text-red-300";
		} else if (percent < 30) {
			return "text-orange-500 dark:text-orange-300";
		} else if (percent >= 50) {
			return "text-green-500 dark:text-green-300";
		} else {
			return `text-green-500 dark:text-green-300`;
		}
	};

	const formatNumber = (number) => {
		return number.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	};

	useEffect(() => {
		const elements = document.querySelectorAll(".animate-fade-in");
		elements.forEach((el) => {
			el.classList.add("opacity-0");
			setTimeout(() => {
				el.classList.remove("opacity-0");
				el.classList.add("opacity-100");
			}, 20);
		});
	}, []);

	const results = calculateTotal();
	const addonTotal = calculateAddonTotal();
	console.log("Final Addon Total:", addonTotal);
	const weightedMargin =
		results.discountedPrice > 0
			? (
					((results.discountedPrice - addonTotal.addonTotalCost) /
						results.discountedPrice) *
					100
				).toFixed(2)
			: 0;
	const profitColor = getColorForPercentage(weightedMargin);

	const [isAddonsVisible, setIsAddonsVisible] = useState(false);

	const toggleAddonsVisibility = () => {
		setIsAddonsVisible(!isAddonsVisible);
	};

	return (
		<div>
			<div>
				<div className="flex gap-6 lg:grid-cols-2">
					<div className="flex-1 space-y-4">
						<div className="flex flex-wrap flex-1 gap-4">
							<div className="flex-1 w-1/2 p-6 space-y-4 transition-opacity duration-200 bg-white rounded-lg shadow-md animate-fade-in dark:bg-slate-700">
								<label className="block font-semibold text-slate-500 dark:text-white">
									Package
								</label>
								<select
									id="packageSelector"
									value={selectedPackage}
									onChange={handlePackageChange}
									className="w-full p-2 bg-white border border-gray-300 rounded-lg custom-select text-slate-700 hover:cursor-pointer dark:border-gray-600 dark:bg-slate-700 dark:text-white"
								>
									<option value="free">Free</option>
									<option value="standard">Standard</option>
									<option value="premium">Premium</option>
									<option value="enterprise">Enterprise</option>
								</select>
							</div>
							<div className="flex-1 w-1/2 p-6 space-y-4 transition-opacity duration-200 bg-white rounded-lg shadow-md animate-fade-in dark:bg-slate-700">
								<label className="block font-semibold text-slate-500 dark:text-white">
									Currency
								</label>
								<select
									id="currencySelector"
									value={selectedCurrency}
									onChange={handleCurrencyChange}
									className="w-full p-2 bg-white border border-gray-300 rounded-lg custom-select text-slate-700 hover:cursor-pointer dark:border-gray-600 dark:bg-slate-700 dark:text-white"
								>
									<option value="USD">🇺🇸 USD</option>
									<option value="EUR">🇪🇺 EUR</option>
									<option value="GBP">🇬🇧 GBP</option>
								</select>
							</div>
						</div>
						<button
							className="justify-center w-full btn-primary hover:scale-100"
							onClick={resetForm}
						>
							Reset
						</button>
						<div className="p-6 transition-opacity duration-200 bg-white rounded-lg shadow-md animate-fade-in dark:bg-slate-700">
							<div className="flex mb-3 font-semibold text-slate-500 dark:text-white align-center gap-1">
								<p>Addons</p>
								<Tippy
									content="Enter any additional addons to be included on top of the included amount for the selected package."
									placement="top"
									animation="fade"
									theme={darkMode ? "light" : "dark"}
								>
									<InfoOutlinedIcon
										style={{ width: "18px" }}
									></InfoOutlinedIcon>
								</Tippy>
							</div>
							{Object.keys(formData).map((field, index) => {
								const filteredFields = Object.keys(formData).filter(
									(field) =>
										field !== "additionalMarkup" && field !== "discount"
								);

								const includedAmount =
									platinumPricing[selectedCurrency][selectedPackage]
										.packageBenefits[field] || 0;
								const additionalAmount = parseFloat(formData[field]) || 0;
								const totalAmount = includedAmount + additionalAmount;

								return (
									field !== "additionalMarkup" &&
									field !== "discount" && (
										<div key={field}>
											<div>
												<label className="block mb-2 text-sm font-medium text-gray-500 text-start dark:text-gray-300">
													{displayNames[field]}:
												</label>
												<div className="grid grid-cols-[auto_1fr_auto_1fr_auto] items-center justify-between gap-3">
													<div className="col-span-2">
														<label className="block mb-2 text-sm font-medium text-gray-500 text-start dark:text-gray-300">
															Included
														</label>
													</div>
													<div className="col-span-2">
														<label className="block mb-2 text-sm font-medium text-gray-500 text-start dark:text-gray-300">
															Additional
														</label>
													</div>
													<div className="flex justify-end">
														<label className="block mb-2 text-sm font-medium text-gray-500 text-start dark:text-gray-300">
															Total
														</label>
													</div>
													<div className="">
														<p className="w-full py-2 text-slate-700 dark:text-white">
															{includedAmount}
														</p>
													</div>
													<div className="flex justify-center">
														<Add className="text-slate-500 dark:text-slate-200" />
													</div>
													<div className="flex items-center w-full">
														<input
															type="text"
															id={field}
															name={field}
															value={formData[field]}
															onChange={handleChange}
															onKeyDown={handleKeyDown}
															placeholder="0"
															maxLength={3}
															className={`w-full rounded-lg border bg-white p-2 text-slate-700 dark:bg-slate-700 dark:text-white ${errors[field] ? "border-red-500" : "border-gray-300 dark:border-gray-600"}`}
														/>
													</div>
													<div className="flex justify-center">
														<FaEquals className="text-slate-500 dark:text-slate-200" />
													</div>
													<div className="flex justify-end">
														<p className="py-2 text-slate-700 dark:text-white">
															{totalAmount}
														</p>
													</div>
												</div>
												{index !== filteredFields.length - 1 && (
													<hr className="my-4 border-t border-gray-300 dark:border-gray-600" />
												)}
											</div>
										</div>
									)
								);
							})}
						</div>
						<div className="p-6 space-y-4 transition-opacity duration-200 bg-white rounded-lg shadow-md animate-fade-in dark:bg-slate-700">
							<div className="flex mb-3 font-semibold text-slate-500 dark:text-white align-center gap-1">
								<p>Pricing Adjustments</p>
								<Tippy
									content="Use the toggles to switch between fixed amount or percentage based on the total amount."
									placement="top"
									animation="fade"
									theme={darkMode ? "light" : "dark"}
								>
									<InfoOutlinedIcon
										style={{ width: "18px" }}
									></InfoOutlinedIcon>
								</Tippy>
							</div>
							<div>
								<label className="block text-sm font-medium text-gray-500 text-start dark:text-gray-300">
									Markup
								</label>
								<div className="flex gap-3">
									<input
										type="text"
										id="additionalMarkup"
										name="additionalMarkup"
										value={formData.additionalMarkup}
										onChange={handleChange}
										onKeyDown={handleKeyDown}
										maxLength={6}
										className={`w-full rounded-lg border bg-white p-2 text-slate-700 dark:bg-slate-700 dark:text-white ${errors.additionalMarkup ? "border-red-500" : "border-gray-300 dark:border-gray-600"}`}
										placeholder={`${isMarkupPercentage ? "0%" : "$0"}`}
									/>
									<div className="flex border-blue-400 rounded-full select-none bg-slate-100 dark:bg-slate-800">
										<label className="radio flex flex-grow items-center justify-center rounded-full p-1 pr-0 cursor-pointer">
											<input
												type="radio"
												name="markupRadio"
												value="$"
												checked={!isMarkupPercentage}
												onChange={() => setIsMarkupPercentage(false)}
												className="peer hidden"
											/>
											<span className="tracking-widest peer-checked:bg-gradient-to-r peer-checked:from-blue-500 peer-checked:to-blue-300 peer-checked:text-white text-gray-700 dark:text-gray-300 p-2 rounded-full transition duration-150 ease-in-out">
												<AttachMoneyIcon />
											</span>
										</label>
										<label className="radio flex flex-grow items-center justify-center rounded-lg p-1 pl-0 cursor-pointer">
											<input
												type="radio"
												name="markupRadio"
												value="%"
												checked={isMarkupPercentage}
												onChange={() => setIsMarkupPercentage(true)}
												className="peer hidden"
											/>
											<span className="tracking-widest peer-checked:bg-gradient-to-r peer-checked:from-blue-500 peer-checked:to-blue-300 peer-checked:text-white text-gray-700 dark:text-gray-300 p-2 rounded-full transition duration-150 ease-in-out">
												<PercentIcon />
											</span>
										</label>
									</div>
								</div>
							</div>
							<div>
								<label className="block text-sm font-medium text-gray-500 text-start dark:text-gray-300">
									Discount
								</label>
								<div className="flex gap-3">
									<input
										type="text"
										id="discount"
										name="discount"
										value={formData.discount}
										onChange={handleChange}
										onKeyDown={handleKeyDown}
										maxLength={6}
										className={`w-full rounded-lg border bg-white p-2 text-slate-700 dark:bg-slate-700 dark:text-white ${errors.discount ? "border-red-500" : "border-gray-300 dark:border-gray-600"}`}
										placeholder={`${isDiscountPercentage ? "0%" : "$0"}`}
									/>
									<div className="flex border-blue-400 rounded-full select-none bg-slate-100 dark:bg-slate-800">
										<label className="radio flex flex-grow items-center justify-center rounded-full p-1 pr-0 cursor-pointer">
											<input
												type="radio"
												name="discountRadio"
												value="$"
												checked={!isDiscountPercentage}
												onChange={() => setIsDiscountPercentage(false)}
												className="peer hidden"
											/>
											<span className="tracking-widest peer-checked:bg-gradient-to-r peer-checked:from-blue-500 peer-checked:to-blue-300 peer-checked:text-white text-gray-700 dark:text-gray-300 p-2 rounded-full transition duration-150 ease-in-out">
												<AttachMoneyIcon />
											</span>
										</label>
										<label className="radio flex flex-grow items-center justify-center rounded-lg p-1 pl-0 cursor-pointer">
											<input
												type="radio"
												name="discountRadio"
												value="%"
												checked={isDiscountPercentage}
												onChange={() => setIsDiscountPercentage(true)}
												className="peer hidden"
											/>
											<span className="tracking-widest peer-checked:bg-gradient-to-r peer-checked:from-blue-500 peer-checked:to-blue-300 peer-checked:text-white text-gray-700 dark:text-gray-300 p-2 rounded-full transition duration-150 ease-in-out">
												<PercentIcon />
											</span>
										</label>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="sticky top-0 self-start flex-1">
						<div className="flex flex-col justify-center text-center transition-opacity duration-1000 border-b-8 border-blue-400 rounded-lg shadow-xl animate-fade-in bg-blue-900/10 p-14 dark:bg-gray-900">
							<div>
								<h2 className={`text-xl font-semibold ${profitColor}`}>
									Total Profit
								</h2>
								<div className="my-4 mb-10 inline-block bg-gradient-to-r from-blue-400 via-indigo-400 via-80% to-green-400 bg-clip-text text-6xl font-bold text-blue-600 text-transparent">
									{currencySymbols[selectedCurrency]}
									{formatNumber(
										results.discountedPrice - addonTotal.addonTotalCost
									)}
								</div>
								<h2 className={`text-xl font-semibold ${profitColor}`}>
									Weighted Margin
								</h2>
								<div className="my-4 mb-10 inline-block bg-gradient-to-r from-blue-400 via-indigo-400 via-80% to-green-400 bg-clip-text text-6xl font-bold text-blue-600 text-transparent">
									{weightedMargin}%
								</div>
							</div>
							<div className="text-left">
								<br />
								<div className="flex justify-between mb-2">
									<span className="text-slate-500 dark:text-white">
										Total Price:
									</span>
									<span className="text-slate-500 dark:text-white">
										{currencySymbols[selectedCurrency]}
										{formatNumber(
											platinumPricing[selectedCurrency][selectedPackage]
												.packageTotalPrice +
												results.addonTotal +
												results.markupPrice
										)}
									</span>
								</div>
								<div className="flex justify-between mb-2">
									<span className="text-slate-500 dark:text-white">
										Total Discounted Price:
									</span>
									<span className="text-slate-500 dark:text-white">
										{currencySymbols[selectedCurrency]}
										{formatNumber(
											platinumPricing[selectedCurrency][selectedPackage]
												.packageTotalPrice +
												results.addonTotal +
												results.markupPrice -
												results.discountPrice
										)}
									</span>
								</div>
								<div className="flex justify-between mb-2">
									<span className="text-slate-500 dark:text-white">
										Monthly Price:
									</span>
									<span className="text-slate-500 dark:text-white">
										{currencySymbols[selectedCurrency]}
										{formatNumber(
											(platinumPricing[selectedCurrency][selectedPackage]
												.packageTotalPrice +
												results.addonTotal +
												results.markupPrice -
												results.discountPrice) /
												12
										)}
									</span>
								</div>
								<div className="flex justify-between mb-2">
									<span className="text-slate-500 dark:text-white">
										Total Cost:
									</span>
									<span className="text-slate-500 dark:text-white">
										{currencySymbols[selectedCurrency]}
										{formatNumber(addonTotal.addonTotalCost)}
									</span>
								</div>
								<hr className="my-4 border-t border-gray-300 dark:border-gray-600" />
								<div className="flex justify-between mb-2">
									<span className="text-slate-500 dark:text-white">
										Package Price:
									</span>
									<span className="text-slate-500 dark:text-white">
										{currencySymbols[selectedCurrency]}
										{formatNumber(
											platinumPricing[selectedCurrency][selectedPackage]
												.packageTotalPrice
										)}
									</span>
								</div>
								<div className="flex justify-between mb-2">
									<span className="text-slate-500 dark:text-white">
										Addon Total Price:
									</span>
									<span className="text-slate-500 dark:text-white">
										{currencySymbols[selectedCurrency]}
										{formatNumber(results.addonTotal)}
									</span>
								</div>
								<div className="flex justify-between mb-2">
									<span className="text-slate-500 dark:text-white">
										Markup Price:
									</span>
									<span className="text-green-500">
										+ {currencySymbols[selectedCurrency]}
										{formatNumber(results.markupPrice)}
									</span>
								</div>
								<div className="flex justify-between mb-2">
									<span className="text-slate-500 dark:text-white">
										Discount Price:
									</span>
									<span className="text-red-500">
										- {currencySymbols[selectedCurrency]}
										{formatNumber(results.discountPrice)}
									</span>
								</div>
								{isAddonsVisible ? (
									<hr className="my-4 border-t border-gray-300 dark:border-gray-600" />
								) : (
									""
								)}
								<div className="flex justify-center">
									<button
										onClick={toggleAddonsVisibility}
										className="mb-3 text-blue-500 align-items-center hover:underline"
									>
										{isAddonsVisible ? "Hide Addons" : "Show Addons"}
									</button>
								</div>
								{isAddonsVisible && (
									<div>
										<p className="pb-3 text-slate-500 dark:text-white">
											Addons
										</p>
										<div className="flex justify-between mb-2">
											<span className="text-slate-500 dark:text-white">
												Sev B/C (Case):
											</span>
											<span className="text-slate-500 dark:text-white">
												{currencySymbols[selectedCurrency]}
												{formatNumber(addonTotal.sevBcCaseTotal)}
											</span>
										</div>
										<div className="flex justify-between mb-2">
											<span className="text-slate-500 dark:text-white">
												Sev A (Hour):
											</span>
											<span className="text-slate-500 dark:text-white">
												{currencySymbols[selectedCurrency]}
												{formatNumber(addonTotal.sevAHourTotal)}
											</span>
										</div>
										<div className="flex justify-between mb-2">
											<span className="text-slate-500 dark:text-white">
												On-premise/Hybrid (Hour):
											</span>
											<span className="text-slate-500 dark:text-white">
												{currencySymbols[selectedCurrency]}
												{formatNumber(addonTotal.onPremiseHourTotal)}
											</span>
										</div>
										<div className="flex justify-between mb-2">
											<span className="text-slate-500 dark:text-white">
												Professional Services (Hour)*:
											</span>
											<span className="text-slate-500 dark:text-white">
												{currencySymbols[selectedCurrency]}
												{formatNumber(addonTotal.professionalServicesHourTotal)}
											</span>
										</div>
										<div className="flex justify-between mb-2">
											<span className="text-slate-500 dark:text-white">
												Consulting Sessions (Hour)*:
											</span>
											<span className="text-slate-500 dark:text-white">
												{currencySymbols[selectedCurrency]}
												{formatNumber(addonTotal.consultingSessionsHourTotal)}
											</span>
										</div>
										<div className="flex justify-between mb-2">
											<span className="text-slate-500 dark:text-white">
												Microsoft Assessment:
											</span>
											<span className="text-slate-500 dark:text-white">
												{currencySymbols[selectedCurrency]}
												{formatNumber(addonTotal.microsoftAssessmentTotal)}
											</span>
										</div>
										<div className="flex justify-between mb-2">
											<span className="text-slate-500 dark:text-white">
												Dedicated Cloud Engineer (Hours):
											</span>
											<span className="text-slate-500 dark:text-white">
												{currencySymbols[selectedCurrency]}
												{formatNumber(
													addonTotal.dedicatedCloudEngineerHoursTotal
												)}
											</span>
										</div>
										<div className="flex justify-between mb-2">
											<span className="text-slate-500 dark:text-white">
												Azure (Case):
											</span>
											<span className="text-slate-500 dark:text-white">
												{currencySymbols[selectedCurrency]}
												{formatNumber(addonTotal.azureCaseTotal)}
											</span>
										</div>
										<div className="flex justify-between mb-2">
											<span className="text-slate-500 dark:text-white">
												Azure Consulting Sessions (Hour)*:
											</span>
											<span className="text-slate-500 dark:text-white">
												{currencySymbols[selectedCurrency]}
												{formatNumber(
													addonTotal.azureConsultingSessionsHourTotal
												)}
											</span>
										</div>
									</div>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default PlatinumSupportCalculator;
