import basicIcon from "./assets/basic-icon.png";
import standardIcon from "./assets/standard-icon.png";
import premiumIcon from "./assets/premium-icon.png";
import eliteIcon from "./assets/elite-icon.png";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";

export const levenshteinDistance = (s, t) => {
	// This function calculates the least amount of changes needed to transform string s into string t
	// Useful for fuzzy string matching inputs from left to right, spell checking, etc.
	if (!s.length) return t.length;
	if (!t.length) return s.length;
	const arr = [];
	for (let i = 0; i <= t.length; i++) {
		arr[i] = [i];
		for (let j = 1; j <= s.length; j++) {
			arr[i][j] =
				i === 0
					? j
					: Math.min(
							arr[i - 1][j] + 1,
							arr[i][j - 1] + 1,
							arr[i - 1][j - 1] + (s[j - 1] === t[i - 1] ? 0 : 1)
						);
		}
	}
	// console.info(`${s} -> ${t} = ${arr[t.length][s.length]}`);
	return arr[t.length][s.length];
};

export const formatMoney = (amount) => {
	return new Intl.NumberFormat("en-US", {
		minimumFractionDigits: 2,
		maximumFractionDigits: 2,
	}).format(amount);
};

export const getPlatinumSupportDetails = (tier) => {
	switch (tier) {
		case "Basic":
			return {
				icon: basicIcon,
				benefits: ["Foundational Account Support"],
			};
		case "Standard":
			return {
				icon: standardIcon,
				benefits: [
					"Foundational Account Support",
					"Access to US-Based Support Engineers",
					"24/7/365 Global Support",
					"Preferred Pricing on Professional Services",
					"Priority Ticket Routing",
					"Severity B & C (Escalation to Microsoft Premier)",
					"Consulting Sessions: 12 hours (1 hour/month)",
					"Severity B & C Cases: 15 cases",
				],
			};
		case "Premium":
			return {
				icon: premiumIcon,
				benefits: [
					"Foundational Account Support",
					"Access to US-Based Support Engineers",
					"24/7/365 Global Support",
					"Preferred Pricing on Professional Services",
					"Priority Ticket Routing",
					"Severity B & C (Escalation to Microsoft Premier)",
					"Consulting Sessions: 24 hours (2 hours/month)",
					"Severity B & C Cases: 50 cases",
					"Severity A (Escalation to Microsoft Premier): 2 hours",
					"On Premise / Hybrid Support (Escalation to Microsoft Premier): 2 hours",
					"Microsoft / Azure Assessment: 20 hours",
					"Dedicated Cloud Support Engineer: 1",
				],
			};
		case "Elite":
			return {
				icon: eliteIcon,
				benefits: [
					"Foundational Account Support",
					"Access to US-Based Support Engineers",
					"24/7/365 Global Support",
					"Preferred Pricing on Professional Services",
					"Priority Ticket Routing",
					"Severity B & C (Escalation to Microsoft Premier)",
					"Consulting Sessions: 48 hours (2 hours/month)",
					"Severity B & C Cases: 100 cases",
					"Severity A (Escalation to Microsoft Premier): 5 hours",
					"On Premise / Hybrid Support (Escalation to Microsoft Premier): 5 hours",
					"Microsoft / Azure Assessment: 100 hours",
					"Dedicated Cloud Support Engineer: 1",
					"Professional Services: 48 hours",
				],
			};
		default:
			return null;
	}
};

export const getCommissionForItem = (item, nceCosts, desiredFields) => {
	const itemPrice = parseFloat(item.price);
	const lowerBound = itemPrice * 0.6;
	const upperBound = itemPrice * 1.4;
	const nceItem = nceCosts.find((nceItem) => {
		return (
			nceItem.unit_price !== 0 && // Disregard 0 acquired cost because we won't pay out commissions on free/trial licenses
			item.name
				.toLowerCase()
				.trim()
				.includes(nceItem.product_title.trim().toLowerCase())
		);
	});

	let nceCost =
		nceItem?.unit_price >= lowerBound && nceItem?.unit_price <= upperBound
			? (nceItem?.unit_price ?? 0)
			: (nceItem?.unit_price ?? 0) / 12;

	// List of product overwrites / exceptions
	if (nceItem?.product_title.includes("Microsoft 365 E3")) {
		nceCost = 28.8;
	}

	const profit = (itemPrice - nceCost) * item.qty;
	const months = 12; // Assuming 12 months for now
	const annualProfit = profit * months;
	let commission = item.name.toLowerCase().includes("platinum")
		? annualProfit * 0.0
		: annualProfit * 0.04;

	if (commission < 0) {
		commission = 0;
	}

	const billingPlan = nceItem?.billing_plan ?? "none";

	if (desiredFields === "ALL") {
		return [
			itemPrice,
			nceCost,
			profit,
			annualProfit,
			commission,
			billingPlan,
		];
	}

	return commission;
};

export const generateSHA256 = async (message) => {
	const msgBuffer = new TextEncoder().encode(message);
	const hashBuffer = await crypto.subtle.digest("SHA-256", msgBuffer);
	const hashArray = Array.from(new Uint8Array(hashBuffer));
	const hashHex = hashArray
		.map((b) => b.toString(16).padStart(2, "0"))
		.join("");
	return hashHex;
};

// export const uploadSalesOrderToCosmos = async (
// 	addons,
// 	formData,
// 	userName,
// 	serverAddress,
// 	accessToken,
// 	salesOrderType,
// 	id = null
// ) => {
// 	const pricingSummary = addons.map((addon) => {
// 		const showBillingAsMonthly = [
// 			"CFQ7TTC0MM8R|0002|P1Y|annual|US|USD", // Copilot for M365
// 		];

// 		const variants = addon.pkMsrpVariants?.[addon?.matchListName];
// 		const matchedVariant = variants?.find(
// 			(variant) => variant.pk_msrp === addon?.matchListLabel
// 		);

// 		const matchedUnitPrice = matchedVariant?.unit_price
// 			? showBillingAsMonthly.includes(matchedVariant.pk_msrp)
// 				? matchedVariant?.unit_price / 12
// 				: matchedVariant?.unit_price
// 			: 0;

// 		const matchedUnitPriceInUsd = matchedVariant?.unit_price_in_usd
// 			? showBillingAsMonthly.includes(matchedVariant.pk_msrp)
// 				? matchedVariant?.unit_price_in_usd / 12
// 				: matchedVariant?.unit_price_in_usd
// 			: 0;

// 		return {
// 			addonID: addon.id,
// 			productName: addon.name,
// 			description: addon?.description,
// 			unitPrice: addon.unit_price / 100,
// 			originalPrice: addon.originalPrice / 100,
// 			quantity: addon.quantity,
// 			matchedSkuTitle: addon.matchListName || "N/A",
// 			matchedPkID: addon.matchListLabel || "N/A",
// 			matchedUnitPrice: matchedUnitPrice,
// 			matchedUnitPriceInUsd: matchedUnitPriceInUsd,
// 		};
// 	});

// 	const totalAmount = addons.reduce(
// 		(total, addon) => total + addon.amount,
// 		0
// 	);
// 	const totalContractValue = totalAmount * (formData.termMonths || 1);
// 	let salesPersonName = formData.salesPersonName || userName;

// 	// Create a modified pricingSummary without addonID for hash calculation
// 	const pricingSummaryForHash = pricingSummary.map(
// 		({ addonID, ...rest }) => rest
// 	);

// 	const jsonSummary = {
// 		id: id ?? uuidv4(),
// 		salesOrderId: formData.salesOrderId ?? uuidv4(),
// 		chargebeeSubscriptionId: formData.chargebeeSubscriptionId,
// 		validThrough: formData.validThrough.toISOString().split("T")[0],
// 		renewalDate: formData.renewalDate.toISOString().split("T")[0],
// 		termMonths: formData.termMonths,
// 		netTerms: formData.netTerms,
// 		billingFrequency: formData.billingFrequency,
// 		companyName: formData.companyName,
// 		salesPersonName: salesPersonName,
// 		currency: formData.currency,
// 		platinumSupport: formData.platinumSupport,
// 		salesForceAccountId: formData.salesForceAccountId,
// 		salesForceOpportunityId: formData.salesForceOpportunityId,
// 		pricingSummary,
// 		totalAmount: (totalAmount / 100).toFixed(2),
// 		totalContractValue: (totalContractValue / 100).toFixed(2),
// 	};

// 	// It is only safe to use hashing for deduping if we remove fields
// 	// from our hash json objects that change each render!
// 	const jsonHashableString = JSON.stringify({
// 		...jsonSummary,
// 		// Overwrite during hashing
// 		pricingSummary: pricingSummaryForHash,
// 		// Exclude when generating hash because they change on creation
// 		validThrough: undefined,
// 		renewalDate: undefined,
// 		salesOrderId: undefined,
// 		id: undefined,
// 	});
// 	const jsonHash = await generateSHA256(jsonHashableString);
// 	const hashSubstring = jsonHash.substring(0, 8);

// 	let createdDate = new Date();
// 	jsonSummary["createdDate"] = createdDate.toISOString().split("T")[0];
// 	jsonSummary["createdBy"] = userName;
// 	jsonSummary["status"] = "Draft";
// 	jsonSummary["hash"] = hashSubstring;
// 	jsonSummary["salesOrderType"] = salesOrderType;

// 	try {
// 		let response;

// 		if (id) {
// 			//update sales order
// 			response = await axios.put(
// 				`${serverAddress}/sales-orders/`,
// 				jsonSummary,
// 				{
// 					headers: {
// 						Authorization: `Bearer ${accessToken}`,
// 						"X-User-Name": userName,
// 					},
// 				}
// 			);
// 			console.log("Updated existing sales order:", response);
// 		} else {
// 			//create sales order
// 			response = await axios.post(
// 				`${serverAddress}/sales-orders/`,
// 				jsonSummary,
// 				{
// 					headers: {
// 						Authorization: `Bearer ${accessToken}`,
// 						"X-User-Name": userName,
// 					},
// 				}
// 			);
// 			console.log("Created new sales order:", response);
// 		}
// 	} catch (error) {
// 		console.error("Error uploading sales order to Cosmos DB:", error);
// 	}
// };

//OG
export const uploadSalesOrderToCosmos = async (
	addons,
	formData,
	userName,
	serverAddress,
	accessToken,
	salesOrderType,
	id = null
) => {
	const pricingSummary = addons.map((addon) => {
		const showBillingAsMonthly = [
			"CFQ7TTC0MM8R|0002|P1Y|annual|US|USD", // Copilot for M365
		];

		const variants = addon.pkMsrpVariants?.[addon?.matchListName];
		const matchedVariant = variants?.find(
			(variant) => variant.pk_msrp === addon?.matchListLabel
		);

		const matchedUnitPrice = matchedVariant?.unit_price
			? showBillingAsMonthly.includes(matchedVariant.pk_msrp)
				? matchedVariant?.unit_price / 12
				: matchedVariant?.unit_price
			: 0;

		const matchedUnitPriceInUsd = matchedVariant?.unit_price_in_usd
			? showBillingAsMonthly.includes(matchedVariant.pk_msrp)
				? matchedVariant?.unit_price_in_usd / 12
				: matchedVariant?.unit_price_in_usd
			: 0;

		return {
			addonID: addon.id,
			productName: addon.name,
			description: addon?.description,
			unitPrice: addon.unit_price / 100,
			originalPrice: addon.originalPrice / 100,
			quantity: addon.quantity,
			matchedSkuTitle: addon.matchListName || "N/A",
			matchedPkID: addon.matchListLabel || "N/A",
			matchedUnitPrice: matchedUnitPrice,
			matchedUnitPriceInUsd: matchedUnitPriceInUsd,
		};
	});

	const totalAmount = addons.reduce(
		(total, addon) => total + addon.amount,
		0
	);
	const totalContractValue = totalAmount * (formData.termMonths || 1);
	let salesPersonName = formData.salesPersonName || userName;

	const jsonSummary = {
		id: id || uuidv4(),
		salesOrderId: formData.salesOrderId || uuidv4(),
		chargebeeSubscriptionId: formData.chargebeeSubscriptionId,
		validThrough: formData.validThrough.toISOString().split("T")[0],
		renewalDate: formData.renewalDate.toISOString().split("T")[0],
		termMonths: formData.termMonths,
		netTerms: formData.netTerms,
		billingFrequency: formData.billingFrequency,
		companyName: formData.companyName,
		salesPersonName: salesPersonName,
		currency: formData.currency,
		platinumSupport: formData.platinumSupport,
		salesForceAccountId: formData.salesForceAccountId,
		salesForceOpportunityId: formData.salesForceOpportunityId,
		pricingSummary,
		totalAmount: (totalAmount / 100).toFixed(2),
		totalContractValue: (totalContractValue / 100).toFixed(2),
	};

	let createdDate = new Date();
	const jsonHashableString = JSON.stringify({
		...jsonSummary,
		id: undefined, //exclude when generating hash
	});
	const jsonHash = await generateSHA256(jsonHashableString);
	const hashSubstring = jsonHash.substring(0, 8);

	jsonSummary["createdDate"] = createdDate.toISOString().split("T")[0];
	jsonSummary["createdBy"] = userName;
	jsonSummary["status"] = "Draft";
	jsonSummary["hash"] = hashSubstring;
	jsonSummary["salesOrderType"] = salesOrderType;

	try {
		let response;

		if (id) {
			//update sales order
			response = await axios.put(
				`${serverAddress}/sales-orders/`,
				jsonSummary,
				{
					headers: {
						Authorization: `Bearer ${accessToken}`,
						"X-User-Name": userName,
					},
				}
			);
			console.log("Updated existing sales order:", response);
		} else {
			//create sales order
			response = await axios.post(
				`${serverAddress}/sales-orders/`,
				jsonSummary,
				{
					headers: {
						Authorization: `Bearer ${accessToken}`,
						"X-User-Name": userName,
					},
				}
			);
			console.log("Created new sales order:", response);
		}
	} catch (error) {
		console.error("Error uploading sales order to Cosmos DB:", error);
	}
};

export const uploadConsumptionReportToCosmos = async (
	updatedRows,
	clientCompany,
	selectedSender,
	referenceCode,
	currency,
	userName,
	serverAddress,
	accessToken
) => {
	const jsonSummary = {
		consumptionReportID: uuidv4(),
		clientCompany: clientCompany,
		selectedSender: selectedSender,
		referenceCode: referenceCode,
		rows: updatedRows,
		currency: currency,
		createdBy: userName,
		status: "Draft",
	};

	let createdDate = new Date();
	// Ignore consumptionReportID when hashing because it's a uuid generated each function call
	// eslint-disable-next-line
	const { consumptionReportID, ...hashableJsonSummary } = jsonSummary;
	const jsonHashableString = JSON.stringify(hashableJsonSummary);
	const jsonHash = await generateSHA256(jsonHashableString);
	const hashSubstring = jsonHash.substring(0, 8);

	jsonSummary["createdDate"] = createdDate.toISOString().split("T")[0];
	jsonSummary["createdBy"] = userName;
	jsonSummary["status"] = "Draft";
	jsonSummary["hash"] = hashSubstring;
	jsonSummary["id"] = "";

	try {
		const response = await axios.post(
			`${serverAddress}/consumption-reports/`,
			jsonSummary,
			{
				headers: {
					"Authorization": `Bearer ${accessToken}`,
					"X-User-Name": `${userName}`,
				},
			}
		);
		console.info(response);
	} catch (error) {
		console.error("Error uploading pdf to blob storage:", error);
	}
};

export const getRedisKeyValue = async (key, serverAddress, accessToken, userName) => {
    try {
        const response = await axios.get(
            `${serverAddress}/admin/?key=${key}`,
            {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${accessToken}`,
                    "X-User-Name": `${userName}`,
                },
            }
        );
        return response; 
    } catch (error) {
        console.error('Error fetching key from Redis:', error);
    }
}

export const setRedisKeyValue = async (key, value, serverAddress, accessToken, userName) => {
    try {
        const body = {
            "key": key,
            "val": value,
        }
        console.log("Setting key in Redis:", body);
        const response = await axios.post(
            `${serverAddress}/admin/`,
            body,
            {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${accessToken}`,
                    "X-User-Name": `${userName}`,
                },
            }
        );
        return response;
    } catch (error) {
        console.error('Error setting key in Redis:', error);
    }
}